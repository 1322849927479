import { HomeCard } from "../components/homeCard"
import { useGetLastWeeksPerformancesQuery } from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { LastPerformance } from "../components/lastPerformanceHome"
import { Trash, TrendingUp } from "lucide-react"

export const LastWeekPerformancesHome = () => {
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { data } = useGetLastWeeksPerformancesQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })

  return (
    <HomeCard
      title="Dernières performances"
      subtitle="Retrouvez vos performances sur les deux dernières semaines"
      className="h-full"
    >
      <div className="flex flex-col gap-6 px-8 py-4 mb-6">
        <LastPerformance
          title="Démarque"
          description="La démarque"
          oneWeekAgo={data?.lastBreakages.one_week_ago ?? 0}
          twoWeeksAgo={data?.lastBreakages.two_weeks_ago ?? 0}
          oneYearAgo={data?.lastBreakages.one_year_ago ?? 0}
          twoWeeksAgoPercent={
            (data?.lastBreakages.two_weeks_ago ?? 0) /
            (data?.lastRevenue.two_weeks_ago ?? 1)
          }
          oneWeekAgoPercent={
            (data?.lastBreakages.one_week_ago ?? 0) /
            (data?.lastRevenue.one_week_ago ?? 1)
          }
          currency={storeCurrency ?? "EUR"}
          icon={<Trash className="size-4" />}
        />
        <LastPerformance
          title="Chiffre d'affaires"
          description="Le chiffre d'affaires"
          oneWeekAgo={data?.lastRevenue.one_week_ago ?? 0}
          twoWeeksAgoPercent={
            (data?.lastBreakages.two_weeks_ago ?? 0) /
            (data?.lastRevenue.two_weeks_ago ?? 1)
          }
          oneWeekAgoPercent={
            (data?.lastBreakages.one_week_ago ?? 0) /
            (data?.lastRevenue.one_week_ago ?? 1)
          }
          twoWeeksAgo={data?.lastRevenue.two_weeks_ago ?? 0}
          oneYearAgo={data?.lastRevenue.one_year_ago ?? 0}
          currency={storeCurrency ?? "EUR"}
          icon={<TrendingUp className="size-4" />}
          invertColors
        />
      </div>
    </HomeCard>
  )
}
