/**
 * Get the variation between two values
 *
 * @example
 * const variation = getVariation(10, 8)
 * // variation === 0.2
 * const percent = variation * 100
 * // percent === 20
 */
export function getVariation(value1: number, value2: number): number {
  return (value1 - value2) / value1
}
