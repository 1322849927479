import { useState } from "react"
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import {
  ArrowRight,
  Calendar,
  ChartArea,
  Home,
  Link as LinkIcon,
  List,
  ListTodo,
  Lock,
  Menu,
  MessageSquareMore,
  PackageSearch,
  Percent,
  Settings,
  Sheet,
  ShieldAlert,
  Store,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import { Drawer, DrawerContent, DrawerOverlay } from "@/components/ui/drawer"
import { cn } from "@/lib/utils"
import { usePermissions } from "@/hooks/usePermissions"

import { LinksDropdown } from "./linksDropDown"
import { NavigationItem, storeDisplayRules } from "./conditions"
import { useWindowSize } from "@/hooks/useWindowSize"

export const AppNavigation = ({
  appMode,
  setAppMode,
  companyId,
  storeFranchise,
}: {
  appMode: "store" | "admin"
  setAppMode: (appMode: "store" | "admin") => void
  companyId: string | null
  storeFranchise: string | null
}) => {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const companyIdParam = params.companyId
  const companyFranchiseParam = searchParams.get("franchise") ?? undefined

  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const { hasPermissions, isAuthAdmin, isIdaAdmin } = usePermissions()
  const location = useLocation()

  const displayRules =
    companyName && companyName in storeDisplayRules
      ? storeDisplayRules[companyName]
      : storeDisplayRules.default

  const { isMD } = useWindowSize()

  const storeModeNavigation = [
    {
      condition: displayRules.showOrder,
      icon: <Home className="w-6 h-6" />,
      title: "Accueil",
      onClick: () => navigate("/home"),
      isActive: matchPath("/home", location.pathname) !== null,
    },
    {
      condition: displayRules.showOrder,
      icon: <Calendar className="w-6 h-6" />,
      title: "Calendrier",
      onClick: () => navigate("/calendar"),
      isActive: matchPath("/calendar", location.pathname) !== null,
    },
    {
      condition: displayRules.showOrder,
      icon: <ListTodo className="w-6 h-6" />,
      title: "Ordre du rayon",
      onClick: () => navigate("/categories"),
      isActive: matchPath("/categories", location.pathname) !== null,
    },
    {
      condition: displayRules.showEngagement && isMD,
      icon: <Percent className="w-6 h-6" />,
      title: "Engagement promotionnel",
      onClick: () => navigate("/engagement-promo"),
      isActive: matchPath("/engagement-promo", location.pathname) !== null,
    },
    {
      condition: displayRules.showReferentielProduit && isMD,
      icon: <Sheet className="w-6 h-6" />,
      title: "Référentiel produit",
      onClick: () => navigate("/matching"),
      isActive: matchPath("/matching", location.pathname) !== null,
    },
    {
      condition: displayRules.showLinks,
      icon: <LinkIcon className="w-6 h-6" />,
      title: "Liens utiles",
      onClick: () => {
        return
      },
      isActive: matchPath("/links", location.pathname) !== null,
      component: <LinksDropdown />,
    },
    {
      condition: true,
      icon: <Settings className="size-6" />,
      title: "Paramètres",
      onClick: () => navigate("/settings"),
      isActive: matchPath("/settings", location.pathname) !== null,
    },
  ]

  const selectedCompanyId = companyIdParam ?? companyId
  const selectedCompanyFranchise = companyFranchiseParam ?? storeFranchise

  const adminModeNavigation = [
    {
      condition: true,
      icon: <List className="w-6 h-6" />,
      title: "Liste des magasins",
      onClick: () =>
        navigate(
          `/admin/companies/${selectedCompanyId}${typeof selectedCompanyFranchise === "string" ? `?franchise=${selectedCompanyFranchise}` : ""}`,
        ),
      isActive:
        matchPath("/admin/companies/:companyId", location.pathname) !== null,
    },
    {
      condition: true,
      icon: <MessageSquareMore className="size-6" />,
      title: "Messages",
      onClick: () =>
        navigate(
          `/admin/companies/${selectedCompanyId}/messages${typeof selectedCompanyFranchise === "string" ? `?franchise=${selectedCompanyFranchise}` : ""}`,
        ),
      isActive:
        matchPath("/admin/companies/:companyId/messages", location.pathname) !==
        null,
    },
    {
      condition: true,
      icon: <PackageSearch className="size-6" />,
      title: "Référentiel",
      onClick: () =>
        navigate(
          `/admin/companies/${selectedCompanyId}/regrouping-ida${typeof selectedCompanyFranchise === "string" ? `?franchise=${selectedCompanyFranchise}` : ""}`,
        ),
      isActive:
        matchPath(
          "/admin/companies/:companyId/regrouping-ida",
          location.pathname,
        ) !== null,
    },
    {
      condition: isIdaAdmin,
      icon: <ChartArea className="size-6" />,
      title: "Performances",
      onClick: () =>
        navigate(
          `/admin/companies/${selectedCompanyId}/performances${typeof selectedCompanyFranchise === "string" ? `?franchise=${selectedCompanyFranchise}` : ""}`,
        ),
      isActive:
        matchPath(
          "/admin/companies/:companyId/performances",
          location.pathname,
        ) !== null,
    },
    {
      condition: isIdaAdmin,
      icon: <Calendar className="size-6" />,
      title: "Plannings",
      onClick: () =>
        navigate(
          `/admin/companies/${selectedCompanyId}/store-scheduler${typeof selectedCompanyFranchise === "string" ? `?franchise=${selectedCompanyFranchise}` : ""}`,
        ),
      isActive:
        matchPath(
          "/admin/companies/:companyId/store-scheduler",
          location.pathname,
        ) !== null,
    },
  ]

  const navigationItems: NavigationItem[] =
    appMode === "store" ? storeModeNavigation : adminModeNavigation

  return (
    <>
      <div className="flex flex-row justify-between items-center md:hidden">
        <Button
          variant="ghost"
          onClick={() => setDrawerOpen(true)}
          className="flex items-center gap-2"
        >
          <Menu className="w-8 h-8" />
        </Button>
      </div>
      <Drawer
        direction="left"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent showDrag={false}>
          <div className="flex flex-col gap-2 mt-4 p-4">
            {navigationItems
              .filter((item) => item.condition)
              .map((item) =>
                item.component ? (
                  item.component
                ) : (
                  <Button
                    key={item.title}
                    variant="ghost"
                    onClick={() => {
                      item.onClick()
                      setDrawerOpen(false)
                    }}
                    className={cn(
                      "flex gap-2 justify-start",
                      item.isActive &&
                        "bg-black text-white hover:bg-gray-800 hover:text-white",
                    )}
                  >
                    {item.icon}
                    {item.title}
                  </Button>
                ),
              )}
          </div>
          {hasPermissions(["arolya:company:admin"]) && (
            <div className="p-4 border-t flex flex-col gap-2 items-start">
              {appMode === "store" ? (
                <Button
                  variant="ghost"
                  onClick={() => {
                    navigate("/admin")
                    setAppMode("admin")
                    setDrawerOpen(false)
                  }}
                  className="flex gap-2"
                >
                  <ShieldAlert className="w-4 h-4" />
                  Mode administrateur
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  onClick={() => {
                    navigate("/home")
                    setAppMode("store")
                    setDrawerOpen(false)
                  }}
                >
                  <Store className="w-4 h-4" />
                  Mode magasin
                </Button>
              )}
            </div>
          )}
          {isAuthAdmin && appMode === "admin" && (
            <div className="px-4 pb-4">
              <Button
                variant="ghost"
                onClick={() => {
                  navigate("/admin/users")
                  setDrawerOpen(false)
                }}
              >
                <Lock className="size-4" />
                Utilisateurs
              </Button>
            </div>
          )}
        </DrawerContent>
      </Drawer>
      <div className="hidden md:flex flex-row justify-between items-center gap-2">
        <div className="hidden md:flex gap-2 flex-wrap">
          {navigationItems
            .filter((item) => item.condition)
            .map((item) => {
              return item.component ? (
                item.component
              ) : (
                <Button
                  key={item.title}
                  onClick={item.onClick}
                  className={cn(
                    "flex items-center gap-2 bg-white border border-gray-200 text-black hover:bg-gray-100",
                    item.isActive &&
                      "bg-black text-white hover:bg-gray-800 hover:text-white",
                  )}
                >
                  {item.icon}
                  {item.title}
                </Button>
              )
            })}
        </div>
        {appMode === "store" && (
          <Button
            onClick={() => {
              navigate("/inventory")
            }}
            className="bg-green-800 text-white  hover:bg-green-700 hover:text-white "
          >
            <p>Passer commande</p>
            <ArrowRight className="w-4 h-4" />
          </Button>
        )}
      </div>
    </>
  )
}
