import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Check, ListFilterIcon } from "lucide-react"
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command"
import { cn } from "@/lib/utils"
import { upperFirst } from "lodash"
import { Label } from "@/components/ui/label"

interface Supplier {
  label: string
  value: string
}

const isSupplierSelected = (supplierIds: string[], supplierId: string) =>
  !supplierIds.length || supplierIds.includes(supplierId)

export const SuppliersFilter = ({
  suppliers,
  selectedIds = [],
  onSelect,
  className,
}: {
  className?: string
  onSelect: (supplierId: string) => void
  selectedIds?: string[]
  suppliers: Supplier[]
}) => (
  <Popover>
    <PopoverTrigger asChild>
      <Button variant="outline" className={className}>
        <ListFilterIcon className="w-4 h-4" />
        <span className="hidden lg:block">Filtrer</span>
      </Button>
    </PopoverTrigger>
    <PopoverContent className="w-[200px] p-0" align="start">
      <Command>
        <CommandList>
          <Label className="px-3 py-3 block font-bold">
            Filtrer par fournisseur
          </Label>
          <CommandSeparator />
          <CommandGroup>
            {suppliers.map(({ value, label }) => (
              <CommandItem key={value} onSelect={() => onSelect(value)}>
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                    isSupplierSelected(selectedIds, value)
                      ? "bg-green-500 border-none text-primary-foreground"
                      : "opacity-50 [&_svg]:invisible",
                  )}
                >
                  <Check />
                </div>
                <span className="font-medium">
                  {upperFirst(label.toLowerCase())}
                </span>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
)
