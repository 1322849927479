import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { StateType } from "@/types"
import { useGetCompaniesQuery } from "@/utils/__generated__/graphql"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

export function CompanySelector() {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const companyId = params.companyId
  const companyFranchise = searchParams.get("franchise") ?? undefined

  const { storeId } = useSelector((state: StateType) => state.storeReducer)

  const { data } = useGetCompaniesQuery({
    variables: { input: { store_id: storeId! } },
  })

  const value = `${companyId}/${companyFranchise ?? ""}`

  const companiesItems = useMemo(() => {
    return data?.companies.map((company, i) => {
      const companyValue = `${company.company_id}/${company.franchise ?? ""}`

      return (
        <SelectItem key={i} value={companyValue} className="capitalize">
          {company.franchise ?? company.company_name}
        </SelectItem>
      )
    })
  }, [data?.companies])

  function handleValueChange(value: string) {
    const [companyId, companyFranchise] = value.split("/")
    navigate(
      `/admin/companies/${companyId}${
        companyFranchise !== "" ? `?franchise=${companyFranchise}` : ""
      }`,
    )
  }

  return (
    <Select value={value} onValueChange={handleValueChange}>
      <SelectTrigger className="w-[180px] capitalize">
        <SelectValue placeholder="Compagnie" />
      </SelectTrigger>
      <SelectContent>{companiesItems}</SelectContent>
    </Select>
  )
}
