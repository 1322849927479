import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ReactNode } from "react"

interface BreakagePopoverProps {
  children: ReactNode
  disabled?: boolean
}

export function BreakagePopover({
  children,
  disabled = false,
}: BreakagePopoverProps) {
  return (
    <Popover open={disabled ? false : undefined}>
      <PopoverTrigger className="flex-1">{children}</PopoverTrigger>
      <PopoverContent side="top">
        La démarque est supérieur à 10%.
      </PopoverContent>
    </Popover>
  )
}
