import { ArrowNextIcon } from "../../ui/icons/ArrowNextIcon"
import { ArrowBackIcon } from "../../ui/icons/ArrowBackIcon"
import { Label } from "../../ui/Label"
import { Link } from "react-router-dom"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import { GetCalendarQuery } from "../../utils/__generated__/graphql"
import { Button } from "../../ui/Button"
import { DateTime } from "luxon"

interface CalendarMobileProps {
  companyName: string | null
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
  data: GetCalendarQuery | undefined
  allDays: DateTime[]
  storeCurrency: string | null
}

interface CalendarHeaderProps {
  setCurrentDate: (date: DateTime) => void
  currentDate: DateTime
}

interface WeekdaysProps {
  currentDate: DateTime
}

interface DaysProps {
  allDays: DateTime[]
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
  data: GetCalendarQuery | undefined
}

interface HolidayProps {
  holidayReason: string | null | undefined
  schoolHolidaysReason: string | null | undefined
}

interface InfosProps {
  currentDate: DateTime
  data: GetCalendarQuery | undefined
  storeCurrency: string | null
}

const CalendarHeader = ({
  currentDate,
  setCurrentDate,
}: CalendarHeaderProps) => {
  const today = DateTime.now()

  const handleChangeMonth = (delta: number) => {
    const newDate = currentDate.plus({ months: delta }).startOf("month")
    if (newDate.hasSame(today, "month")) {
      setCurrentDate(today)
    } else {
      setCurrentDate(newDate)
    }
  }
  return (
    <div className="p-3 flex justify-between items-center">
      <p className="text-md text-zinc-800 font-black capialize">
        {currentDate.toFormat("LLLL yyyy", { locale: "fr-FR" })}
      </p>
      <Button
        size="sm"
        color="ghost"
        className=""
        onClick={() => {
          setCurrentDate(today)
        }}
      >
        Aujourd&apos;hui
      </Button>
      <div className="flex gap-4">
        <div
          onClick={() => {
            handleChangeMonth(-1)
          }}
        >
          <ArrowBackIcon className="w-5 h-5" />
        </div>
        <div
          onClick={() => {
            handleChangeMonth(1)
          }}
        >
          <ArrowNextIcon className="w-5 h-5" />
        </div>
      </div>
    </div>
  )
}

const Weekdays = ({ currentDate }: WeekdaysProps) => {
  const weekdays = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"]
  const currentWeekDay = currentDate.weekday
  return (
    <div className="grid grid-cols-7">
      {weekdays.map((day, index) => {
        const isCurrentWeekday =
          currentWeekDay === index + 1 &&
          currentDate.hasSame(currentDate, "day")
        return (
          <div className="flex justify-center items-center" key={day}>
            <p
              className={`text-md ${isCurrentWeekday ? "text-green-400 font-extrabold" : "text-zinc-800 font-bold"}`}
            >
              {day}
            </p>
          </div>
        )
      })}
    </div>
  )
}

const Days = ({ allDays, currentDate, setCurrentDate, data }: DaysProps) => {
  const handleChangeDay = (day: DateTime) => {
    if (day !== null) {
      setCurrentDate(day)
    }
  }

  return (
    <div className="grid grid-cols-7">
      {allDays.map((day, index) => {
        const info = data?.getCalendar.calendar?.find((info) => {
          if (typeof info?.date !== "string") return false
          const date = DateTime.fromISO(info.date)
          date.startOf("day")
          return day.hasSame(date, "day")
        })
        const isCurrentDate = day.hasSame(currentDate, "day")
        const isDayInCurrentMonth = day.hasSame(currentDate, "month")
        let dayStyle = "tex-md text-gray-400 font-medium"
        if (isDayInCurrentMonth) {
          if (isCurrentDate) {
            dayStyle = "text-md text-white font-bold"
          } else {
            dayStyle = "text-md text-black font-medium"
          }
        }
        return (
          <div
            className={`flex flex-col justify-center items-center`}
            key={index}
          >
            <div
              className={`flex items-center justify-center p-3 ${isCurrentDate ? "bg-green-400 rounded-full w-10 h-10" : ""}`}
              onClick={() => {
                handleChangeDay(day)
              }}
            >
              <p className={dayStyle}>{day.day}</p>
            </div>
            {day !== null && (
              <div className="w-5 h-2 p-0.5 justify-center items-center gap-0.5 inline-flex">
                {typeof info?.holidayReason === "string" && (
                  <div className="w-1 h-1 bg-red-500 rounded-full"></div>
                )}
                {info?.isSchoolHolidays === true &&
                  typeof info?.holidayReason !== "string" && (
                    <div className="w-1 h-1 bg-cyan-100 rounded-full"></div>
                  )}
                {(info?.orderItems ?? []).map((orderItem) => (
                  <div
                    key={orderItem.dimOrderRequestId}
                    className="w-1 h-1 bg-orange-400 rounded-full"
                  />
                ))}
                {(typeof info?.dailyCA === "number" ||
                  typeof info?.dailyParcelCount === "number") && (
                  <div className="w-1 h-1 bg-yellow-300 rounded-full"></div>
                )}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const Holiday = ({ schoolHolidaysReason, holidayReason }: HolidayProps) => {
  const isPublicHoliday = typeof holidayReason === "string"

  const text = isPublicHoliday
    ? (holidayReason ?? "Férié")
    : (schoolHolidaysReason ?? "Vacances scolaires")
  const color: "blue" | "red" = isPublicHoliday ? "red" : "blue"

  return (
    <Label color={color} variant="filled" className="w-full p-2">
      <span className="font-medium text-xs">{text}</span>
    </Label>
  )
}

const Infos = ({ currentDate, data, storeCurrency }: InfosProps) => {
  const currentData = data?.getCalendar.calendar?.find((dataItem) => {
    if (dataItem === null) return false
    const infoDate = DateTime.fromISO(dataItem.date)
    return infoDate.hasSame(currentDate, "day")
  })

  return (
    <div className="flex flex-col gap-2 w-full">
      {(typeof currentData?.holidayReason === "string" ||
        currentData?.isSchoolHolidays === true) && (
        <Holiday
          holidayReason={currentData?.holidayReason}
          schoolHolidaysReason={currentData?.schoolHolidaysReason}
        />
      )}
      {currentData?.orderItems.map((orderItem) => (
        <Link
          key={orderItem.dimOrderRequestId}
          to={`/inventory/recap?orderId=${orderItem.dimOrderRequestId}`}
          className="w-full"
        >
          <Label
            variant="filled"
            className="w-full justify-between p-2"
            color="orange"
            rightIcon={<ArrowRightIcon className="w-4 h-4" />}
          >
            <span className="flex flex-col">
              <span className="text-zinc-800 font-black text-sm">
                {new Intl.NumberFormat("fr-FR", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(orderItem.potentialCA)}
              </span>
              <span className="text-zinc-800 text-xs font-medium">
                CA Potentiel Commandé
              </span>
            </span>
          </Label>
        </Link>
      ))}
      <div className="flex gap-2">
        {typeof currentData?.dailyCA === "number" && (
          <Label color="yellow" variant="filled" className="w-full p-2">
            <span className="flex flex-col">
              <span className="text-zinc-800 font-black text-sm">
                {new Intl.NumberFormat("fr-FR", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(currentData.dailyCA)}
              </span>
              <span className="text-zinc-800 text-xs font-medium">
                CA Réalisé
              </span>
            </span>
          </Label>
        )}
        {typeof currentData?.dailyParcelCount === "number" && (
          <Label color="yellow" variant="filled" className="w-full p-2">
            <span className="flex flex-col">
              <span className="text-zinc-800 font-black text-sm">
                {currentData.dailyParcelCount}
              </span>
              <span className="text-zinc-800 text-xs font-medium">
                Cs Vendues
              </span>
            </span>
          </Label>
        )}
      </div>
    </div>
  )
}

const CalendarMobile = ({
  currentDate,
  setCurrentDate,
  data,
  allDays,
  storeCurrency,
}: CalendarMobileProps) => {
  return (
    <div className="flex flex-col gap-2 h-full justify-center">
      <div className="shadow rounded-lg p-2">
        <CalendarHeader
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
        <Weekdays currentDate={currentDate} />
        <Days
          allDays={allDays}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          data={data}
        />
      </div>
      <div className="">
        <Infos
          currentDate={currentDate}
          data={data}
          storeCurrency={storeCurrency}
        />
      </div>
    </div>
  )
}

export default CalendarMobile
