// src/components/StoreModificationsChart.tsx

"use client"

import { useState } from "react"
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useGetInventoriesModificationsQuery } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"

interface StoreModification {
  store_id: string
  store_name: string
  modification_count: number
  increase_count: number
  decrease_count: number
  total_increase: number
  total_decrease: number
}

interface StoreModificationsChartProps {
  companyId: string
  franchiseName: string
  startDate: string
  endDate: string
  storeId: string
}

export function StoreModificationsChart({
  companyId,
  franchiseName,
  startDate,
  endDate,
  storeId,
}: StoreModificationsChartProps) {
  const { data, loading, error } = useGetInventoriesModificationsQuery({
    variables: {
      input: {
        company_id: companyId,
        store_id: storeId,
        franchise_name: franchiseName,
        start_date: startDate,
        end_date: endDate,
      },
    },
  })

  const [selectedIndicator, setSelectedIndicator] =
    useState<string>("modification_count")

  const indicators = [
    { key: "modification_count", name: "Nombre de modifications" },
    { key: "increase_count", name: "Nombre de modifs à la hausse" },
    { key: "decrease_count", name: "Nombre de modifs à la baisse" },
    { key: "total_increase", name: "Total des modifs à la hausse (en unité)" },
    {
      key: "total_decrease",
      name: "Total des modifs à la baisse (en unité)",
    },
  ]

  if (loading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return <div className="text-red-500">Error fetching data.</div>
  }

  const chartData: StoreModification[] =
    data?.getInventoriesModifications?.inventories_modifications?.map(
      (item) => ({
        store_id: item.store_id,
        store_name: item.store_name,
        modification_count: item.modification_count,
        increase_count: item.increase_count,
        decrease_count: item.decrease_count,
        total_increase: Math.round(item.total_increase * 100) / 100,
        total_decrease: Math.round(item.total_decrease * 100) / 100,
      }),
    ) ?? []
  console.log(chartData, "chartData")

  return (
    <Card className="w-full max-w-4xl mx-auto overflow-y-auto">
      <CardHeader>
        <CardTitle>Classement des magasins qui modifient leur stock</CardTitle>
      </CardHeader>
      <CardContent>
        {/* Selector for indicators */}
        <div className="mb-4">
          <label htmlFor="indicator-select" className="mr-2">
            Sélectionnez un indicateur:
          </label>
          <select
            id="indicator-select"
            value={selectedIndicator}
            onChange={(e) => setSelectedIndicator(e.target.value)}
            className="border rounded p-1"
          >
            {indicators.map((indicator) => (
              <option key={indicator.key} value={indicator.key}>
                {indicator.name}
              </option>
            ))}
          </select>
        </div>

        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={chartData}
            layout="vertical"
            margin={{
              top: 20,
              right: 30,
              left: 100,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey="store_name"
              tickLine={false}
              width={150}
            />
            <Tooltip />

            <Bar
              dataKey={selectedIndicator}
              fill="#8884d8"
              name={
                indicators.find(
                  (indicator) => indicator.key === selectedIndicator,
                )?.name
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
