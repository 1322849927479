import { StoreReducerState } from "../reducers/storeReducer"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import { addZeroBeforeNumber } from "../utils/addZeroBeforeNumber"
import { ChartPieIcon, GlobeIcon, TagIcon, WalletIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { ReactNode } from "react"
import { twJoin } from "tailwind-merge"

interface ReferenceDetailsProps {
  reference: MercurialReducerState["mercurialAndStoreInventories"][number]
  storeCurrency: StoreReducerState["storeCurrency"]
  storeSettings: StoreReducerState["storeSettings"]
  bestMargin: number | null
}

export const LabelAndValue = ({
  value,
  icon,
  className,
}: {
  className?: string
  value: ReactNode
  icon: ReactNode
}) => (
  <div className="flex items-center gap-1">
    {icon}
    <p className={cn("font-medium text-primary flex text-xs", className)}>
      {value}
    </p>
  </div>
)

export function ReferenceDetails({
  bestMargin,
  reference,
  storeCurrency,
  storeSettings,
}: ReferenceDetailsProps) {
  const margin =
    typeof reference.pa === "number" &&
    reference.pa > 0 &&
    typeof reference.pv === "number" &&
    reference.pv > 0
      ? (reference.pv - reference.pa) / reference.pv
      : null

  const formattedMargin =
    margin !== null
      ? new Intl.NumberFormat("fr-FR", {
          maximumFractionDigits: 0,
          style: "percent",
        }).format(margin)
      : ""

  const isBestMargin = bestMargin !== null && margin === bestMargin

  const formatCurrency = (amount: number, currency = "EUR") => {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency,
    }).format(amount)
  }

  const price = reference?.pa ?? 0
  const shouldDisplayPrice = storeSettings?.view_pa && price > 0
  const shouldDisplayPurchaseCode = storeSettings?.display_purchase_code
  const hasPvChange =
    reference.last_pv !== null && reference.last_pv !== reference.pv
  const shouldDisplayPv =
    typeof reference.pv === "number" &&
    reference.pv > 0 &&
    storeSettings?.view_pv

  return (
    <div className="flex flex-col text-zinc-500 text-xs md:text-sm font-bold md:font-medium">
      <div className="flex gap-2 md:gap-4 text-xs flex-wrap items-center">
        {shouldDisplayPurchaseCode &&
          reference?.order_code !== null &&
          reference?.order_code !== "10000000" && (
            <p className="font-light">
              {addZeroBeforeNumber(
                (reference?.order_code ?? "").split(/[|;]/).slice(0, 1)[0],
              )}
            </p>
          )}
        {reference?.origin !== null && reference?.origin?.trim() !== "" && (
          <LabelAndValue
            className="capitalize"
            value={reference?.origin?.toLowerCase()}
            icon={
              <GlobeIcon className="text-muted-foreground/80 w-3.5 h-3.5 inline" />
            }
          />
        )}
        {reference?.typology !== null && storeSettings?.typologies !== null && (
          <p>Typologie : {reference?.typology}</p>
        )}
        {shouldDisplayPrice && (
          <LabelAndValue
            icon={
              <WalletIcon className="text-muted-foreground/80 w-3.5 h-3.5 inline" />
            }
            value={formatCurrency(price, storeCurrency ?? "EUR")}
          />
        )}
        {shouldDisplayPv && (
          <LabelAndValue
            icon={
              <TagIcon className="text-muted-foreground/80 w-3.5 h-3.5 inline" />
            }
            value={
              <>
                {hasPvChange && storeSettings?.show_last_pv && (
                  <span className="line-through mr-1 text-muted-foreground">
                    {new Intl.NumberFormat("fr-FR", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: storeCurrency ?? "EUR",
                    }).format(reference?.last_pv ?? 0)}
                  </span>
                )}
                <span
                  className={twJoin(
                    hasPvChange &&
                      storeSettings?.show_last_pv &&
                      "font-bold text-[#323232]",
                  )}
                >
                  {new Intl.NumberFormat("fr-FR", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: storeCurrency ?? "EUR",
                  }).format(reference?.pv ?? 0)}
                </span>
              </>
            }
          />
        )}
        {shouldDisplayPv && shouldDisplayPrice && (
          <LabelAndValue
            value={formattedMargin}
            icon={
              <ChartPieIcon
                className={cn(
                  "text-muted-foreground/80 w-3.5 h-3.5 inline",
                  isBestMargin && "text-emerald-700",
                )}
              />
            }
            className={isBestMargin ? "text-emerald-700" : ""}
          />
        )}
      </div>
    </div>
  )
}
