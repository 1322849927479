// src/components/inventories/TopUnorderedReferencesChart.tsx

"use client"

import { useState, useEffect, useMemo } from "react"
import { Store } from "@/utils/__generated__/graphql"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useGetTopUnorderedReferencesQuery } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"
import { toast } from "sonner"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { add } from "date-fns"
import { useParams, useSearchParams } from "react-router-dom"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import TopUnorderedReferencesChartCard from "./TopUnorderedReferencesChartCard"

interface TopUnorderedReferencesChartProps {
  selectedStoreId: string
  dateRange: {
    from: Date | undefined
    to: Date | undefined
  }
  stores: Store[]
}

type ViewMode = "recurrence" | "amount" | "both"

export function TopUnorderedReferencesChart({
  selectedStoreId,
  dateRange,
  stores,
}: TopUnorderedReferencesChartProps) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeName = stores.find(
    (store) => store.id === selectedStoreId,
  )?.store_name

  // **Separate State for Input Fields**
  const [endRankInput, setEndRankInput] = useState<number>(10)
  const [startRankInput, setStartRankInput] = useState<number>(1)

  // **State for Query Variables**
  const [startRank, setStartRank] = useState<number>(1)
  const [endRank, setEndRank] = useState<number>(10)

  // **State for View Mode**
  const [viewMode, setViewMode] = useState<ViewMode>("recurrence")

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  // Calculate total days in the selected date range
  const totalDays = useMemo(() => {
    const start = new Date(fromDate)
    const end = new Date(toDate)
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1
  }, [fromDate, toDate])

  // Fetch top unordered references based on selected store and date range
  const { data, loading, error, refetch } = useGetTopUnorderedReferencesQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: franchiseParam,
        store_id: selectedStoreId!,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        start_rank: startRank > 0 ? startRank : 1,
        end_rank: endRank > 0 ? endRank : 10,
      },
    },
    skip: !companyId || !selectedStoreId || !fromDate || !toDate,
    fetchPolicy: "cache-and-network",
  })

  // **Refetch data when query variables change**
  useEffect(() => {
    if (selectedStoreId && companyId && fromDate && toDate) {
      refetch({
        input: {
          company_id: companyId,
          franchise_name: franchiseParam,
          store_id: selectedStoreId,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          start_rank: startRank ?? 1,
          end_rank: endRank ?? 10,
        },
      })
    }
  }, [
    selectedStoreId,
    companyId,
    franchiseParam,
    fromDate,
    toDate,
    startRank,
    endRank,
    refetch,
  ])

  // Transform fetched data for the chart
  const references = useMemo(() => {
    if (!data?.getTopUnorderedReferences.references) return []
    return data.getTopUnorderedReferences.references.map((ref) => ({
      saleName: ref.sale_name,
      TotalSales: ref.total_sales,
      OrderDays: ref.order_days,
      NotOrderedDays: totalDays - ref.order_days,
      TotalOrderedQuantity: ref.total_ordered_quantity, // Ensure this field is available from your GraphQL query
    }))
  }, [data, totalDays])

  // **Handle Form Submission**
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Validation: Ensure startRank <= endRank and endRank <= 20
    if (startRankInput > endRankInput) {
      toast.error(
        "Le nombre de références de départ doit être inférieur ou égal au nombre de références de fin.",
      )
      return
    }
    if (endRankInput > 20) {
      toast.error("Le nombre de références de fin ne peut pas dépasser 20.")
      return
    }
    setStartRank(startRankInput)
    setEndRank(endRankInput)
  }

  // **Handle Input Changes without Triggering Refetch**
  const handleStartRankChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    setStartRankInput(isNaN(value) ? 1 : value)
  }

  const handleEndRankChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10)
    if (value > 20) {
      value = 20
      toast.warning("Le nombre de références de fin est limité à 20.")
    }
    setEndRankInput(isNaN(value) ? 10 : value)
  }

  // **Handle View Mode Change**
  const handleViewModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewMode(e.target.value as ViewMode)
  }

  // **Handle loading state**
  if (loading) {
    return (
      <Card className="w-full max-w-6xl mx-auto">
        <CardHeader>
          <CardTitle>Top Références Non Commandées pour {storeName}</CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center items-center h-64">
          <Spinner />
        </CardContent>
      </Card>
    )
  }

  if (error && !data?.getTopUnorderedReferences.error) {
    const errorMessage =
      "Une erreur est survenue lors du chargement des données."
    toast.error(`Erreur: ${errorMessage}`)
    return (
      <Card className="w-full max-w-6xl mx-auto h-full overflow-y-auto">
        <CardHeader>
          <CardTitle>Top Références Non Commandées pour {storeName}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-red-500">{errorMessage}</div>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="h-full flex-1 p-4 flex flex-col overflow-y-auto">
      {/* **Form for Pagination Controls and View Mode Selector** */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 mb-4">
        {/* **Pagination Controls** */}
        <div className="flex items-center gap-2">
          <Label htmlFor="start-rank" className="text-sm">
            Nombre de références:
          </Label>
          <Input
            id="start-rank"
            type="number"
            min={1}
            value={startRankInput}
            onChange={handleStartRankChange}
            className="w-20 border border-gray-300 rounded p-1"
            required
          />
          <span>à</span>
          <Input
            id="end-rank"
            type="number"
            min={1}
            max={20} // **Set maximum to 20**
            value={endRankInput}
            onChange={handleEndRankChange}
            className="w-20 border border-gray-300 rounded p-1"
            required
          />
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Appliquer
          </button>
        </div>

        {/* **View Mode Selector** */}
        <div className="flex items-center gap-4">
          <span className="text-sm">Afficher:</span>
          <label className="flex items-center gap-1">
            <input
              type="radio"
              name="viewMode"
              value="recurrence"
              checked={viewMode === "recurrence"}
              onChange={handleViewModeChange}
            />
            <span>Récurrence</span>
          </label>
          <label className="flex items-center gap-1">
            <input
              type="radio"
              name="viewMode"
              value="amount"
              checked={viewMode === "amount"}
              onChange={handleViewModeChange}
            />
            <span>Quantité</span>
          </label>
          <label className="flex items-center gap-1">
            <input
              type="radio"
              name="viewMode"
              value="both"
              checked={viewMode === "both"}
              onChange={handleViewModeChange}
            />
            <span>Tout</span>
          </label>
        </div>
      </form>
      {selectedStoreId ? (
        <TopUnorderedReferencesChartCard
          storeName={storeName ?? "Inconnu"}
          references={references}
          viewMode={viewMode}
        />
      ) : (
        <Card className="w-full max-w-6xl mx-auto my-8">
          <CardHeader> </CardHeader>
          <CardContent>
            <div>
              Veuillez sélectionner un magasin pour afficher les références non
              commandées.
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
