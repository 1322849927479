"use client"
import { Checkbox } from "@/components/ui/checkbox"
import { Icon, Link2, PackageOpen, Ruler, Tag } from "lucide-react"
import { targetArrow } from "@lucide/lab"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { useState, useEffect, useMemo, useCallback } from "react"
import {
  ReferenceWithRegroupingOrdersIdaRecords,
  useGetReferenceWithRegroupingOrdersIdaQuery,
  useDeleteRegroupingOrdersIdaMutation,
} from "@/utils/__generated__/graphql"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useParams, useSearchParams } from "react-router-dom"
import { ArrowUpDown, Trash } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { OrdersRegroupingDataTable } from "./OrdersRegroupingDataTable"
import OrdersMatchingReferenceDialog from "./OrdersMatchingReferenceDialog"
import BatchDeleteOrdersReferenceDialog from "./BatchDeleteOrdersReferenceDialog"
import BatchMatchOrdersReferenceDialog from "./BatchMatchOrdersReferenceDialog"

const OrdersRegroupingTable: React.FC = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error, refetch } =
    useGetReferenceWithRegroupingOrdersIdaQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
        },
      },
      fetchPolicy: "cache-and-network",
      skip: !storeId,
    })

  const [deleteRegroupingOrdersIda, { loading: deleteLoading }] =
    useDeleteRegroupingOrdersIdaMutation()

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des références.")
    }
  }, [error])

  const [isMatchDialogOpen, setIsMatchDialogOpen] = useState(false)
  const [selectedReference, setSelectedReference] =
    useState<ReferenceWithRegroupingOrdersIdaRecords | null>(null)

  const [isBatchMatchDialogOpen, setIsBatchMatchDialogOpen] = useState(false)
  const [isBatchDeleteDialogOpen, setIsBatchDeleteDialogOpen] = useState(false)

  const [selectedRecords, setSelectedRecords] = useState<
    ReferenceWithRegroupingOrdersIdaRecords[]
  >([])

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const records: ReferenceWithRegroupingOrdersIdaRecords[] = useMemo(() => {
    return data?.getReferenceWithRegroupingOrdersIda.records ?? []
  }, [data])

  const selected = useMemo(() => {
    return records.filter((record) => rowSelection[record.order_id])
  }, [rowSelection, records])

  const allSelectedMatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => record.is_matched)
  }, [selected])

  const allSelectedUnmatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => !record.is_matched)
  }, [selected])

  const handleDelete = useCallback(
    async (supplierName: string, orderCode: string, orderName: string) => {
      try {
        const response = await deleteRegroupingOrdersIda({
          variables: {
            input: {
              company_id: companyId!,
              franchise_name: franchiseParam!,
              store_id: storeId!,
              order_code: orderCode,
              supplier_name: supplierName,
              order_name: orderName,
            },
          },
        })
        if (response.data?.deleteRegroupingOrdersIda.success) {
          toast.success("Référence supprimée avec succès.")
          refetch()
        } else {
          toast.error(
            response.data?.deleteRegroupingOrdersIda.error?.message ??
              "Échec de la suppression.",
          )
        }
      } catch (error) {
        toast.error("Une erreur est survenue lors de la suppression.")
      }
    },
    [deleteRegroupingOrdersIda, companyId, franchiseParam, storeId, refetch],
  )

  const handleBatchMatch = useCallback(() => {
    if (!allSelectedUnmatched) {
      toast.error("Toutes les références sélectionnées doivent être non liées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchMatchDialogOpen(true)
  }, [allSelectedUnmatched, selected])

  const handleBatchDelete = useCallback(() => {
    if (!allSelectedMatched) {
      toast.error("Toutes les références sélectionnées doivent être matchées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchDeleteDialogOpen(true)
  }, [allSelectedMatched, selected])

  const [hasShownMixedSelectionToast, setHasShownMixedSelectionToast] =
    useState(false)

  useEffect(() => {
    if (selected.length > 0) {
      const hasMatched = selected.some((record) => record.is_matched)
      const hasUnmatched = selected.some((record) => !record.is_matched)
      if (hasMatched && hasUnmatched) {
        if (!hasShownMixedSelectionToast) {
          toast.warning(
            "Vous avez sélectionné à la fois des références liées et non liées.",
          )
          setHasShownMixedSelectionToast(true)
        }
      } else {
        setHasShownMixedSelectionToast(false)
      }
    }
  }, [selected, hasShownMixedSelectionToast])

  const columns: ColumnDef<ReferenceWithRegroupingOrdersIdaRecords>[] = useMemo(
    () => [
      {
        accessorKey: "selecteur",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onCheckedChange={(checked) =>
              table.toggleAllRowsSelected(!!checked)
            }
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(checked) => row.toggleSelected(!!checked)}
          />
        ),
      },
      {
        accessorKey: "supplier_name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Fournisseur
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "order_name",
        cell: (info) => (
          <div className="text-left px-1 max-w-32">
            {info.getValue() as string}
          </div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Libellé achat
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "unit",
        header: () => (
          <div className="text-left flex gap-2">
            <Ruler className="h-4 w-4" />
            Unité
          </div>
        ),
      },
      {
        accessorKey: "colisage",
        header: () => (
          <div className="text-left flex gap-2">
            <PackageOpen className="h-4 w-4" />
            Colisage
          </div>
        ),
        cell: (info) => {
          const value = info.getValue<number>()
          return String(value) === "0.0" ? "-" : value
        },
      },
      {
        accessorKey: "order_code",
        header: "Code achat",
      },
      {
        accessorKey: "sale_name_ida_base",
        header: ({ column }) => (
          <Button
            className="text-left"
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Tag className="h-4 w-4" />
            Code vente base
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "sale_name_ida_cible",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Icon iconNode={targetArrow} />
            Code vente cible
          </Button>
        ),
        cell: (info) => (
          <div className="text-center">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "libelle_base",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Tag className="h-4 w-4" />
            Libellé base
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "libelle_cible",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            <Icon iconNode={targetArrow} />
            Libellé cible
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "is_matched",
        header: "Matché",
        cell: ({ getValue }) =>
          getValue<boolean>() ? (
            <Badge variant="default">Oui</Badge>
          ) : (
            <Badge variant="outline">Non</Badge>
          ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const saleNameIdaBase = row.original.sale_name_ida_base
          const isMatched = row.original.is_matched

          if (isMatched) {
            return (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="destructive"
                    size="sm"
                    disabled={deleteLoading}
                    className="flex items-center gap-2"
                  >
                    <Trash className="h-4 w-4" />
                    Supprimer
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Confirmer la suppression
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      Êtes-vous sûr de vouloir supprimer la référence &quot;
                      {saleNameIdaBase}&quot; ?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annuler</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() =>
                        handleDelete(
                          row.original.supplier_name,
                          row.original.order_code,
                          row.original.order_name,
                        )
                      }
                    >
                      Confirmer
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )
          } else {
            return (
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setSelectedReference(row.original)
                  setIsMatchDialogOpen(true)
                }}
                className="flex items-center gap-2"
              >
                <Link2 className="h-4 w-4" />
                Lier
              </Button>
            )
          }
        },
      },
    ],
    [deleteLoading, handleDelete],
  )

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden pt-2">
      <div className="flex justify-start px-2 gap-2">
        <Button
          onClick={handleBatchMatch}
          disabled={!allSelectedUnmatched}
          className="flex items-center space-x-2"
        >
          <Link2 className="h-4 w-4" />
          <span>Lier Tous</span>
        </Button>
        <Button
          variant="destructive"
          onClick={handleBatchDelete}
          disabled={!allSelectedMatched}
          className="flex items-center space-x-2"
        >
          <Trash className="h-4 w-4" />
          <span>Supprimer Tous</span>
        </Button>
      </div>
      <OrdersRegroupingDataTable
        columns={columns}
        data={records}
        loading={loading}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      {selectedReference && (
        <OrdersMatchingReferenceDialog
          isOpen={isMatchDialogOpen}
          onClose={() => setIsMatchDialogOpen(false)}
          baseReference={selectedReference}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          allReferences={records}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}

      {selectedRecords.length > 0 && isBatchMatchDialogOpen && (
        <BatchMatchOrdersReferenceDialog
          isOpen={isBatchMatchDialogOpen}
          onClose={() => setIsBatchMatchDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          allReferences={records}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}

      {selectedRecords.length > 0 && isBatchDeleteDialogOpen && (
        <BatchDeleteOrdersReferenceDialog
          isOpen={isBatchDeleteDialogOpen}
          onClose={() => setIsBatchDeleteDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}
    </div>
  )
}

export default OrdersRegroupingTable
