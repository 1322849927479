// src/components/MatchReferenceDialog.tsx

"use client"

import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  ReferenceWithRegroupingIdaRecords,
  useCreateRegroupingIdaMutation,
} from "@/utils/__generated__/graphql"
import { toast } from "sonner"
import { Spinner } from "@/ui/Spinner"
import { TrendingUp } from "lucide-react"
import { Virtuoso } from "react-virtuoso"

interface MatchReferenceDialogProps {
  isOpen: boolean
  onClose: () => void
  baseReference: ReferenceWithRegroupingIdaRecords
  refetch: () => void
  allReferences: ReferenceWithRegroupingIdaRecords[]
  storeId: string
  companyId: string
  franchiseParam: string | undefined
}

const MatchReferenceDialog: React.FC<MatchReferenceDialogProps> = ({
  isOpen,
  onClose,
  baseReference,
  refetch,
  allReferences,
  storeId,
  companyId,
  franchiseParam,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredOptions, setFilteredOptions] = useState<
    ReferenceWithRegroupingIdaRecords[]
  >([])
  const [selectedTarget, setSelectedTarget] =
    useState<ReferenceWithRegroupingIdaRecords | null>(null)

  const [createRegroupingIdaMutation, { loading: mutationLoading }] =
    useCreateRegroupingIdaMutation()

  useEffect(() => {
    if (isOpen) {
      setSearchTerm("")
      setSelectedTarget(null)
      setFilteredOptions([]) // Initialiser à vide au lieu de pré-filtrer
    }
  }, [isOpen, allReferences, baseReference])

  const handleSearch = (term: string) => {
    setSearchTerm(term)
    if (term.trim() === "") {
      setFilteredOptions([])
      return
    }
    const lowerTerm = term.toLowerCase()
    const filtered = allReferences.filter(
      (ref) =>
        ref.sale_name_ida_base !== baseReference.sale_name_ida_base &&
        !ref.is_matched &&
        (ref.libelle_base.toLowerCase().includes(lowerTerm) ||
          ref.sale_name_ida_base.toLowerCase().includes(lowerTerm)),
    )
    setFilteredOptions(filtered)
  }

  const handleMatch = async () => {
    if (!selectedTarget) {
      toast.error("Veuillez sélectionner une référence cible.")
      return
    }

    const mutationInput = {
      sale_name_ida_base: baseReference.sale_name_ida_base,
      sale_name_ida_cible: selectedTarget.sale_name_ida_base,
      libelle_base: baseReference.libelle_base,
      libelle_cible: selectedTarget.libelle_base,
      store_id: storeId,
      company_id: companyId,
      franchise_name: franchiseParam,
    }

    try {
      const { data } = await createRegroupingIdaMutation({
        variables: {
          input: mutationInput,
        },
      })

      if (data?.createRegroupingIda?.records) {
        toast.success("Référence liée avec succès.")
        refetch()
        onClose()
      } else if (data?.createRegroupingIda?.error) {
        toast.error(data?.createRegroupingIda?.error?.message)
      } else {
        toast.error("Échec de la liaison.")
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        toast.error("Erreur", {
          description:
            error.message || "Une erreur s'est produite lors de la liaison.",
        })
      } else {
        toast.error("Erreur", {
          description: "Une erreur inattendue s'est produite.",
        })
      }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Lier la Référence</DialogTitle>
          <DialogDescription>
            Sélectionnez une référence cible pour lier avec{" "}
            <strong>{baseReference.sale_name_ida_base}</strong> -{" "}
            <em>{baseReference.libelle_base}</em>.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground">
              <TrendingUp className="h-4 w-4" />
            </span>
            <Input
              placeholder="Rechercher une référence..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4 pl-10"
            />
          </div>

          {mutationLoading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          <div className="h-60">
            {searchTerm.trim() === "" ? (
              <div className="text-gray-500">
                Veuillez saisir un terme de recherche pour afficher les
                références.
              </div>
            ) : filteredOptions.length === 0 && !mutationLoading ? (
              <div className="text-gray-500">Aucune référence trouvée.</div>
            ) : (
              <Virtuoso
                className="h-60"
                data={filteredOptions}
                itemContent={(_, filteredOption) => (
                  <Button
                    key={filteredOption.sale_name_ida_base}
                    variant={
                      selectedTarget?.sale_name_ida_base ===
                      filteredOption.sale_name_ida_base
                        ? "default"
                        : "outline"
                    }
                    onClick={() => setSelectedTarget(filteredOption)}
                    className="w-full text-left mb-2"
                  >
                    {filteredOption.libelle_base} (
                    {filteredOption.sale_name_ida_base})
                  </Button>
                )}
              />
            )}
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Annuler
            </Button>
            <Button
              onClick={handleMatch}
              disabled={mutationLoading || !selectedTarget}
            >
              {mutationLoading ? <Spinner /> : "Lier"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MatchReferenceDialog
