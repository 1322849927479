import { removeDuplicates } from "@/utils/removeDuplicates"

export interface ModalsReducerState {
  lastPerformancesModalCloseDate: string | undefined
  closedMessages: string[]
}

export const defaultModalsReducerState: ModalsReducerState = {
  lastPerformancesModalCloseDate: undefined,
  closedMessages: [],
}

interface SetModalsReducerState {
  type: "setModalsReducerState"
  payload: ModalsReducerState
}

interface CloseLastPerformancesModal {
  type: "closeLastPerformancesModal"
  payload: Date
}

interface CloseMessages {
  type: "closeMessages"
  payload: string[]
}

export type ModalsReducerAction =
  | SetModalsReducerState
  | CloseLastPerformancesModal
  | CloseMessages

export const modalsReducer = (
  state: ModalsReducerState = defaultModalsReducerState,
  action: ModalsReducerAction,
): ModalsReducerState => {
  switch (action.type) {
    case "setModalsReducerState":
      return {
        ...state,
        ...action.payload,
      }
    case "closeLastPerformancesModal":
      return {
        ...state,
        lastPerformancesModalCloseDate: action.payload.toString(),
      }
    case "closeMessages":
      return {
        ...state,
        closedMessages: removeDuplicates([
          ...state.closedMessages,
          ...action.payload,
        ]),
      }
    default:
      return state
  }
}
