import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { DispatchActionType, StateType } from "@/types"
import { SaveAlertIcon } from "@/ui/AlertIcons"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"
import {
  BatchUpdateData,
  BatchUpdateMutation,
  LogsModificationUpdateMutation,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { getBatchData } from "@/utils/getBatchData"
import { FetchResult } from "@apollo/client"
import { captureException } from "@sentry/react"
import { Dispatch, SetStateAction } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

export interface SaveDataModalState {
  isOpen: boolean
  backToHome: boolean
}

interface SaveDataModalProps {
  saveDataModalState: SaveDataModalState
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
}

export function RetrySaveModal({
  saveDataModalState,
  setSaveDataModalState,
}: SaveDataModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const [batchUpdateMutation, { loading: batchUpdateLoading }] =
    useBatchUpdateMutation()
  const [
    logsModificationUpdateMutation,
    { loading: logsModificationUpdateLoading },
  ] = useLogsModificationUpdateMutation()

  const {
    updatedReferences,
    modifications,
    mercurialAndStoreInventories,
    dimMercuriales,
  } = useSelector((state: StateType) => state.mercurialReducer)
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  return (
    <Modal
      icon={<SaveAlertIcon />}
      title="Tentative de sauvegarde échouée"
      open={saveDataModalState.isOpen}
      hideCloseButton
      onClose={() => {
        setSaveDataModalState({ isOpen: false, backToHome: false })
      }}
    >
      <p className="text-[#667085] text-sm font-normal text-center">
        Votre connexion internet est instable. Veuillez résoudre vos problèmes
        de réseau et réessayer de sauvegarder vos données en cliquant sur le
        bouton ci-dessous.
      </p>
      <Button
        onClick={async () => {
          if (modifications.length <= 0) return
          const notSavedReferences = Object.values(updatedReferences).reduce<
            MercurialReducerState["updatedReferences"]
          >((acc, curr) => {
            if (
              modifications.some(
                (modification) =>
                  modification.mercuriale_id === curr.mercurialeId,
              )
            ) {
              acc[curr.mercurialeId] = curr
              return acc
            }
            return acc
          }, {})
          const batchData = getBatchData(
            mercurialAndStoreInventories,
            notSavedReferences,
          )
          try {
            let batchUpdateResults:
              | FetchResult<BatchUpdateMutation>[]
              | undefined = undefined

            const filteredBatchDatas = dimMercuriales?.reduce<
              {
                dimOrderRequestId: string | undefined
                data: BatchUpdateData[]
              }[]
            >((acc, curr) => {
              const filteredBatchData = batchData.filter(
                (data) => data.dim_mercuriale_id === curr.dimMercurialeId,
              )
              if (filteredBatchData.length === 0) return acc
              return [
                ...acc,
                {
                  dimOrderRequestId: curr.dimOrderRequestId,
                  data: filteredBatchData,
                },
              ]
            }, [])

            if (filteredBatchDatas !== undefined) {
              batchUpdateResults = await Promise.all(
                filteredBatchDatas.map((filteredBatchData) =>
                  batchUpdateMutation({
                    variables: {
                      input: {
                        batch_data: filteredBatchData.data,
                        dim_order_request_id:
                          filteredBatchData.dimOrderRequestId,
                        store_id: storeId ?? "",
                        inventory_type: undefined,
                      },
                    },
                  }),
                ),
              )

              batchUpdateResults.forEach((result) => {
                if (result.data?.batchUpdate?.error !== null) {
                  throw result.data?.batchUpdate.error
                }
              })
            }

            let logsModificationUpdateResult:
              | FetchResult<LogsModificationUpdateMutation>
              | undefined = undefined

            logsModificationUpdateResult = await logsModificationUpdateMutation(
              {
                variables: {
                  input: {
                    store_id: storeId ?? "",
                    modifications_logs_items: modifications,
                  },
                },
              },
            )
            if (
              logsModificationUpdateResult.data?.logsModificationUpdate
                ?.error !== null
            ) {
              throw logsModificationUpdateResult.data?.logsModificationUpdate
                ?.error
            }

            batchUpdateResults?.forEach((batchUpdateResult, i) => {
              const dimMercurialeId =
                filteredBatchDatas?.[i].data[0].dim_mercuriale_id
              if (
                typeof batchUpdateResult?.data?.batchUpdate
                  .dim_order_request_id === "string" &&
                typeof dimMercurialeId === "string"
              ) {
                dispatch({
                  type: "setDimOrderRequestId",
                  payload: {
                    dimMercurialeId: dimMercurialeId,
                    dimOrderRequestId:
                      batchUpdateResult.data?.batchUpdate.dim_order_request_id,
                  },
                })
              }
            })
            dispatch({
              type: "setModifications",
              payload: [],
            })
            dispatch({
              type: "setDataSynchronizationStatus",
              payload: DataSynchronizationStatus.SYNCHRONIZED,
            })
            setSaveDataModalState({ isOpen: false, backToHome: false })
            if (saveDataModalState.backToHome) {
              navigate("/home")
            }
          } catch (error) {
            console.error(error)
            captureException(error)
            toast.error("Données non sauvegardées")
          }
        }}
        loading={batchUpdateLoading || logsModificationUpdateLoading}
        className="w-full h-11 text-base mt-4 md:mt-8"
      >
        Réessayer
      </Button>
      <Button
        onClick={() => {
          setSaveDataModalState({ isOpen: false, backToHome: false })
          if (saveDataModalState.backToHome) {
            navigate("/home")
          }
        }}
        className="w-full h-11 text-base"
        color="ghost"
      >
        Annuler
      </Button>
    </Modal>
  )
}
