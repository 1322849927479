"use client"

import { useState, useMemo } from "react"
import { StateType } from "@/types"
import { useGetStoresQuery } from "@/utils/__generated__/graphql"
import { StoreSelector } from "./components/StoreSelector"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { add } from "date-fns"
import { DateRange } from "react-day-picker"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { DateSelector } from "./components/DateSelector"
import { Spinner } from "@/ui/Spinner"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  BarChart3,
  LineChart,
  PackageSearch,
  PanelRightClose,
  PanelRightOpen,
  Star,
} from "lucide-react"
import StoreReferencePredictionsPage from "./components/predictions/StoreReferencePredictionsPage"
import GlobalReferencePredictionsPage from "./components/predictions/GlobalReferencePredictionsPage"
import { StoreModificationsChart } from "./components/inventories/StoreModificationsChart"
import ModificationsChartByStore from "./components/inventories/ModificationsChartByStore"
import GlobalModificationRateChart from "./components/inventories/GlobalModificationRateChart"
import { TopUnorderedReferencesChart } from "./components/topReference/TopUnorderedReferencesChart"
import { TopUnorderedReferencesGlobalChart } from "./components/topReference/TopUnorderedReferencesGlobalChart"
import { PerformancesByStore } from "./components/performance/PerformancesByStore"
import { twJoin } from "tailwind-merge"

const sidebarNavItems = [
  {
    id: "global",
    title: "Performance par magasin",
    icon: BarChart3,
  },
  {
    id: "prediction",
    title: "Évaluation des prédictions",
    icon: LineChart,
  },
  {
    id: "inventory",
    title: "Modifications de stock",
    icon: PackageSearch,
  },
  {
    id: "top-unordered-global",
    title: "Suivi des tops Références",
    icon: Star,
  },
] as const

type NavItem = (typeof sidebarNavItems)[number]["id"]

export function PerformancesPage() {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined
  const companyId = params.companyId

  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null)
  const [activeNav, setActiveNav] = useState<NavItem>("global")
  const [subNav, setSubNav] = useState("by-store")

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const {
    data: storesData,
    loading: storesLoading,
    error: storesError,
  } = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise: franchiseParam,
        include_inactive: false,
      },
    },
  })

  const stores = useMemo(
    () => storesData?.getStores?.stores ?? [],
    [storesData],
  )

  if (storesLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (storesError) {
    return <div className="text-red-500">Error fetching stores.</div>
  }

  const renderContent = () => {
    switch (activeNav) {
      case "global":
        return (
          <div className="flex-1 overflow-y-auto">
            <PerformancesByStore
              storeId={storeId!}
              companyId={companyId!}
              franchise={franchiseParam}
              fromDate={fromDate}
              toDate={toDate}
            />
          </div>
        )
      case "top-unordered-global":
        return (
          <div className="flex-1 overflow-y-auto">
            <div className="h-full flex flex-col gap-4">
              <div className="flex gap-2">
                <Button
                  variant={subNav === "by-store" ? "default" : "outline"}
                  onClick={() => setSubNav("by-store")}
                >
                  Par magasin
                </Button>
                <Button
                  variant={subNav === "global" ? "default" : "outline"}
                  onClick={() => setSubNav("global")}
                >
                  Risque de rupture
                </Button>
              </div>
              {subNav === "by-store" ? (
                <TopUnorderedReferencesChart
                  dateRange={{ from: fromDate, to: toDate }}
                  stores={stores}
                  selectedStoreId={selectedStoreId!}
                />
              ) : (
                <TopUnorderedReferencesGlobalChart
                  dateRange={{ from: fromDate, to: toDate }}
                  stores={stores}
                />
              )}
            </div>
          </div>
        )
      case "prediction":
        return (
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <Button
                  variant={subNav === "by-store" ? "default" : "outline"}
                  onClick={() => setSubNav("by-store")}
                >
                  Par magasin
                </Button>
                <Button
                  variant={subNav === "by-reference" ? "default" : "outline"}
                  onClick={() => setSubNav("by-reference")}
                >
                  Par référence
                </Button>
              </div>
              {subNav === "by-store" ? (
                <StoreReferencePredictionsPage
                  dateRange={{ from: fromDate, to: toDate }}
                  selectedStoreId={selectedStoreId!}
                  stores={stores}
                />
              ) : (
                <GlobalReferencePredictionsPage
                  dateRange={{ from: fromDate, to: toDate }}
                  stores={stores}
                />
              )}
            </div>
          </div>
        )
      case "inventory":
        return (
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <Button
                  variant={subNav === "global-store" ? "default" : "outline"}
                  onClick={() => setSubNav("global-store")}
                >
                  Classement des magasins
                </Button>
                <Button
                  variant={subNav === "by-store" ? "default" : "outline"}
                  onClick={() => setSubNav("by-store")}
                >
                  Evolution pour un magasin
                </Button>
                <Button
                  variant={
                    subNav === "global-modification-rate"
                      ? "default"
                      : "outline"
                  }
                  onClick={() => setSubNav("global-modification-rate")}
                >
                  Evolution global du taux
                </Button>
              </div>
              {subNav === "global-store" ? (
                <StoreModificationsChart
                  companyId={companyId!}
                  franchiseName={franchiseParam!}
                  startDate={formatDateToYYYYMMDD(fromDate)}
                  endDate={formatDateToYYYYMMDD(toDate)}
                  storeId={storeId!}
                />
              ) : subNav === "by-store" ? (
                <ModificationsChartByStore
                  selectedStoreId={selectedStoreId!}
                  dateRange={{ from: fromDate, to: toDate }}
                  stores={stores}
                />
              ) : (
                <GlobalModificationRateChart
                  companyId={companyId!}
                  franchiseName={franchiseParam!}
                  dateRange={{ from: fromDate, to: toDate }}
                  storeId={storeId!}
                />
              )}
            </div>
          </div>
        )
    }
  }

  return (
    <div className="flex w-full h-full">
      <div
        className={twJoin(
          "border-r p-4 transition-all flex flex-col",
          "text-gray-800",

          isSidebarOpen ? "min-w-64 w-64" : "min-w-20 w-20",
        )}
      >
        <div className="flex items-center mb-4 border-b">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            className="mr-2"
          >
            {isSidebarOpen ? (
              <PanelRightOpen className="h-5 w-5" />
            ) : (
              <PanelRightClose className="h-5 w-5" />
            )}
          </Button>
          {isSidebarOpen && (
            <h2 className="text-lg font-semibold truncate ">Dashboard</h2>
          )}
        </div>
        {sidebarNavItems.map((item) => {
          const Icon = item.icon
          return (
            <Button
              key={item.id}
              variant={activeNav === item.id ? "default" : "ghost"}
              className={cn(
                "w-full justify-start gap-2",
                activeNav === item.id && "bg-primary",
              )}
              onClick={() => setActiveNav(item.id)}
            >
              <Icon className="h-4 w-4" />
              {isSidebarOpen && item.title}
            </Button>
          )
        })}
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex gap-2 px-2 py-3 w-fit">
          <StoreSelector
            stores={stores}
            selectedStoreId={selectedStoreId}
            onSelect={(storeId) => setSelectedStoreId(storeId)}
          />
          <DateSelector
            fromDate={fromDate}
            toDate={toDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchParams={setSearchParams}
            franchiseParam={franchiseParam}
          />
        </div>
        {renderContent()}
      </div>
    </div>
  )
}
