import { StoreReducerState } from "@/reducers/storeReducer"
import { usePotentialRevenue } from "@/hooks/usePotentialRevenue"

export const SupplierFrancoAmount = ({
  minimalOrderValue,
  storeCurrency,
  storeSupplierId,
}: {
  minimalOrderValue: number | undefined
  storeCurrency: StoreReducerState["storeCurrency"]
  storeSupplierId: string
}) => {
  const { orderPrice } = usePotentialRevenue({
    suppliersIds: [storeSupplierId],
  })

  if ((orderPrice ?? 0) <= 0) {
    return <></>
  }

  return (
    <p
      className={`ml-2 font-bold ${(orderPrice ?? 0) < (minimalOrderValue ?? 0) ? "text-red-200" : "text-green-600"}`}
    >
      {new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: "currency",
        currency: storeCurrency ?? "EUR",
      }).format(orderPrice ?? 0)}
    </p>
  )
}
