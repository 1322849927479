enum Units {
  kg = "kg",
  pce = "pce",
  cs = "cs",
}

const companiesCustomUnits: Record<string, Record<Units, string>> = {
  auchan: {
    kg: "k",
    pce: "p",
    cs: "c",
  },
  default: {
    kg: "kg",
    pce: "pce",
    cs: "cs",
  },
}

export function getNormalizedUnit(
  unit: string | null | undefined,
): Units | undefined {
  switch (unit?.toLowerCase()) {
    case "kg":
    case "kilo":
      return Units.kg
    case "pc":
    case "piece":
    case "pce":
      return Units.pce
    case "cs":
      return Units.cs
  }
}

export function getDisplayedUnit(
  unit: string | null | undefined,
  companyName?: string | null,
  franchise?: string | null,
): string | undefined {
  const normalizedUnit = getNormalizedUnit(unit)
  if (normalizedUnit === undefined) return undefined

  const companyKey = franchise ?? companyName ?? "default"
  const companyUnits =
    companiesCustomUnits[companyKey] ?? companiesCustomUnits.default

  return companyUnits[normalizedUnit]
}
