import { useMemo } from "react"
import { type MercurialReducerState } from "../../../reducers/mercurialReducer"
import { useResetPrediction } from "./useResetPrediction"
import { RotateCcw } from "lucide-react"

interface ResetPredictionButtonProps {
  reference: MercurialReducerState["mercurialAndStoreInventories"][number]
  disabled?: boolean
}

export const ResetPredictionButton = ({
  reference,
  disabled = false,
}: ResetPredictionButtonProps): JSX.Element => {
  const { isDisabled, reset } = useResetPrediction({ reference, disabled })

  const transformStyle = useMemo(() => {
    return isDisabled
      ? { transform: "translateX(-110%)" }
      : { transform: "translateX(-10%)" }
  }, [isDisabled])

  return (
    <div className="h-full flex items-center">
      <button
        disabled={isDisabled}
        onClick={() => {
          void reset()
        }}
        className="border border-l-0 flex size-8 transition ease-in duration-200 items-center justify-center bg-white rounded-r-full shadow hover:bg-zinc-100"
        style={transformStyle}
      >
        <RotateCcw className="size-4 text-gray-500" />
      </button>
    </div>
  )
}
