"use client"

import { useState, useMemo } from "react"
import { useGetStoresQuery } from "@/utils/__generated__/graphql"
import { useParams, useSearchParams } from "react-router-dom"
import { Spinner } from "@/ui/Spinner"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  CheckCircle,
  PackageSearch,
  PanelRightClose,
  PanelRightOpen,
  LucideTarget,
} from "lucide-react"
import { twJoin } from "tailwind-merge"
import MatchedReferencesTable from "./components/matchedReferencesTable"
import UnmatchedReferencesTable from "./components/unmatchedReferencesTable"
import OrdersRegroupingTable from "./orders_regrouping_components/ordersRegroupingTable"

const sidebarNavItems = [
  {
    id: "matching",
    title: "Suivi du non liée",
    icon: PackageSearch,
  },
  {
    id: "orders-regrouping",
    title: "Achat",
    icon: LucideTarget,
  },
  {
    id: "matched",
    title: "Vente",
    icon: CheckCircle,
  },
] as const

type NavItem = (typeof sidebarNavItems)[number]["id"]

export function RegroupingIdaPage() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  const [activeNav, setActiveNav] = useState<NavItem>("matching")
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const {
    data: storesData,
    loading: storesLoading,
    error: storesError,
  } = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise: franchiseParam,
        include_inactive: false,
      },
    },
  })

  const stores = useMemo(
    () => storesData?.getStores?.stores ?? [],
    [storesData],
  )

  if (storesLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (storesError) {
    return <div className="text-red-500">Error fetching stores.</div>
  }

  const renderContent = () => {
    switch (activeNav) {
      case "matching":
        return <UnmatchedReferencesTable stores={stores} />
      case "matched":
        return <MatchedReferencesTable />
      case "orders-regrouping":
        return <OrdersRegroupingTable />
    }
  }

  return (
    <div className="flex w-full h-full">
      <div
        className={twJoin(
          "border-r p-4 transition-all flex flex-col",
          "text-gray-800",

          isSidebarOpen ? "min-w-64 w-64" : "min-w-20 w-20",
        )}
      >
        <div className="flex items-center mb-4 border-b">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            className="mr-2"
          >
            {isSidebarOpen ? (
              <PanelRightOpen className="h-5 w-5" />
            ) : (
              <PanelRightClose className="h-5 w-5" />
            )}
          </Button>
          {isSidebarOpen && (
            <h2 className="text-lg font-semibold truncate ">Regroupement</h2>
          )}
        </div>

        {/* Navigation Items */}
        <div className="flex-1 flex flex-col space-y-1">
          {sidebarNavItems.map((item) => {
            const Icon = item.icon
            const active = activeNav === item.id
            return (
              <Button
                key={item.id}
                variant={active ? "default" : "ghost"}
                className={cn(
                  "w-full justify-start gap-2 rounded-md px-2 py-2 hover:bg-gray-300",
                  active && "bg-primary text-white hover:bg-primary/90",
                )}
                onClick={() => setActiveNav(item.id)}
              >
                <Icon className="h-5 w-5" />
                {isSidebarOpen && item.title}
              </Button>
            )
          })}
        </div>
      </div>
      <div className="flex-1 w-full h-full overflow-x-hidden flex flex-col">
        {renderContent()}
      </div>
    </div>
  )
}
