// src/components/MatchedReferencesTable.tsx

"use client"

import { ColumnDef } from "@tanstack/react-table"
import React, { useMemo, useEffect, useState, useCallback } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  useGetReferenceWithRegroupingIdaQuery,
  useDeleteRegroupingIdaMutation,
  ReferenceWithRegroupingIdaRecords,
} from "@/utils/__generated__/graphql"
import { MatchedDataTable } from "./MatchedDataTable"
import { Button } from "@/components/ui/button"
import { ArrowUpDown, Icon, Link2, Ruler, Tag, Trash } from "lucide-react"
import { toast } from "sonner"

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { StateType } from "@/types"
import { Badge } from "@/components/ui/badge"
import { Checkbox } from "@/components/ui/checkbox"
import MatchReferenceDialog from "./MatchReferenceDIalog"
import BatchMatchReferenceDialog from "./BatchMatchReferenceDialog"
import BatchDeleteReferenceDialog from "./BatchDeleteReferenceDialog"
import { targetArrow } from "@lucide/lab"

const MatchedReferencesTable: React.FC = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error, refetch } =
    useGetReferenceWithRegroupingIdaQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
        },
      },
      fetchPolicy: "cache-and-network",
    })

  const [deleteRegroupingIda, { loading: deleteLoading }] =
    useDeleteRegroupingIdaMutation()

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des références.")
    }
  }, [error])
  const [isMatchDialogOpen, setIsMatchDialogOpen] = useState(false)
  const [selectedReference, setSelectedReference] =
    useState<ReferenceWithRegroupingIdaRecords | null>(null)

  const [isBatchMatchDialogOpen, setIsBatchMatchDialogOpen] = useState(false)
  const [isBatchDeleteDialogOpen, setIsBatchDeleteDialogOpen] = useState(false) // New State

  const [selectedRecords, setSelectedRecords] = useState<
    ReferenceWithRegroupingIdaRecords[]
  >([])

  // Manage row selection state
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const records: ReferenceWithRegroupingIdaRecords[] = useMemo(() => {
    return data?.getReferenceWithRegroupingIda.records ?? []
  }, [data])

  const selected = useMemo(() => {
    return records.filter((record) => rowSelection[record.sale_name_ida_base])
  }, [rowSelection, records])

  const allSelectedMatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => record.is_matched)
  }, [selected])

  const allSelectedUnmatched = useMemo(() => {
    return selected.length > 0 && selected.every((record) => !record.is_matched)
  }, [selected])

  const handleDelete = useCallback(
    async (saleNameIdaBase: string) => {
      try {
        const response = await deleteRegroupingIda({
          variables: {
            input: {
              sale_name_ida_base: saleNameIdaBase,
              company_id: companyId!,
              franchise_name: franchiseParam!,
              store_id: storeId!,
            },
          },
        })
        if (response.data?.deleteRegroupingIda.success) {
          toast.success("Référence supprimée avec succès.")
          refetch()
        } else {
          toast.error(
            response.data?.deleteRegroupingIda.error?.message ??
              "Échec de la suppression.",
          )
        }
      } catch (err) {
        toast.error("Erreur lors de la suppression.")
      }
    },
    [deleteRegroupingIda, companyId, franchiseParam, storeId, refetch],
  )

  const handleBatchMatch = useCallback(() => {
    if (!allSelectedUnmatched) {
      toast.error("Toutes les références sélectionnées doivent être non liées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchMatchDialogOpen(true)
  }, [allSelectedUnmatched, selected])

  const handleBatchDelete = useCallback(() => {
    if (!allSelectedMatched) {
      toast.error("Toutes les références sélectionnées doivent être matchées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchDeleteDialogOpen(true)
  }, [allSelectedMatched, selected])
  const [hasShownMixedSelectionToast, setHasShownMixedSelectionToast] =
    useState(false)

  useEffect(() => {
    if (selected.length > 0) {
      const hasMatched = selected.some((record) => record.is_matched)
      const hasUnmatched = selected.some((record) => !record.is_matched)
      if (hasMatched && hasUnmatched) {
        if (!hasShownMixedSelectionToast) {
          toast.warning(
            "Vous avez sélectionné à la fois des références liées et non liées.",
          )
          setHasShownMixedSelectionToast(true)
        }
      } else {
        setHasShownMixedSelectionToast(false)
      }
    } else {
      setHasShownMixedSelectionToast(false)
    }
  }, [selected, hasShownMixedSelectionToast])

  const columns: ColumnDef<ReferenceWithRegroupingIdaRecords>[] = useMemo(
    () => [
      {
        accessorKey: "selecteur",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onCheckedChange={(checked) =>
              table.toggleAllRowsSelected(checked as boolean)
            }
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(checked) =>
              row.toggleSelected(checked as boolean)
            }
          />
        ),
      },
      {
        accessorKey: "sale_name_ida_base",
        header: ({ column }) => (
          <Button
            className="text-center"
            variant="ghost"
            onClick={() => column.toggleSorting()}
          >
            <Tag className="h-4 w-4" />
            Code vente base
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left px-1 max-w-28">
            {info.getValue() as string}
          </div>
        ),
      },
      {
        accessorKey: "sale_name_ida_cible",
        header: ({ column }) => (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
            <Icon iconNode={targetArrow} />
            Code vente cible
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left px-1 max-w-28">
            {info.getValue() as string}
          </div>
        ),
      },
      {
        accessorKey: "libelle_base",
        header: ({ column }) => (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
            <Tag className="h-4 w-4" />
            Libellé base
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left px-1 max-w-28">
            {info.getValue() as string}
          </div>
        ),
      },
      {
        accessorKey: "libelle_cible",
        header: ({ column }) => (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
            <Icon iconNode={targetArrow} />
            Libellé cible
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-left px-1 max-w-28">
            {info.getValue() as string}
          </div>
        ),
      },
      {
        accessorKey: "unit",
        header: () => (
          <div className="text-left flex gap-2">
            <Ruler className="h-4 w-4" />
            Unité
          </div>
        ),
        cell: (info) => (
          <div className="text-left px-1 max-w-28">
            {info.getValue() as string}
          </div>
        ),
      },

      {
        accessorKey: "is_matched",
        header: "Matché",
        cell: ({ getValue }) =>
          getValue<boolean>() ? (
            <Badge variant="default">Oui</Badge>
          ) : (
            <Badge variant="outline">Non</Badge>
          ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const saleNameIdaBase = row.original.sale_name_ida_base
          const isMatched = row.original.is_matched

          if (isMatched) {
            // Bouton de suppression pour les références matchées
            return (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="destructive"
                    size="sm"
                    disabled={deleteLoading}
                    className="flex items-center gap-2"
                  >
                    <Trash className="h-4 w-4" />
                    Supprimer
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Confirmer la suppression
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      Êtes-vous sûr de vouloir supprimer la référence &quot;
                      {saleNameIdaBase}
                      &quot; ?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annuler</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => handleDelete(saleNameIdaBase)}
                    >
                      Confirmer
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )
          } else {
            return (
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setSelectedReference(row.original)
                  setIsMatchDialogOpen(true)
                }}
                className="flex items-center gap-2"
              >
                Lier
                <ArrowUpDown className="h-4 w-4" />
              </Button>
            )
          }
        },
      },
    ],
    [deleteLoading, handleDelete],
  )

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden pt-2">
      <div className="flex justify-start px-2 gap-2">
        <Button
          onClick={handleBatchMatch}
          disabled={!allSelectedUnmatched}
          className="flex items-center space-x-2"
        >
          <Link2 className="h-4 w-4" />
          <span>Lier Tous</span>
        </Button>
        <Button
          variant="destructive"
          onClick={handleBatchDelete}
          disabled={!allSelectedMatched}
          className="flex items-center space-x-2"
        >
          <Trash className="h-4 w-4" />
          <span>Supprimer Tous</span>
        </Button>
      </div>
      <MatchedDataTable
        columns={columns}
        data={records}
        loading={loading}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      {selectedReference && (
        <MatchReferenceDialog
          isOpen={isMatchDialogOpen}
          onClose={() => setIsMatchDialogOpen(false)}
          baseReference={selectedReference}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          allReferences={records}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}

      {selectedRecords.length > 0 && isBatchMatchDialogOpen && (
        <BatchMatchReferenceDialog
          isOpen={isBatchMatchDialogOpen}
          onClose={() => setIsBatchMatchDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          allReferences={records}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}

      {selectedRecords.length > 0 && isBatchDeleteDialogOpen && (
        <BatchDeleteReferenceDialog
          isOpen={isBatchDeleteDialogOpen}
          onClose={() => setIsBatchDeleteDialogOpen(false)}
          selectedReferences={selectedRecords}
          refetch={() => {
            refetch()
            setRowSelection({})
          }}
          storeId={storeId!}
          companyId={companyId!}
          franchiseParam={franchiseParam}
        />
      )}
    </div>
  )
}

export default MatchedReferencesTable
