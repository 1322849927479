import { useUpdateDimStoreArticleMutation } from "../../utils/__generated__/graphql"
import { Paysan } from "../../ui/icons/Paysan"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "../../types"
import { toast } from "sonner"
import { captureException } from "@sentry/react"
import { twJoin } from "tailwind-merge"
import { Button } from "../ui/button"

interface LocalFlagToggleProps {
  reference: Partial<AllMercurialInfo>
  storeId: string | null
}

const LocalFlagToggle = ({ reference, storeId }: LocalFlagToggleProps) => {
  const dispatch = useDispatch<DispatchActionType>()
  const [updateLocalFlag, { loading }] = useUpdateDimStoreArticleMutation()
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const localFlag = reference.local_flag ?? false

  const handleClick = async () => {
    if (typeof reference.mercuriale_id !== "string") return

    try {
      dispatch({
        type: "updateReference",
        payload: {
          mercurialeId: reference.mercuriale_id,
          local_flag: !localFlag,
        },
      })
      if (isTestMode === true) return

      const result = await updateLocalFlag({
        variables: {
          input: {
            order_id: reference.order_id!,
            store_id: storeId ?? "",
            article_name: reference.mercuriale_name ?? "",
            local_flag: !localFlag,
          },
        },
      })

      if (!result.data?.updateDimStoreArticle.success) {
        throw new Error(result.data?.updateDimStoreArticle.error?.message)
      }
    } catch (e) {
      console.error(e)
      toast.error("Erreur lors de la mise à jour de la référence.")
      captureException(e)
      dispatch({
        type: "updateReference",
        payload: {
          mercurialeId: reference.mercuriale_id,
          local_flag: localFlag,
        },
      })
    }
  }

  return (
    <Button
      variant={localFlag ? "default" : "outline"}
      onClick={handleClick}
      disabled={loading}
      className={twJoin(
        "p-0 size-9 text-xs",
        localFlag && "bg-green-800 hover:bg-green-900",
      )}
    >
      <Paysan className="size-2" />
    </Button>
  )
}

export default LocalFlagToggle
