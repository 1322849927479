import { Button } from "../ui/button"
import { Input } from "../ui/input"

interface Props {
  disabled?: boolean
  value?: number | string
  onReducedQuantity: () => void
  onIncreasedQuantity: () => void
  onQuantityChange: (value?: number) => void
  unit?: string
  allowZero?: boolean
}

export const QuantityInput = ({
  disabled = false,
  value,
  onReducedQuantity,
  onIncreasedQuantity,
  onQuantityChange,
  allowZero = false,
  unit,
}: Props) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-row items-center">
        <Input
          type="number"
          value={value === 0 && !allowZero ? "" : (value ?? "")}
          min={0}
          className={`w-14 min-h-10 text-center p-0 m-0 rounded-r-none rounded-l-md border-r-0`}
          onChange={(e) => {
            const value = parseFloat(e.target.value)
            if (isNaN(value)) {
              return onQuantityChange()
            }
            onQuantityChange(Math.max(0, value))
          }}
          disabled={disabled}
        />
        {unit && (
          <div
            className={`flex flex-row items-center bg-white border border-x-0 pr-2 rounded-l-none h-10 ${disabled ? "opacity-50" : ""}`}
          >
            <p className="text-sm">{unit}</p>
          </div>
        )}
      </div>
      <Button
        className="min-w-10 min-h-10 rounded-none border-r-0"
        variant="outline"
        size="icon"
        onClick={onReducedQuantity}
        disabled={disabled || (typeof value === "number" ? value <= 0 : true)}
      >
        -
      </Button>
      <Button
        className="min-w-10 min-h-10 rounded-l-none rounded-r-md"
        variant="outline"
        size="sm"
        onClick={onIncreasedQuantity}
        disabled={disabled}
      >
        +
      </Button>
    </div>
  )
}
