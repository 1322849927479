import { Button } from "@/components/ui/button"
import { DateRangePicker } from "@/components/ui/date-range-picker"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import MultipleSelector, { Option } from "@/components/ui/multi-select"
import { Textarea } from "@/components/ui/textarea"
import {
  GetMessagesForAdminQueryResult,
  MessageType,
  useCreateMessageMutation,
  useGetStoresQuery,
  useUpdateMessageMutation,
} from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { captureException } from "@sentry/react"
import { useEffect, useMemo } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "sonner"

interface CreateMessageForm {
  title: string
  content: string
  date: {
    from: Date
    to?: Date
  }
  storesIds?: string[]
}

interface MessageFormModalProps {
  companyId: string
  franchise: string | undefined
  storeId: string
  refetchMessages: () => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setUpdateMessageId: (id?: string) => void
  message:
    | Exclude<
        GetMessagesForAdminQueryResult["data"],
        undefined
      >["messagesForAdmin"][number]
    | undefined
}

export function MessageFormModal({
  companyId,
  franchise,
  storeId,
  refetchMessages,
  isOpen,
  setIsOpen,
  setUpdateMessageId,
  message,
}: MessageFormModalProps) {
  const storesResult = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId,
        franchise: franchise,
      },
    },
  })

  const [createMessage, { loading: isCreateMessageLoading }] =
    useCreateMessageMutation()
  const [updateMessage, { loading: isUpdateMessageLoading }] =
    useUpdateMessageMutation()

  const { handleSubmit, register, control, reset, setValue } =
    useForm<CreateMessageForm>({
      defaultValues: {
        date: {
          from: new Date(),
          to: undefined,
        },
      },
    })

  useEffect(() => {
    setValue("title", message?.title ?? "")
    setValue("content", message?.content ?? "")
    setValue("date", {
      from:
        typeof message?.start_date === "string"
          ? new Date(message.start_date)
          : new Date(),
      to:
        typeof message?.end_date === "string"
          ? new Date(message.end_date)
          : undefined,
    })
    setValue(
      "storesIds",
      message?.stores?.map((store) => store.id) ?? undefined,
    )
  }, [
    message,
    message?.content,
    message?.end_date,
    message?.start_date,
    message?.stores,
    message?.title,
    setValue,
  ])

  const storesOptions = useMemo<(Option & { id: string })[]>(() => {
    return (
      storesResult.data?.getStores?.stores?.map((store) => ({
        label: store.store_name,
        value: store.store_name,
        id: store.id,
      })) ?? []
    )
  }, [storesResult.data?.getStores?.stores])

  const onSubmit: SubmitHandler<CreateMessageForm> = async (data) => {
    try {
      if (message !== undefined) {
        await updateMessage({
          variables: {
            input: {
              store_id: storeId!,
              id: message.id,
              type: MessageType.Default,
              title: data.title,
              content: data.content,
              start_date: formatDateToYYYYMMDD(data.date.from),
              end_date: formatDateToYYYYMMDD(data.date.to ?? data.date.from),
              stores_ids: data.storesIds,
            },
          },
        })
      } else {
        await createMessage({
          variables: {
            input: {
              store_id: storeId!,
              company_id: companyId,
              company_franchise: franchise,
              type: MessageType.Default,
              title: data.title,
              content: data.content,
              start_date: formatDateToYYYYMMDD(data.date.from),
              end_date: formatDateToYYYYMMDD(data.date.to ?? data.date.from),
              stores_ids: data.storesIds,
            },
          },
        })
      }
      refetchMessages()
      setIsOpen(false)
      setUpdateMessageId(undefined)
      reset()
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error(
        `Le message n'a pas pu être ${message !== undefined ? "modifié" : "créé"}.`,
      )
    }
  }

  function handleOnOpenChange(value: boolean) {
    if (value === false) {
      setUpdateMessageId(undefined)
    }

    setIsOpen(value)
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            {message !== undefined ? "Modifier le message" : "Créer un message"}
          </DialogTitle>
          <DialogDescription hidden={message !== undefined}>
            Les messages créés seront affichés dans l&apos;interface des
            magasins.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <Input {...register("title")} placeholder="Titre" />
          <Textarea
            {...register("content")}
            placeholder="Le contenu de votre message..."
          />
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, value } }) => (
              <DateRangePicker value={value} onChange={onChange} />
            )}
          />
          <Controller
            control={control}
            name="storesIds"
            render={({ field: { onChange, value } }) => (
              <MultipleSelector
                defaultOptions={storesOptions}
                placeholder="Magasins"
                value={storesOptions.filter((option) =>
                  value?.includes(option.id),
                )}
                emptyIndicator={
                  <p className="text-center text-gray-600 dark:text-gray-400">
                    Ce magasin n&apos;existe pas
                  </p>
                }
                onChange={(values) => onChange(values.map((value) => value.id))}
                className="bg-white"
              />
            )}
          />
          <DialogFooter>
            <Button
              type="submit"
              disabled={isCreateMessageLoading || isUpdateMessageLoading}
            >
              {message !== undefined ? "Modifier" : "Créer"} le message
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
