import { Button } from "@/components/ui/button"
import { GroupedPromotion } from "../EngagementPromoPage"
import { useAppContext } from "@/contexts/AppContext"
import { Link } from "react-scroll"
interface EngagementPromoDateSelectorProps {
  groupedPromotions: GroupedPromotion[]
}

export function EngagementPromoDateSelector({
  groupedPromotions,
}: EngagementPromoDateSelectorProps) {
  const { headerHeight } = useAppContext()
  return (
    <div
      className={`flex flex-col gap-1 sticky bg-white shadow-md p-4 z-10 rounded-lg`}
      style={{ top: headerHeight + 5 }}
    >
      <p className="text-sm font-medium text-muted-foreground flex-shrink-0">
        Date engagement :
      </p>
      <div className="bg-white shadow border rounded-lg flex gap-1 overflow-x-auto">
        {groupedPromotions?.map((groupedPromotion, i) => {
          return (
            <Button key={i} variant="ghost">
              <Link
                to={`section-${i}`}
                smooth={true}
                offset={-headerHeight - 120}
                duration={500}
                className="flex-1"
              >
                {Intl.DateTimeFormat("fr-FR").format(
                  new Date(groupedPromotion.start_date),
                )}{" "}
                au{" "}
                {Intl.DateTimeFormat("fr-FR").format(
                  new Date(groupedPromotion.end_date),
                )}
              </Link>
            </Button>
          )
        })}
      </div>
    </div>
  )
}
