import { useMemo, type ButtonHTMLAttributes, type ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { Spinner } from "./Spinner"

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: ReactNode
  color?:
    | "green"
    | "blue"
    | "red"
    | "ghost"
    | "yellow"
    | "lightGreen"
    | "darkGreen"
    | "black"
    | "blackGhost"
  size?: "sm" | "md" | "lg" | "custom" | "adaptive-custom"
  loading?: boolean
}

// @deprecated
export function Button({
  children,
  leftIcon,
  color = "green",
  size = "md",
  loading = false,
  disabled = false,
  className,
  ...props
}: ButtonProps): JSX.Element {
  const colorClassName = useMemo(() => {
    switch (color) {
      case "green":
        return "bg-green-500 hover:bg-green-600 text-white"
      case "blue":
        return "bg-blue-500 hover:bg-blue-700 text-white"
      case "red":
        return "bg-red-500 hover:bg-red-700 text-white"
      case "ghost":
        return "border text-black"
      case "yellow":
        return "bg-yellow-500 hover:bg-yellow-600 text-white"
      case "lightGreen":
        return "bg-green-20 hover:bg-green-30 text-green-900"
      case "darkGreen":
        return "bg-green-900 text-white"
      case "black":
        return "bg-neutral-700 text-white"
      case "blackGhost":
        return "border-2 border-black text-black"
    }
  }, [color])

  const sizeClassName = useMemo(() => {
    switch (size) {
      case "sm":
        return "text-xs px-3 py-2 h-8"
      case "md":
        return "text-base px-4 h-[35px]"
      case "lg":
        return "text-lg xl:text-xl px-5 py-3.5"
      case "custom":
        return ""
      case "adaptive-custom":
        return "text-base px-4 h-[35px]  md:text-xs"
    }
  }, [size])

  const mergedClassName = twMerge(
    `${colorClassName} ${sizeClassName} flex transition ease-in duration-200 justify-center items-center gap-2 rounded-md shadow-sm touch-manipulation disabled:bg-gray-300 disabled:text-white`,
    className,
  )

  return (
    <button
      disabled={disabled || loading}
      {...props}
      className={mergedClassName}
    >
      {leftIcon}
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </button>
  )
}
