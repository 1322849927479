import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { DispatchActionType, StateType } from "@/types"
import { useGetMessagesForStoreQuery } from "@/utils/__generated__/graphql"
import { X } from "lucide-react"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Messages } from "@/components/messages/Messages"

export function MessagesModal() {
  const dispatch = useDispatch<DispatchActionType>()
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const closedMessages = useSelector(
    (state: StateType) => state.modalsReducer.closedMessages,
  )

  const { data, loading } = useGetMessagesForStoreQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
    fetchPolicy: "network-only",
  })

  function closeMessages() {
    dispatch({
      type: "closeMessages",
      payload: data?.messagesForStore.map((message) => message.id) ?? [],
    })
  }

  const isModalOpen = useMemo(() => {
    const hasMessages = (data?.messagesForStore ?? []).length > 0
    const hasNewMessages = data?.messagesForStore?.some((message) => {
      return !closedMessages.includes(message.id)
    })

    return !loading && hasMessages && hasNewMessages
  }, [closedMessages, data?.messagesForStore, loading])

  return (
    <AlertDialog open={isModalOpen}>
      <AlertDialogContent className="bg-gray-100 flex flex-col gap-4 p-4 overflow-y-auto h-fit max-h-full">
        <button className="self-end" onClick={closeMessages}>
          <X className="size-5" />
        </button>
        <AlertDialogTitle>Messages</AlertDialogTitle>
        <AlertDialogDescription>
          Des messages importants pour votre magasin.
        </AlertDialogDescription>
        <div className="flex flex-col gap-2">
          <Messages messages={data?.messagesForStore ?? []} />
        </div>
        <AlertDialogFooter>
          <AlertDialogAction className="w-full" onClick={closeMessages}>
            Continuer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
