import { type IconType } from "./types"
import { twMerge } from "tailwind-merge"

export function Paysan({ className }: IconType): JSX.Element {
  const mergedClassName = twMerge("size-24", className)

  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mergedClassName}
    >
      <g id="paysan (1) 1" clipPath="url(#clip0_1810_6696)">
        <g id="Group">
          <path
            id="Vector"
            opacity="0.984"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.58192 -0.0234375C7.86969 -0.0234375 9.15742 -0.0234375 10.4452 -0.0234375C11.2013 0.30672 11.5008 0.890705 11.3436 1.72852C11.6584 1.72105 11.9729 1.72853 12.287 1.75098C12.739 1.97378 12.8513 2.3107 12.6239 2.76172C12.5368 2.87878 12.4245 2.96114 12.287 3.00879C11.9579 3.03124 11.6285 3.03872 11.2987 3.03125C11.0444 4.59561 10.1235 5.43417 8.53602 5.54688C6.94816 5.43376 6.02727 4.5952 5.77332 3.03125C5.44355 3.03872 5.11414 3.03124 4.78504 3.00879C4.33307 2.78598 4.22076 2.44907 4.44813 1.99805C4.51551 1.93066 4.58289 1.86328 4.65028 1.7959C5.00583 1.73296 5.36521 1.7105 5.7284 1.72852C5.57823 0.916252 5.86276 0.332267 6.58192 -0.0234375Z"
            fill="currentColor"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_2"
            opacity="0.962"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.892 2.98718C16.1798 2.9797 17.4676 2.98718 18.7552 3.00964C18.9831 3.07278 19.1404 3.21504 19.2269 3.4364C19.316 4.16976 19.316 4.9035 19.2269 5.63757C19.1313 5.77818 19.019 5.90544 18.89 6.01941C18.4205 6.35412 17.9638 6.70599 17.5199 7.07507C17.5124 7.79427 17.4899 8.51302 17.4525 9.23132C17.0182 8.90191 16.584 8.57245 16.1498 8.24304C16.1124 7.85456 16.0899 7.46526 16.0824 7.07507C15.6086 6.67612 15.122 6.29428 14.6224 5.92956C14.5273 5.8518 14.4599 5.75446 14.4203 5.63757C14.3475 4.93684 14.3325 4.23305 14.3754 3.52624C14.451 3.24857 14.6232 3.06889 14.892 2.98718ZM15.7455 4.37976C16.4642 4.37976 17.183 4.37976 17.9017 4.37976C17.9092 4.58995 17.9017 4.79955 17.8793 5.00867C17.5271 5.28938 17.1677 5.55891 16.8011 5.81726C16.4444 5.56552 16.0999 5.29599 15.7679 5.00867C15.7455 4.79955 15.738 4.58995 15.7455 4.37976Z"
            fill="currentColor"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            opacity="0.978"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.668 22.9766C10.1738 22.9766 9.67969 22.9766 9.18555 22.9766C9.19305 20.2363 9.18555 17.4961 9.16309 14.7559C8.96408 14.271 8.62717 14.1437 8.15235 14.374C8.01906 14.4684 7.93672 14.5957 7.90527 14.7559C7.88281 17.4961 7.87531 20.2363 7.88281 22.9766C7.40363 22.9766 6.9245 22.9766 6.44531 22.9766C6.23674 22.8652 6.07951 22.7005 5.97363 22.4824C5.97318 18.3028 5.94327 14.125 5.88379 9.94923C5.67144 9.52405 5.34949 9.41174 4.91797 9.61232C4.8009 9.69938 4.71856 9.81168 4.6709 9.94923C4.66942 11.5382 4.63945 13.1255 4.58106 14.711C4.37999 15.1747 4.0281 15.3619 3.52539 15.2725C3.10989 15.1534 2.89276 14.8764 2.87402 14.4414C2.84408 12.6445 2.84408 10.8477 2.87402 9.05079C2.92843 8.17005 3.35519 7.54861 4.1543 7.18653C4.37071 7.10258 4.59534 7.05765 4.82813 7.05177C7.05926 7.02181 9.29035 7.02181 11.5215 7.05177C12.1432 7.09427 12.7122 7.28896 13.2285 7.63575C14.4979 8.6111 15.7632 9.59192 17.0244 10.5781C17.3057 11.0385 17.2908 11.4878 16.9795 11.9258C16.449 12.2959 15.9324 12.2734 15.4297 11.8584C14.3366 11.0648 13.2435 10.2712 12.1504 9.47755C11.7949 9.33389 11.5029 9.41628 11.2744 9.72462C11.2148 13.8998 11.1849 18.0776 11.1846 22.2578C11.141 22.5929 10.9688 22.8325 10.668 22.9766Z"
            fill="currentColor"
          />
        </g>
        <g id="Group_4">
          <path
            id="Vector_4"
            opacity="0.963"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6533 22.9785C19.4736 22.9785 19.2939 22.9785 19.1142 22.9785C18.9355 22.897 18.8082 22.7622 18.7324 22.5742C18.7174 21.7656 18.7024 20.957 18.6875 20.1484C18.6362 19.3266 18.2469 18.7277 17.5195 18.3516C17.5183 19.5223 17.4883 20.6903 17.4296 21.8555C17.1804 22.253 16.8435 22.3503 16.4189 22.1475C16.2885 22.0468 16.1912 21.9196 16.1269 21.7656C16.1045 20.6277 16.097 19.4897 16.1045 18.3516C15.5583 18.5307 15.1765 18.8901 14.9589 19.4297C14.8864 19.6025 14.8414 19.7822 14.8242 19.9688C14.8092 20.8223 14.7942 21.6758 14.7793 22.5293C14.6873 22.7335 14.545 22.8832 14.3525 22.9785C14.1878 22.9785 14.0231 22.9785 13.8584 22.9785C13.6275 22.8743 13.4852 22.6947 13.4316 22.4395C13.3922 21.465 13.4071 20.4917 13.4765 19.5195C13.8143 18.1261 14.6753 17.2426 16.0595 16.8691C16.1045 15.7465 16.1194 14.6235 16.1045 13.5C16.5941 13.503 17.0509 13.3832 17.4746 13.1406C17.497 14.3983 17.5045 15.6562 17.497 16.9141C18.8832 17.2747 19.7293 18.1581 20.0351 19.5645C20.1026 20.4769 20.1176 21.3903 20.08 22.3047C20.0532 22.6074 19.9109 22.832 19.6533 22.9785Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1810_6696">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
