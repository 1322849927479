import { TableCell, TableFooter, TableRow } from "@/components/ui/table"
import { useMemo } from "react"
import { TableRowData } from "./PerformancesByStore"
import { Skeleton } from "@/components/ui/skeleton"

interface PerformancesTableFooterProps {
  performances: TableRowData[]
  storeCurrency: string | null
  isBreakageLoading: boolean
}

export function PerformancesTableFooter({
  performances,
  storeCurrency,
  isBreakageLoading,
}: PerformancesTableFooterProps) {
  const totalAmounts = useMemo(() => {
    return performances?.reduce(
      (totalAmounts, performance) => ({
        orderedAmount:
          totalAmounts.orderedAmount + (performance?.ordered_amount ?? 0),
        predictedAmount:
          totalAmounts.predictedAmount + (performance?.predicted_amount ?? 0),
        finalPredictedAmount:
          totalAmounts.finalPredictedAmount +
          (performance?.final_predicted_amount ?? 0),
        salesAmount:
          totalAmounts.salesAmount + (performance?.sales_amount ?? 0),
        prePredictedAmount:
          totalAmounts.prePredictedAmount +
          (performance?.pre_predicted_amount ?? 0),
        breakagesAmount:
          totalAmounts.breakagesAmount + (performance?.breakages_amount ?? 0),
      }),
      {
        orderedAmount: 0,
        predictedAmount: 0,
        finalPredictedAmount: 0,
        salesAmount: 0,
        prePredictedAmount: 0,
        breakagesAmount: 0,
      },
    )
  }, [performances])

  return (
    <TableFooter className="sticky bottom-0 bg-gray-100">
      <TableRow>
        <TableCell colSpan={2}>Total</TableCell>
        <TableCell>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
          }).format(totalAmounts?.orderedAmount ?? 0)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
          }).format(totalAmounts?.prePredictedAmount ?? 0)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
          }).format(totalAmounts?.finalPredictedAmount ?? 0)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
          }).format(totalAmounts?.predictedAmount ?? 0)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: storeCurrency ?? "EUR",
          }).format(totalAmounts?.salesAmount ?? 0)}
        </TableCell>
        <TableCell colSpan={2}>
          {isBreakageLoading ? (
            <Skeleton className="bg-gray-300 w-14 h-4 rounded-full" />
          ) : (
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: storeCurrency ?? "EUR",
            }).format(totalAmounts?.breakagesAmount ?? 0)
          )}
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}
