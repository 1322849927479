import { useMemo, useState } from "react"
import { useGetStoresQuery } from "../../utils/__generated__/graphql"
import { useParams, Link, useSearchParams } from "react-router-dom"
import { usePermissions } from "../../hooks/usePermissions"
import { Switch } from "@/components/ui/switch"
import { Button } from "@/components/ui/button"
import { ArrowRight } from "lucide-react"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

export function StoresPage(): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise")
  const { isIdaAdmin } = usePermissions()

  const [searchValue, setSearchValue] = useState("")
  const [includeInactiveStores, setIncludeInactiveStores] = useState(false)

  const { data, loading } = useGetStoresQuery({
    variables: {
      input: {
        company_id: params.companyId!,
        franchise: franchiseParam,
        include_inactive: includeInactiveStores,
      },
    },
  })

  const stores = useMemo(() => {
    return (
      data?.getStores?.stores?.filter(
        (store) =>
          store.company_id === params.companyId &&
          store.franchise === franchiseParam &&
          store.store_name.toLowerCase().includes(searchValue.toLowerCase()),
      ) ?? []
    )
  }, [data?.getStores?.stores, franchiseParam, params.companyId, searchValue])

  return (
    <div className="flex flex-col gap-2 pb-12 px-2 flex-1">
      <div className="flex flex-col md:flex-row items-center justify-between flex-wrap gap-2 md:gap-4">
        <input
          type="search"
          className="w-full flex-1 focus:ring-green-500 bg-white border-none shadow rounded-md"
          placeholder="Rechercher un nom du magasin"
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
          value={searchValue}
        />
        <div className="flex items-center justify-between md:justify-center gap-2 w-full md:w-fit">
          {!loading ? (
            <p>
              <span className="font-bold">{stores.length}</span> magasin
              {stores.length > 1 && "s"}
            </p>
          ) : (
            <span className="w-24 h-5 bg-gray-200 animate-pulse rounded-full" />
          )}
          {isIdaAdmin && (
            <div className="flex items-center gap-2">
              <p>
                <span className="hidden md:inline">/ </span>Tous les magasins
              </p>
              <Switch
                checked={includeInactiveStores}
                onCheckedChange={setIncludeInactiveStores}
              />
            </div>
          )}
        </div>
      </div>
      {!loading
        ? stores.map((store) => (
            <Card key={store.id}>
              <CardHeader>
                <CardTitle>{store.store_name}</CardTitle>
                {isIdaAdmin && <CardDescription>{store.id}</CardDescription>}
              </CardHeader>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <CardContent>
                  <p>
                    {store.address} {store.city} {store.country}
                  </p>
                  <p className="text-sm">
                    <span className="font-black">Code : </span>
                    {store.store_code}
                  </p>
                </CardContent>
                <CardFooter>
                  <Button variant="secondary" asChild>
                    <Link to={`/stores/${store.id}`}>
                      <ArrowRight />
                      Visiter le magasin
                    </Link>
                  </Button>
                </CardFooter>
              </div>
            </Card>
          ))
        : new Array(3)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className="w-full h-32 bg-gray-200 animate-pulse rounded-md"
              />
            ))}
    </div>
  )
}
