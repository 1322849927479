import { GetCalendarQuery } from "../../utils/__generated__/graphql"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import { ArrowBackIcon } from "../../ui/icons/ArrowBackIcon"
import { ArrowNextIcon } from "../../ui/icons/ArrowNextIcon"
import { Button } from "../../ui/Button"
import { Link } from "react-router-dom"
import { Label } from "../../ui/Label"
import { DateTime } from "luxon"

interface CalendarProps {
  companyName: string | null
  data: GetCalendarQuery | undefined
  storeCurrency: string | null
  currentDate: DateTime
  allDays: DateTime[]
  setCurrentDate: (date: DateTime) => void
}

interface HeaderProps {
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
  companyName: string | null
}

interface WeekdaysProps {
  currentDate: DateTime
  today: DateTime
}

interface DaysProps {
  allDays: DateTime[]
  currentDate: DateTime
  data: GetCalendarQuery | undefined
  storeCurrency: string | null
  today: DateTime
}

interface HolidayOrSchoolHolidayProps {
  holidayReason: string | null | undefined
  isSchoolHolidays: boolean
  schoolHolidaysReason: string | null | undefined
  isFirstSchoolHolidays: boolean
}

const Weekdays = ({ currentDate, today }: WeekdaysProps) => {
  const weekdays = [
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
    "dimanche",
  ]
  const currentWeekDay = currentDate.weekday
  return (
    <>
      {weekdays.map((day, index) => {
        const isCurrentWeekday =
          currentWeekDay === index + 1 && currentDate.hasSame(today, "day")
        return (
          <div key={day} className="bg-gray-40 flex justify-center py-1">
            <p
              className={`text-xs font-bold uppercase ${isCurrentWeekday ? "text-green-400" : ""}`}
            >
              {day}
            </p>
          </div>
        )
      })}
    </>
  )
}

const HolidayOrSchoolHoliday = ({
  holidayReason,
  isSchoolHolidays,
  schoolHolidaysReason,
  isFirstSchoolHolidays,
}: HolidayOrSchoolHolidayProps) => {
  const isHoliday = typeof holidayReason === "string"
  if (!isHoliday && !isSchoolHolidays) return null
  let backgroundColor = "bg-cyan-10"
  let displayText = ""
  let textColor = "text-white"

  if (isHoliday) {
    backgroundColor = "bg-red-500"
    displayText = holidayReason
  } else if (isSchoolHolidays && isFirstSchoolHolidays) {
    displayText = schoolHolidaysReason ?? "Vacances scolaires"
    textColor = "text-cyan-900"
  }

  return (
    <div
      className={`absolute top-0 left-0 w-full h-0.5 xl:h-1 flex items-center justify-end py-[7px] px-1 ${backgroundColor}`}
    >
      <p className={`text-xxs xl:text-xs font-medium ${textColor} truncate`}>
        {displayText}
      </p>
    </div>
  )
}

const Days = ({
  allDays,
  data,
  storeCurrency,
  currentDate,
  today,
}: DaysProps) => {
  const firstSchoolHoliday = data?.getCalendar.calendar?.find(
    (info) => info?.isSchoolHolidays === true,
  )
  const firstSchoolHolidayDate = firstSchoolHoliday
    ? DateTime.fromISO(firstSchoolHoliday.date)
    : null

  return (
    <>
      {allDays.map((day, index) => {
        const info = data?.getCalendar.calendar?.find((info) => {
          if (typeof info?.date !== "string") return false
          const date = DateTime.fromISO(info.date)
          date.startOf("day")
          return day.hasSame(date, "day")
        })
        const isToday = day.hasSame(today, "day")
        const isFirstSchoolHolidays = firstSchoolHolidayDate
          ? day.hasSame(firstSchoolHolidayDate, "day")
          : false

        return (
          <div
            key={index}
            className={`min-h-32 relative p-1 ${day.month !== currentDate.month || info?.isOpen === false ? "bg-gray-50" : "bg-white"}`}
          >
            {day && (
              <HolidayOrSchoolHoliday
                holidayReason={info?.holidayReason}
                isSchoolHolidays={info?.isSchoolHolidays === true}
                schoolHolidaysReason={info?.schoolHolidaysReason}
                isFirstSchoolHolidays={isFirstSchoolHolidays}
              />
            )}
            <p
              className={`${!firstSchoolHoliday ? "mt-1" : "mt-2 xl:mt-3"} w-6 h-6 rounded-full flex justify-center items-center text-sm font-bold ${isToday ? "bg-green-400 text-white" : "text-gray-400"}`}
            >
              {day.day}
            </p>
            <div className="mt-2 flex flex-col gap-1 w-full">
              {info?.orderItems?.map((orderItem) => (
                <Link
                  key={orderItem.dimOrderRequestId}
                  to={`/inventory/recap?orderId=${orderItem.dimOrderRequestId}`}
                  className="w-full"
                >
                  <Label
                    className="w-full justify-between"
                    color="orange"
                    variant="filled"
                    rightIcon={<ArrowRightIcon className="w-4 h-4" />}
                  >
                    <span className="flex flex-col">
                      <span className="text-gray-600 text-xxs xl:text-xs">
                        {new Intl.NumberFormat("fr-FR", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: storeCurrency ?? "EUR",
                        }).format(orderItem.potentialCA)}
                      </span>
                      <span className="text-[6px] xl:text-xxs">
                        CA Potentiel Commandé
                      </span>
                    </span>
                  </Label>
                </Link>
              ))}
              <div className="flex flex-row gap-1">
                {typeof info?.dailyCA === "number" && (
                  <Label color="yellow" variant="filled" className="w-full">
                    <span className="flex flex-col">
                      <span className="text-gray-600 text-xxs xl:text-xs">
                        {new Intl.NumberFormat("fr-FR", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: storeCurrency ?? "EUR",
                        }).format(info.dailyCA)}
                      </span>
                      <span className="text-[6px] xl:text-xxs">CA Réalisé</span>
                    </span>
                  </Label>
                )}
                {typeof info?.dailyParcelCount === "number" && (
                  <Label color="yellow" variant="filled" className="w-full">
                    <span className="flex flex-col">
                      <span className="text-gray-600 text-xxs xl:text-xs">
                        {info.dailyParcelCount}
                      </span>
                      <span className="text-[6px] xl:text-xxs">Cs Vendues</span>
                    </span>
                  </Label>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const Header = ({ currentDate, setCurrentDate }: HeaderProps) => {
  const today = DateTime.local()
  // Extracting company_name or other relevant data from storeData

  const handleChangeMonth = (delta: number) => {
    const newDate = currentDate.plus({ months: delta }).startOf("month")
    if (newDate.hasSame(today, "month")) {
      setCurrentDate(today)
    } else {
      setCurrentDate(newDate)
    }
  }

  return (
    <div className="flex items-center w-full gap-4">
      <div className="flex items-center gap-1">
        <div
          onClick={() => {
            handleChangeMonth(-1)
          }}
        >
          <ArrowBackIcon />
        </div>
        <div className="min-w-48 text-center text-2xl capitalize text-gray-600 font-bold">
          {currentDate.toFormat("LLLL yyyy", { locale: "fr-FR" })}
        </div>
        <div
          onClick={() => {
            handleChangeMonth(1)
          }}
        >
          <ArrowNextIcon />
        </div>
      </div>
      <Button
        color="ghost"
        className=""
        onClick={() => {
          setCurrentDate(today)
        }}
      >
        Aujourd&apos;hui
      </Button>
    </div>
  )
}

const Calendar = ({
  companyName,
  data,
  storeCurrency,
  currentDate,
  allDays,
  setCurrentDate,
}: CalendarProps) => {
  const today = DateTime.now()
  return (
    <>
      <Header
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        companyName={companyName}
      />
      <div className="mt-2 flex flex-col gap-4">
        <div className="w-full border shadow rounded-lg overflow-hidden">
          <div className="bg-gray-100 grid grid-cols-7 gap-[1px]">
            <Weekdays currentDate={currentDate} today={today} />
            <Days
              allDays={allDays}
              currentDate={currentDate}
              data={data}
              storeCurrency={storeCurrency}
              today={today}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Calendar
