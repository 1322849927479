// src/components/WorstReferencesList.tsx

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
interface WorstReferencesListProps {
  data: {
    store_name: string
    sale_name: string
    taux_de_modif: number
    predicted_amount: number
    ordered_amount: number
    sales_amount: number
    distance: number
  }[]
}

export function WorstReferencesList({ data }: WorstReferencesListProps) {
  return (
    <>
      <Card className="w-1/3">
        <CardHeader>
          <CardTitle>
            Liste des references les moins bien prédites (au niveau du magasin)
          </CardTitle>
        </CardHeader>

        <CardContent>
          <div className="space-y-4 w-full">
            {data.map((item, index) => (
              <div key={index} className="flex items-center">
                <div className="ml-4 space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {item.sale_name}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    {item.store_name}
                  </p>
                </div>
                <div className="ml-auto text-right space-y-1">
                  <p className="text-sm font-medium">
                    distance (preds avt recalage - ventes):{" "}
                    {item.distance.toFixed(2)}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    ventes: €{item.sales_amount.toFixed(2)}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    montant des commandes: €{item.ordered_amount.toFixed(2)}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    montant des prédictions à 0 stock: €
                    {item.predicted_amount.toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  )
}
