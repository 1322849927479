import { Progress } from "@/components/ui/progress"
import { ChevronRight, LucideProps } from "lucide-react"
import { twMerge } from "tailwind-merge"

interface EngagementPromoBadgeProps {
  Icon: React.ComponentType<LucideProps>
  title: string
  startDate: string
  endDate: string
  progress?: number
  className?: string
}

export function EngagementPromoBadge({
  Icon,
  title,
  startDate,
  endDate,
  progress,
  className,
}: EngagementPromoBadgeProps) {
  return (
    <div
      className={twMerge(
        "px-2 py-1 rounded flex flex-col justify-center",
        className,
      )}
    >
      <div className="flex items-center gap-1 text-sm font-bold">
        <Icon className="size-4" />
        <p>{title}</p>
        <ChevronRight className="size-4" />
        <p className="text-gray-500 font-medium">
          {Intl.DateTimeFormat("fr-FR").format(new Date(startDate))}
        </p>
        <ChevronRight className="size-4 text-gray-500" />
        <p className="text-gray-500 font-medium">
          {Intl.DateTimeFormat("fr-FR").format(new Date(endDate))}
        </p>
      </div>
      {typeof progress === "number" && (
        <Progress className="h-1 bg-gray-40" value={progress} />
      )}
    </div>
  )
}
