import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

interface TopUnorderedReferencesChartCardProps {
  storeName: string
  references: {
    saleName: string
    TotalSales: number
    OrderDays: number
    NotOrderedDays: number
    TotalOrderedQuantity: number
  }[]
  viewMode: string
}

const TopUnorderedReferencesChartCard = ({
  storeName,
  references,
  viewMode,
}: TopUnorderedReferencesChartCardProps) => (
  <Card className="w-full max-w-6xl mx-auto my-8">
    <CardHeader>
      <CardTitle>Top Références Non Commandées pour {storeName}</CardTitle>
    </CardHeader>
    <CardContent>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={references}
          margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
          aria-label={`Top Références Non Commandées pour ${storeName}`}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="saleName"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={150}
            tick={{ fontSize: 12 }}
          />
          {/* Primary Y-Axis */}
          <YAxis
            yAxisId="left"
            label={{
              value: "Nombre de Jours",
              angle: -90,
              position: "insideLeft",
              offset: -10,
              dy: 40,
              fill: "black",
            }}
            allowDecimals={false}
          />
          {/* Secondary Y-Axis for Total Ordered Quantity and Total Sales */}
          {(viewMode === "amount" || viewMode === "both") && (
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{
                value: "Quantité Commandée",
                angle: 90,
                position: "insideRight",
                offset: 10,
                fill: "black",
              }}
              allowDecimals={false}
            />
          )}
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {/* Conditional Bars Based on View Mode */}
          {(viewMode === "recurrence" || viewMode === "both") && (
            <>
              <Bar
                yAxisId="left"
                dataKey="OrderDays"
                stackId="a"
                fill="#82ca9d"
                name="Jours Commandés"
              />
              <Bar
                yAxisId="left"
                dataKey="NotOrderedDays"
                stackId="a"
                fill="#ff4d4f"
                name="Jours Non Commandés"
              />
            </>
          )}
          {(viewMode === "amount" || viewMode === "both") && (
            <>
              <Bar
                yAxisId="right"
                dataKey="TotalOrderedQuantity"
                fill="#8884d8"
                name="Quantité Commandée (en unités)"
              />
              <Bar
                yAxisId="right"
                dataKey="TotalSales"
                fill="#ffc658"
                name="Ventes Totales (€)"
              />
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
)

export default TopUnorderedReferencesChartCard
