import { Skeleton } from "@/components/ui/skeleton"
import { Img } from "react-image"
import { InventoryInput } from "../../InventoriesInputs/InventoryInput"
import { InventoryType } from "@/utils/__generated__/graphql"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ReceiptPercentIcon } from "@heroicons/react/24/solid"
import { FlaggedRefGroup } from "@/components/organisms/flaggedRefGroup"
import { Card } from "@/components/ui/card"
import { getDisplayedUnit } from "@/utils/getUnit"

export function UsedToBePromoCard({
  references,
  companyId,
  companyName,
  franchise,
  updateInventory,
}: {
  references: AllMercurialInfo[]
  companyId: string | null
  companyName: string | null
  franchise: string | null
  updateInventory: (value: string) => Promise<void>
}) {
  return (
    <FlaggedRefGroup
      header={
        <div className="flex gap-2 items-center">
          <ReceiptPercentIcon className="size-5 text-gray-500" />
          <div>
            <p className="font-bold">Anciennes promotions</p>
            <p className="font-medium text-sm italic">
              Capacité linéaire à renseigner
            </p>
          </div>
        </div>
      }
    >
      {references.map((reference) => (
        <Card
          key={reference.mercuriale_id!}
          className="lg:w-[calc(50%-4px)] flex flex-row items-start justify-between bg-white shadow rounded p-2"
        >
          <div className="flex-1">
            <p className="text-sm font-bold line-clamp-2">
              {reference.mercuriale_name}
            </p>
            <div className="flex items-center lg:items-start gap-2">
              {typeof reference.photo_id === "string" && (
                <Img
                  src={`https://storage.googleapis.com/references_images/${companyId}/${reference.photo_id}.jpeg`}
                  className="w-12 lg:w-9 h-16 lg:h-11 rounded object-cover"
                  loader={
                    <Skeleton className="w-12 lg:w-9 h-16 lg:h-11 rounded" />
                  }
                />
              )}
              <div className="text-left">
                <p className="text-sm font-medium text-gray-500">
                  {reference.colisage}{" "}
                  {getDisplayedUnit(reference.unit, companyName, franchise)}
                </p>
              </div>
            </div>
          </div>
          <div className="w-32 md:w-fit">
            <InventoryInput
              name={InventoryType.Shelf}
              mercurialeId={reference.mercuriale_id!}
              unit={reference.unit ?? ""}
              isNewReference={reference.new_reference ?? false}
              value={reference.shelf_floor_size ?? 0}
              floorInventoryQuantity={reference.floor_inventory_qty ?? 0}
              updateInventory={updateInventory}
            />
          </div>
        </Card>
      ))}
    </FlaggedRefGroup>
  )
}
