"use client"

import * as React from "react"
import { MercurialReducerState } from "../../reducers/mercurialReducer"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { Clock, AlertCircle, CheckCircle2, Loader2 } from "lucide-react"
import { useGetOrderRequestStatusLazyQuery } from "../../utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

interface TimeSlot {
  date: string
  available: boolean
  dimMercurialeId: string
}

interface MultipleMercurialesModalProps {
  isOpen: boolean
  onClose: () => void
  onSelect: (dimMercurialeId: string) => void
  dimMercuriales: MercurialReducerState["dimMercuriales"]
}

export function MultipleMercurialesModal({
  isOpen,
  onClose,
  onSelect,
  dimMercuriales,
}: MultipleMercurialesModalProps) {
  const [timeSlots, setTimeSlots] = React.useState<TimeSlot[]>([])
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null)
  const [statuses, setStatuses] = React.useState<
    Record<string, { status: string; colis?: number }>
  >({})
  const [fetchingStatuses, setFetchingStatuses] = React.useState<boolean>(false)
  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const [fetchOrderStatus] = useGetOrderRequestStatusLazyQuery()

  React.useEffect(() => {
    if (isOpen && dimMercuriales && dimMercuriales.length > 0) {
      const slots = dimMercuriales.map((dimMercuriale) => {
        let displayLabel: string
        if (companyName?.toLowerCase() === "auchan") {
          const formattedDate = Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "numeric",
            month: "short",
          }).format(new Date(dimMercuriale.expectedReceptionDate))
          displayLabel = `Livraison ${formattedDate}`
        } else {
          const pickupTime = dimMercuriale.orderPickupTime || "N/A"
          displayLabel =
            pickupTime !== "N/A"
              ? pickupTime.slice(0, -3).replace(":", "h")
              : "N/A"
        }
        return {
          date: displayLabel,
          available: true,
          dimMercurialeId: dimMercuriale.dimMercurialeId,
        }
      })
      setTimeSlots(slots)
    } else {
      setTimeSlots([])
      setStatuses({})
    }
  }, [isOpen, dimMercuriales, companyName])

  React.useEffect(() => {
    const fetchAllStatuses = async () => {
      if (!isOpen || timeSlots.length === 0 || !storeId) return

      setFetchingStatuses(true)

      const promises = timeSlots.map(async (slot) => {
        const { data } = await fetchOrderStatus({
          variables: {
            input: {
              store_id: storeId,
              dim_mercuriale_id: slot.dimMercurialeId,
            },
          },
          fetchPolicy: "no-cache",
        })

        const orderRequest = data?.getOrderRequestStatus?.order_request_object
        const status = orderRequest?.status ?? "ONGOING"
        const colis = orderRequest?.colis_nb ?? 0

        return { dimMercurialeId: slot.dimMercurialeId, status, colis }
      })

      const results = await Promise.all(promises)
      const statusMap: Record<string, { status: string; colis?: number }> = {}
      for (const { dimMercurialeId, status, colis } of results) {
        statusMap[dimMercurialeId] = { status, colis }
      }
      setStatuses(statusMap)
      setFetchingStatuses(false)
    }

    void fetchAllStatuses()
  }, [isOpen, timeSlots, storeId, fetchOrderStatus])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  const handleSelect = (time: string) => {
    setSelectedTime(time)
    const selectedSlot = timeSlots.find((slot) => slot.date === time)
    if (selectedSlot) {
      onSelect(selectedSlot.dimMercurialeId)
    }
    handleOpenChange(false)
  }

  const translateStatus = (status: string, colis?: number) => {
    switch (status.toLowerCase()) {
      case "ongoing":
        return {
          label: "En cours",
          icon: <Clock className="h-6 w-6 text-gray-400" />,
        }
      case "completed":
        return {
          label:
            colis && colis > 0 ? (
              <span className="text-green-500">Terminé - {colis} colis</span>
            ) : (
              <span className="text-green-500">Terminé</span>
            ),
          icon: <CheckCircle2 className="h-6 w-6 text-green-500" />,
        }
      default:
        return {
          label: "En cours",
          icon: <Clock className="h-6 w-6 text-gray-400" />,
        }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <div className="flex items-center justify-center space-x-2">
            <AlertCircle className="h-6 w-6" />
            <DialogTitle className="text-xl font-semibold">
              {dimMercuriales?.length ?? 0} commandes sont à passer
              aujourd&apos;hui
            </DialogTitle>
          </div>
          <DialogDescription className="text-center">
            Sélectionnez-en une ci-dessous
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-1 gap-4 p-4">
          {timeSlots.map((slot) => {
            const { status, colis } = statuses[slot.dimMercurialeId] || {}
            const isLoading = fetchingStatuses || !status
            const statusInfo = status ? translateStatus(status, colis) : null

            return (
              <TooltipProvider key={slot.dimMercurialeId}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "flex items-center justify-between w-full p-4 h-auto hover:border-primary hover:bg-primary/5",
                        selectedTime === slot.date &&
                          "border-primary bg-primary/5",
                      )}
                      onClick={() => handleSelect(slot.date)}
                      disabled={!slot.available || isLoading}
                    >
                      <div className="flex items-center space-x-4">
                        {/* If loading, show a loader, otherwise show the status icon */}
                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100">
                          {isLoading ? (
                            <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                          ) : (
                            statusInfo?.icon
                          )}
                        </div>
                        <div className="text-left">
                          <div className="text-lg font-semibold">
                            {slot.date}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            {isLoading ? "Chargement..." : statusInfo?.label}
                          </div>
                        </div>
                      </div>
                    </Button>
                  </TooltipTrigger>
                  {!isLoading && (
                    <TooltipContent>
                      <p>Statut: {statusInfo?.label}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            )
          })}
        </div>
      </DialogContent>
    </Dialog>
  )
}
