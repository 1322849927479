import { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { type DispatchActionType, type StateType } from "../../types"
import { Button, ButtonProps } from "../../ui/Button"
import { useUpdateMercurialeMutation } from "../../utils/__generated__/graphql"
import { twMerge } from "tailwind-merge"
import { filteredMercurialeReducerSelector } from "../../selectors/mercurialeSelectors"
import { removeDuplicatesValues } from "../../utils/removeDuplicates"
import { getDisplayedUnit } from "@/utils/getUnit"

interface OrderButtonProps {
  isLoading: boolean
  size?: ButtonProps["size"]
  className?: string
  totalOrders?: number
}

const OrderButton = ({
  isLoading,
  size = "lg",
  totalOrders,
  className,
}: OrderButtonProps): JSX.Element => {
  const [updateMercurialeMutation] = useUpdateMercurialeMutation({
    fetchPolicy: "network-only",
  })
  const dispatch = useDispatch<DispatchActionType>()
  const { updatedReferences, mercurialAndStoreInventories } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const { filteredReferences } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const { storeId, companyName, storeCode, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const dimMercurialeId = useSelector(
    (state: StateType) =>
      state.mercurialReducer.mercurialAndStoreInventories[0]
        ?.dim_mercuriale_id ?? null,
  )
  const navigate = useNavigate()
  const mergedClassName = twMerge("flex-1 w-full px-0", className)

  const needVerificationItems = useMemo(() => {
    const mercurialeIds =
      removeDuplicatesValues(
        mercurialAndStoreInventories?.filter((mercurialeAndStoreInventory) => {
          const updatedReference =
            updatedReferences?.[mercurialeAndStoreInventory.mercuriale_id ?? ""]
          return (
            updatedReference?.stock_to_verify_flag ??
            mercurialeAndStoreInventory.stock_to_verify_flag === true
          )
        }),
        "sale_id",
      )
        .map(
          (mercurialeAndStoreInventory) =>
            mercurialeAndStoreInventory.mercuriale_id,
        )
        .filter(
          (mercurialId): mercurialId is string =>
            mercurialId !== undefined && mercurialId !== null,
        ) ?? []
    return mercurialeIds
  }, [mercurialAndStoreInventories, updatedReferences])

  function filterReferences(): void {
    dispatch({
      type: "setFilteredReferences",
      payload: {
        page: "inventoryPage",
        filteredReferences: needVerificationItems,
      },
    })
  }

  function cancelFilteredReferences(): void {
    dispatch({
      type: "setFilteredReferences",
      payload: { page: "inventoryPage", filteredReferences: [] },
    })
  }

  async function handleClick() {
    navigate("/inventory/recap")
    if (
      typeof companyName !== "string" ||
      typeof dimMercurialeId !== "string" ||
      typeof storeCode !== "string" ||
      typeof storeId !== "string" ||
      !online
    ) {
      return
    }
    const result = await updateMercurialeMutation({
      variables: {
        input: {
          store_id: storeId,
          store_code: storeCode,
          dim_mercuriale_id: dimMercurialeId,
          company: companyName,
        },
      },
    })

    if (
      result.data?.updateMercuriale.updated_flag !== true ||
      result.data?.updateMercuriale.mercuriale === null ||
      result.data?.updateMercuriale.mercuriale === undefined
    )
      return
    dispatch({
      type: "updateMercuriale",
      payload: result.data.updateMercuriale.mercuriale,
    })
  }

  if (!isLoading && needVerificationItems.length > 0) {
    return (
      <div className="w-full flex items-center">
        <Button
          onClick={filterReferences}
          color="red"
          size={size}
          className={mergedClassName}
        >
          {needVerificationItems.length} référence
          {needVerificationItems.length > 1 ? "s" : ""} à vérifier
        </Button>
        {(filteredReferences ?? []).length > 0 && (
          <button
            className="ml-2 flex justify-center items-center w-8 h-8 rounded-full border-2 text-red-500 active:text-red-700 border-red-500 active:border-red-700"
            onClick={cancelFilteredReferences}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5 xl:w-6 xl:h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>
    )
  }

  if (
    !isLoading &&
    needVerificationItems.length === 0 &&
    (filteredReferences ?? []).length > 0
  ) {
    return (
      <Button
        onClick={filterReferences}
        color="blue"
        size={size}
        className={mergedClassName}
      >
        Revenir aux références
      </Button>
    )
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading}
      size={size}
      className={mergedClassName}
    >
      Valider{" "}
      {totalOrders !== undefined ? (
        <span className="font-normal text-sm">
          ({totalOrders} {getDisplayedUnit("cs", companyName, storeFranchise)})
        </span>
      ) : (
        <>la commande</>
      )}
    </Button>
  )
}

export default OrderButton
