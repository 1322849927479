import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { MessageRow } from "./MessageRow"
import { GetMessagesForAdminQueryResult } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"

interface MessagesTableProps {
  messages: Exclude<
    GetMessagesForAdminQueryResult["data"],
    undefined
  >["messagesForAdmin"]
  isLoading: boolean
  onDelete: (id: string) => void
  onUpdate: (id: string) => void
}

export function MessagesTable({
  messages,
  isLoading,
  onDelete,
  onUpdate,
}: MessagesTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Titre</TableHead>
          <TableHead>Début</TableHead>
          <TableHead>Fin</TableHead>
          <TableHead>Magasins</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {messages.length > 0 &&
          !isLoading &&
          messages.map((message) => (
            <MessageRow
              key={message.id}
              message={message}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          ))}
      </TableBody>
      <TableCaption>
        {isLoading && <Spinner />}
        {!isLoading && messages.length === 0 && "Aucun message"}
        {messages.length > 0 &&
          !isLoading &&
          `${messages.length} message${messages.length > 1 ? "s" : ""}`}
      </TableCaption>
    </Table>
  )
}
