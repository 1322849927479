import { TableCell, TableRow } from "@/components/ui/table"
import { ViewMessageModal } from "./ViewMessageModal"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { MoreVertical, Pencil, Trash2 } from "lucide-react"
import { GetMessagesForAdminQueryResult } from "@/utils/__generated__/graphql"

interface MessageRowProps {
  message: Exclude<
    GetMessagesForAdminQueryResult["data"],
    undefined
  >["messagesForAdmin"][number]
  onDelete: (id: string) => void
  onUpdate: (id: string) => void
}

export function MessageRow({ message, onDelete, onUpdate }: MessageRowProps) {
  const storesCount = message.stores?.length ?? 0

  return (
    <TableRow>
      <TableCell>{message.title}</TableCell>
      <TableCell>
        {new Intl.DateTimeFormat("fr-FR", {
          weekday: "long",
          day: "numeric",
          month: "long",
        }).format(new Date(message.start_date))}
      </TableCell>
      <TableCell>
        {message.end_date
          ? new Intl.DateTimeFormat("fr-FR", {
              weekday: "long",
              day: "numeric",
              month: "long",
            }).format(new Date(message.end_date))
          : "N/A"}
      </TableCell>
      <TableCell>
        {storesCount > 0
          ? `${storesCount} magasin${storesCount > 1 ? "s" : ""}`
          : "Tous les magasins"}
      </TableCell>
      <TableCell className="flex gap-2 justify-end">
        <ViewMessageModal message={message} />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="px-3">
              <MoreVertical className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => onUpdate(message.id)}>
              <Pencil className="size-4" />
              Modifier
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onDelete(message.id)}>
              <Trash2 className="size-4" />
              Supprimer
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
