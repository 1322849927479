// src/components/GlobalModificationRateChart.tsx

"use client"

import { parseISO, format, add } from "date-fns"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useGetGlobalInventoryModificationsEvolutionQuery } from "@/utils/__generated__/graphql"
import { CardContent, CardTitle } from "@/components/ui/card"

export default function GlobalModificationRateChart({
  companyId,
  franchiseName,
  dateRange,
  storeId,
}: {
  companyId: string
  franchiseName: string
  dateRange: {
    from: Date | undefined
    to: Date | undefined
  }
  storeId: string
}) {
  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const { data, loading, error } =
    useGetGlobalInventoryModificationsEvolutionQuery({
      variables: {
        input: {
          store_id: storeId,
          company_id: companyId,
          franchise_name: franchiseName,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
        },
      },
      skip: !storeId,
    })

  const chartData =
    data?.getGlobalInventoryModificationsEvolution?.inventory_modifications_evolution
      ?.map((item) => {
        try {
          return {
            date: format(parseISO(item.modification_date), "yyyy-MM-dd"),
            modification_count: item.modification_count,
            increase_count: item.increase_count,
            decrease_count: item.decrease_count,
          }
        } catch (error) {
          console.error("Invalid date format:", item.modification_date)
          return null // Exclude invalid date entries
        }
      })
      .filter(Boolean) ?? []

  return (
    <div className="h-full flex-1 p-4 flex flex-col gap-4 overflow-y-auto">
      <CardTitle>Nombre de modifications d&apos;inventaire (Global)</CardTitle>
      <CardContent>
        {loading ? (
          // Skeleton loader when loading
          <div className="animate-pulse">
            <div className="h-64 bg-gray-200 rounded"></div>
          </div>
        ) : error ? (
          <div className="text-red-500">
            Erreur lors du chargement des données.
          </div>
        ) : chartData.length === 0 ? (
          <div>Aucune donnée disponible.</div>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="modification_count"
                stroke="#8884d8"
                name="Nombre de modifications"
              />
              <Line
                type="monotone"
                dataKey="increase_count"
                stroke="#82ca9d"
                name="Nombre d'augmentations"
              />
              <Line
                type="monotone"
                dataKey="decrease_count"
                stroke="#82ca9d"
                name="Nombre de diminutions"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </div>
  )
}
