import { useDispatch, useSelector } from "react-redux"
import { type StateType, type DispatchActionType } from "../../types"
import { SortOption } from "../../reducers/userInterfaceReducer"
import { ArrowUpDownIcon } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { entries } from "@/utils/object"

interface FilterSelectProps {
  onSort: () => void
  page: "inventoryPage"
}

const optionsMap: Record<SortOption, string> = {
  [SortOption.BestSeller]: "Meilleures ventes",
  [SortOption.Categories]: "Ordre personnalisé",
  [SortOption.Alphabetical]: "Ordre alphabétique",
  [SortOption.Breakage]: "Pires démarques",
  [SortOption.Promotion]: "Promotion",
  [SortOption.QuantityActual]: "Quantité commandée",
  [SortOption.StockQuantity]: "Quantité en stock",
  [SortOption.SortIndex]: "Ordre de la mercuriale",
  [SortOption.Typology]: "Typologie",
}

export const InventorySort = ({
  page,
  onSort,
}: FilterSelectProps): JSX.Element => {
  const { storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { sortOption } = useSelector(
    (state: StateType) => state.userInterfaceReducer[page],
  )

  const dispatch = useDispatch<DispatchActionType>()

  const handleChange = (event: SortOption) => {
    dispatch({
      type: "setSortOption",
      payload: {
        page,
        sortOption: event,
      },
    })
    onSort()
  }

  const options = entries(optionsMap).filter(([key]) => {
    if (key === SortOption.Categories) {
      return Boolean(storeSettings?.categories_orders?.length)
    }

    return key !== SortOption.Typology || storeSettings?.typologies !== null
  })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <ArrowUpDownIcon className="h-4 w-4 text-muted-foreground" />
          <span className="hidden lg:block">{optionsMap[sortOption]}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="center">
        <DropdownMenuLabel>Trier par...</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {options.map(([option, label]) => (
          <DropdownMenuCheckboxItem
            key={option}
            checked={sortOption === option}
            onCheckedChange={() => handleChange(option)}
            className="font-medium"
          >
            {label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
