import { useSelector } from "react-redux"
import { type StateType } from "../../types"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import { getDisplayedUnit } from "@/utils/getUnit"

export type CheckboxState = Record<string, boolean>
interface PotentialRevenueProps {
  isLoading: boolean
}

const PotentialRevenue = ({
  isLoading,
}: PotentialRevenueProps): JSX.Element => {
  const { storeCurrency, companyName, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const {
    valuedStock,
    totalOrders,
    potentialCommandRevenue,
    potentialOrderRevenue,
    orderValuedStock,
  } = usePotentialRevenue()

  return (
    <div className="bg-neutral-400 shadow rounded p-3 text-white text-sm font-medium flex flex-col gap-2">
      <div className="flex px-1 gap-2 items-center">
        <p className="flex-1 font-bold">Valorisation</p>
        <p className="flex-1 text-right">Achat (PA)</p>
        <p className="flex-1 text-right">Vente (PV)</p>
      </div>
      <div className="bg-neutral-500 p-2 rounded flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <p className="flex-1">Stock</p>
          {orderValuedStock !== undefined && !isLoading ? (
            <p className="flex-1 font-bold text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(orderValuedStock)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
          {valuedStock !== undefined && !isLoading ? (
            <p className="flex-1 font-bold text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(valuedStock)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
        </div>
        <div className="flex items-center gap-2">
          <p className="flex-1">Commande</p>
          {potentialOrderRevenue !== undefined && !isLoading ? (
            <p className="flex-1 font-bold text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(potentialOrderRevenue)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
          {potentialCommandRevenue !== undefined && !isLoading ? (
            <p className="flex-1 font-bold text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(potentialCommandRevenue)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
        </div>
      </div>
      <div className="flex justify-between px-1 items-center">
        <p>Nombre de colis</p>
        {totalOrders !== undefined && !isLoading ? (
          <p className="font-bold">
            {Math.round(totalOrders)}{" "}
            {getDisplayedUnit("cs", companyName, storeFranchise)}.
          </p>
        ) : (
          <div className="w-12 h-3 bg-gray-200 rounded-full animate-pulse" />
        )}
      </div>
    </div>
  )
}

export default PotentialRevenue
