import { type InputHTMLAttributes, type ReactNode } from "react"
import {
  type FieldError,
  Controller,
  type Control,
  type FieldValues,
  type Path,
} from "react-hook-form"
export interface InputProps<T extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "name"> {
  icon?: ReactNode
  type: "text" | "password" | "email"
  label?: string
  error?: FieldError | undefined
  control?: Control<T>
  name: Path<T>
}

export function Input<T extends FieldValues>({
  type = "text",
  label,
  icon,
  error,
  control,
  name,
  ...props
}: InputProps<T>): JSX.Element {
  const renderInput = (fieldProps = {}): JSX.Element => (
    <div>
      <div className="flex justify-between items-center">
        {typeof label === "string" && label.length > 0 && (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}
        {error !== undefined && (
          <p className="text-red-500 text-sm leading-5 mb-2 ">
            {error.message}
          </p>
        )}
      </div>
      <div className="relative w-full">
        <div
          className={
            "relative w-full cursor-default rounded-lg bg-white text-left shadow focus:outline-none flex items-center"
          }
        >
          {icon !== undefined && (
            <div className="absolute left-0 inset-y-0 flex items-center pl-2">
              {icon}
            </div>
          )}
          <input
            type={type}
            className={`text-sm lg:text-base w-full border-2 rounded-md ring-0 focus:ring-0 focus:border-2 duration-200 transition ease-in ${icon !== undefined ? "pl-8 lg:pl-9" : ""} ${error !== undefined ? "border-red-500 focus:border-red-500" : typeof props.value === "string" && props.value !== "" ? "border-green-500 focus:border-green-500" : "border-white focus:border-green-500"}`}
            autoComplete="off"
            {...props}
            {...fieldProps}
          />
        </div>
      </div>
    </div>
  )

  return control !== undefined ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => renderInput(field)}
    />
  ) : (
    renderInput()
  )
}
