import {
  GetMessagesForStoreQueryResult,
  MessageType,
} from "@/utils/__generated__/graphql"
import { MessageCard } from "./MessageCard"
import { useMemo } from "react"

interface MessagesProps {
  messages: Exclude<
    GetMessagesForStoreQueryResult["data"],
    undefined
  >["messagesForStore"]
}

export function Messages({ messages }: MessagesProps) {
  const sortedMessages = useMemo(() => {
    // Sort messages to put "ANNOUNCEMENT" messages first
    return [...messages].sort((a, b) => {
      if (
        (a.type === MessageType.Announcement ||
          b.type === MessageType.Announcement) &&
        a.type !== b.type
      ) {
        return a.type === MessageType.Announcement ? -1 : 1
      }
      return 0
    })
  }, [messages])

  return (
    <>
      {sortedMessages.map((message) => (
        <MessageCard key={message.id} message={message} />
      ))}
    </>
  )
}
