import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { CheckInModal } from "./CheckInModal/CheckInModal"
import { LastWeeksPerformancesModal } from "./LastWeeksPerformancesModal/LastWeeksPerformancesModal"
import { LoadingRefreshModal } from "./LoadingRefreshModal"
import { MissingPlanningsModal } from "./MissingPlanningsModal"
import { OfflineDataModal } from "./OfflineDataModal"
import { OutdatedDataModal } from "./OutdatedDataModal"
import { RetrySaveModal, SaveDataModalState } from "./RetrySaveModal"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { MessagesModal } from "./MessagesModal"

interface InventoryModalsProps {
  isLoading: boolean
  saveDataModalState: SaveDataModalState
  isOfflineRefreshModalOpen: boolean
  isDataSynchronizeModalOpen: boolean
  isRefreshModalOpen: boolean
  isOrderReceptionDateModalOpen: boolean
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
  setIsOfflineRefreshModalOpen: Dispatch<SetStateAction<boolean>>
  setIsDataSynchronizeModalOpen: Dispatch<SetStateAction<boolean>>
  setIsRefreshModalOpen: Dispatch<SetStateAction<boolean>>
  setIsOrderReceptionDateModalOpen: Dispatch<SetStateAction<boolean>>
  updateInventory: (value: string) => Promise<void>
}

export function InventoryModals({
  isLoading,
  saveDataModalState,
  isOfflineRefreshModalOpen,
  isDataSynchronizeModalOpen,
  isRefreshModalOpen,
  isOrderReceptionDateModalOpen,
  setSaveDataModalState,
  setIsOfflineRefreshModalOpen,
  setIsDataSynchronizeModalOpen,
  setIsRefreshModalOpen,
  setIsOrderReceptionDateModalOpen,
  updateInventory,
}: InventoryModalsProps) {
  const refreshModalCloseDate = useSelector(
    (state: StateType) => state.updateReducer.refreshModalCloseDate,
  )
  const orderDate = useSelector(
    (state: StateType) => state.mercurialReducer.orderDate,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const companyName = useSelector(
    (state: StateType) => state.storeReducer.companyName,
  )

  function refreshApplication() {
    window.location.href = `/?date=${new Date().getTime()}`
  }

  useEffect(() => {
    if (online && isOfflineRefreshModalOpen) {
      refreshApplication()
    }
  }, [isOfflineRefreshModalOpen, online])

  useEffect(() => {
    if (window.location.search.includes("date")) {
      window.history.replaceState({}, document.title, window.location.pathname)
      setIsDataSynchronizeModalOpen(true)
      return
    }
    if (!isLoading) {
      setIsDataSynchronizeModalOpen(false)
    }
  }, [isLoading, setIsDataSynchronizeModalOpen])

  const isValidOrderDate = useMemo(() => {
    if (isLoading) return true
    const currentDate = formatDateToYYYYMMDD(new Date())
    return orderDate === currentDate
  }, [isLoading, orderDate])

  useEffect(() => {
    const currentDate = new Date()
    if (
      isValidOrderDate ||
      (refreshModalCloseDate !== undefined &&
        getDiffBetweenDates(currentDate, new Date(refreshModalCloseDate)) >= 0)
    ) {
      if (companyName === "biomonde") {
        setIsOrderReceptionDateModalOpen(false)
        return
      }
      setIsRefreshModalOpen(false)
      return
    }

    if (companyName === "biomonde") {
      setIsOrderReceptionDateModalOpen(true)
      return
    }
    setIsRefreshModalOpen(true)
  }, [
    companyName,
    isValidOrderDate,
    refreshModalCloseDate,
    setIsOrderReceptionDateModalOpen,
    setIsRefreshModalOpen,
  ])

  return (
    <>
      <RetrySaveModal
        saveDataModalState={saveDataModalState}
        setSaveDataModalState={setSaveDataModalState}
      />
      <LoadingRefreshModal isOpen={isDataSynchronizeModalOpen} />
      <OfflineDataModal
        isOpen={isOfflineRefreshModalOpen}
        setIsOpen={setIsOfflineRefreshModalOpen}
        isValidOrderDate={isValidOrderDate}
      />
      <OutdatedDataModal
        isOpen={isRefreshModalOpen}
        setIsOpen={setIsRefreshModalOpen}
        setIsOfflineRefreshModalOpen={setIsOfflineRefreshModalOpen}
        refreshApplication={refreshApplication}
      />
      <MissingPlanningsModal
        isOpen={isOrderReceptionDateModalOpen}
        setIsOpen={setIsOrderReceptionDateModalOpen}
        refreshApplication={refreshApplication}
      />
      <CheckInModal
        updateInventory={updateInventory}
        isLoading={isLoading}
        isValidOrderDate={isValidOrderDate}
      />
      <LastWeeksPerformancesModal isLoading={isLoading} />
      <MessagesModal />
    </>
  )
}
