import React, { createContext, useContext, useState, ReactNode } from "react"

interface AppContextType {
  headerHeight: number
  setHeaderHeight: (height: number) => void
}

const AppContext = createContext<AppContextType | undefined>(undefined)

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  return (
    <AppContext.Provider value={{ headerHeight, setHeaderHeight }}>
      {children}
    </AppContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider")
  }
  return context
}
