import { Send } from "lucide-react"

import { toast } from "sonner"
import { useSendEngagementPromoMutation } from "@/utils/__generated__/graphql"
import { Button } from "@/components/ui/button"
import { Spinner } from "@/ui/Spinner"
interface EngagementPromoHeaderProps {
  isTestMode: boolean
  storeId: string
  isSendDisabled: boolean
}

export function EngagementPromoHeader({
  isTestMode,
  storeId,
  isSendDisabled,
}: EngagementPromoHeaderProps) {
  const [sendEngagementPromo, { loading: isSending }] =
    useSendEngagementPromoMutation({
      onCompleted: () => {
        toast.success("Promotions envoyées avec succès")
      },
      onError: (error) => {
        toast.error("Erreur lors de l'envoi des promotions: " + error.message)
      },
    })

  const handleSendPromo = async () => {
    await sendEngagementPromo({
      variables: {
        input: {
          store_id: storeId!,
          test_mode: isTestMode,
        },
      },
    })
  }
  return (
    <div className="flex items-center p-4 md:p-7 lg:p-2 justify-between">
      <div className="flex flex-col gap-2">
        <p className="text-2xl text-black font-bold">
          Engagements promotionnels
        </p>
        {isTestMode && (
          <div className="flex items-center justify-center bg-yellow-500 rounded py-1 px-4 w-[350px]">
            <p className=" text-yellow-900">
              Les modifications ne sont pas sauvegardées
            </p>
          </div>
        )}
      </div>
      <Button onClick={handleSendPromo} disabled={isSending || isSendDisabled}>
        {isSending ? (
          <Spinner className="size-4" />
        ) : (
          <Send className="size-4 mr-2" />
        )}
        Envoyer
      </Button>
    </div>
  )
}
