// src/components/BatchDeleteReferenceDialog.tsx

"use client"

import React from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  ReferenceWithRegroupingIdaRecords,
  useDeleteRegroupingIdaMutation,
} from "@/utils/__generated__/graphql"
import { toast } from "sonner"
import { Spinner } from "@/ui/Spinner"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Icon, Tag, Trash2 } from "lucide-react"
import { targetArrow } from "@lucide/lab"

interface BatchDeleteReferenceDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedReferences: ReferenceWithRegroupingIdaRecords[]
  refetch: () => void
  storeId: string
  companyId: string
  franchiseParam: string | undefined
}

const BatchDeleteReferenceDialog: React.FC<BatchDeleteReferenceDialogProps> = ({
  isOpen,
  onClose,
  selectedReferences,
  refetch,
  storeId,
  companyId,
  franchiseParam,
}) => {
  const [deleteRegroupingIdaMutation, { loading: mutationLoading }] =
    useDeleteRegroupingIdaMutation()

  const handleBatchDelete = async () => {
    try {
      // Create an array of mutation promises
      const deletePromises = selectedReferences.map((reference) =>
        deleteRegroupingIdaMutation({
          variables: {
            input: {
              sale_name_ida_base: reference.sale_name_ida_base,
              company_id: companyId!,
              franchise_name: franchiseParam!,
              store_id: storeId!,
            },
          },
        }),
      )

      // Execute all deletion mutations concurrently
      const results = await Promise.all(deletePromises)

      // Calculate the number of successful deletions
      const successCount = results.filter(
        (res) => res.data?.deleteRegroupingIda?.success,
      ).length

      // Provide feedback based on the results
      if (successCount === selectedReferences.length) {
        toast.success("Toutes les références ont été supprimées avec succès.")
      } else if (successCount > 0) {
        toast.error(
          `${successCount} sur ${selectedReferences.length} références ont été supprimées avec succès.`,
        )
      } else {
        toast.error("Aucune référence n'a été supprimée.")
      }

      // Refresh the data and close the dialog
      refetch()
      onClose()
    } catch (error) {
      console.error("Erreur lors de la suppression en lot:", error)
      toast.error("Une erreur est survenue lors de la suppression en lot.")
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <div className="flex items-center space-x-3 text-red-600">
            <Trash2 className="h-6 w-6" />
            <div>
              <DialogTitle className="text-xl font-semibold">
                Supprimer les Références Sélectionnées
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Vous êtes sur le point de supprimer{" "}
                <span className="font-medium text-gray-700">
                  {selectedReferences.length}
                </span>{" "}
                référence(s). Cette action est irréversible.
              </DialogDescription>
            </div>
          </div>
        </DialogHeader>

        <div className="space-y-4 mt-4">
          <div className="max-h-60 overflow-y-auto bg-red-50 rounded-md border border-red-200 p-2">
            <Table>
              <TableHeader>
                <TableRow className="[&>th]:border-b [&>th]:border-gray-300">
                  <TableHead>
                    <Tag size={16} />
                    <span>Code vente base</span>
                  </TableHead>
                  <TableHead>
                    <Tag size={16} />
                    <span>Libellé base</span>
                  </TableHead>
                  <TableHead>
                    <Icon iconNode={targetArrow} />
                    <span>Libellé cible</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {selectedReferences.map((record) => (
                  <TableRow key={record.sale_name_ida_base}>
                    <TableCell>{record.sale_name_ida_base}</TableCell>
                    <TableCell>{record.libelle_base}</TableCell>
                    <TableCell>{record.libelle_cible}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="flex justify-end space-x-2 pt-2">
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={mutationLoading}
              className="hover:bg-gray-100 transition-colors"
            >
              Annuler
            </Button>
            <Button
              variant="destructive"
              onClick={handleBatchDelete}
              disabled={mutationLoading}
              className="flex items-center space-x-2"
            >
              {mutationLoading ? (
                <Spinner />
              ) : (
                <>
                  <Trash2 className="h-4 w-4" />
                  <span>Supprimer</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default BatchDeleteReferenceDialog
