import { type ReactNode, useMemo } from "react"
import { twMerge } from "tailwind-merge"

interface LabelProps {
  color:
    | "green"
    | "blue"
    | "red"
    | "orange"
    | "yellow"
    | "gray"
    | "lightGray"
    | "white"
    | "black"
  variant?: "normal" | "filled" | "outline"
  children: ReactNode
  className?: string
  innerClassName?: string
  rightIcon?: ReactNode
  leftIcon?: ReactNode
}

export function Label({
  color,
  variant = "normal",
  children,
  className,
  innerClassName,
  rightIcon,
  leftIcon,
}: LabelProps): JSX.Element {
  const colors = useMemo(() => {
    switch (color) {
      case "green":
        if (variant === "filled") {
          return {
            text: "text-green-900",
            background: "bg-green-20",
          }
        }
        return {
          text: "text-green-900",
          background: "bg-stone-50",
        }
      case "white": {
        return {
          text: "text-black",
          background: "bg-white",
        }
      }
      case "black": {
        return {
          text: "text-white",
          background: "bg-[#444444]",
        }
      }
      case "blue": {
        if (variant === "outline") {
          return {
            text: "text-cyan-900",
            border: "border-cyan-900",
            background: "bg-cyan-100",
          }
        }
        if (variant === "filled") {
          return {
            text: "text-cyan-900",
            background: "bg-cyan-10",
          }
        }
        return {
          text: "text-cyan-900",
          background: "bg-stone-50",
        }
      }
      case "red":
        if (variant === "outline") {
          return {
            text: "text-red-700",
            border: "border-red-700",
            background: "transparent",
          }
        }
        if (variant === "filled") {
          return {
            text: "text-red-700",
            background: "bg-red-20",
          }
        }
        return {
          text: "text-red-700",
          background: "bg-stone-50",
        }
      case "orange":
        if (variant === "filled") {
          return {
            text: "text-orange-900",
            background: "bg-orange-40",
          }
        }
        return {
          text: "text-orange-900",
          background: "bg-stone-50",
        }
      case "yellow":
        if (variant === "filled") {
          return {
            text: "text-yellow-900",
            background: "bg-yellow-20",
          }
        }
        if (variant === "outline") {
          return {
            text: "text-yellow-600",
            border: "border-yellow-600",
            background: "transparent",
          }
        }
        return {
          text: "text-yellow-900",
          background: "bg-stone-50",
        }
      case "gray":
        if (variant === "filled") {
          return {
            text: "text-gray-600",
            border: "border-gray-200",
            background: "bg-gray-200",
          }
        }
        if (variant === "outline") {
          return {
            text: "text-gray-800",
            border: "border-gray-200",
            background: "transparent",
          }
        }
        return {
          text: "text-neutral-700",
          background: "bg-stone-50",
        }
      case "lightGray":
        return {
          text: "text-zinc-500",
          background: "bg-stone-50",
        }
    }
  }, [color, variant])

  const mergedClassName = twMerge(
    `${colors.background} whitespace-nowrap w-fit px-2 py-1 rounded-sm flex gap-1 items-center ${colors.border !== undefined ? `border ${colors.border}` : ""} ${colors.text}`,
    className,
  )

  return (
    <div className={mergedClassName}>
      {leftIcon}
      <p
        className={twMerge(
          "text-xxs md:text-xs font-bold w-fit",
          innerClassName,
        )}
      >
        {children}
      </p>
      {rightIcon}
    </div>
  )
}
