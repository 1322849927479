export const computeHistoricalRevenue = ({
  sale_historical_quantities,
  pv,
}: {
  sale_historical_quantities?: { quantity?: number | null }[] | null
  pv?: number | null
}) => {
  const totalQuantitySold = (sale_historical_quantities ?? []).reduce(
    (total, { quantity }) => total + (quantity ?? 0),
    0,
  )

  return totalQuantitySold * (pv ?? 0)
}
