// src/components/MatchSaleIdDialog.tsx

"use client"

import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  useCreateRegroupingIdaMutation,
  useGetSaleIdsWithTotalsLazyQuery,
  SaleIdsDataRecord,
} from "@/utils/__generated__/graphql"
import { toast } from "sonner"
import { Spinner } from "@/ui/Spinner"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { Package, TrendingUp, Truck } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { Virtuoso } from "react-virtuoso"
interface MatchSaleIdDialogProps {
  isOpen: boolean
  onClose: () => void
  baseSaleNameIda: string
  saleName: string
  fromDate: Date
  toDate: Date
  filterType: string
  companyId: string
  franchiseParam?: string
  storeId: string
  onMatchSuccess: () => void
}

const MatchSaleIdDialog: React.FC<MatchSaleIdDialogProps> = ({
  isOpen,
  onClose,
  baseSaleNameIda,
  saleName,
  fromDate,
  toDate,
  filterType,
  companyId,
  franchiseParam,
  storeId,
  onMatchSuccess,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")
  const [options, setOptions] = useState<SaleIdsDataRecord[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [createRegroupingIdaMutation, { loading: mutationLoading }] =
    useCreateRegroupingIdaMutation()

  const [fetchOptions, { data: optionsData, loading: optionsLoading }] =
    useGetSaleIdsWithTotalsLazyQuery()

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  useEffect(() => {
    if (debouncedSearchTerm.trim() !== "") {
      setIsLoading(true)
      fetchOptions({
        variables: {
          input: {
            sale_name_ida_current: baseSaleNameIda,
            company_id: companyId,
            franchise_name: franchiseParam,
            start_date: formatDateToYYYYMMDD(fromDate),
            end_date: formatDateToYYYYMMDD(toDate),
            search_term: debouncedSearchTerm,
            store_id: storeId,
            filter_type: filterType,
          },
        },
      })
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    } else {
      setOptions([])
    }
  }, [
    debouncedSearchTerm,
    fetchOptions,
    baseSaleNameIda,
    companyId,
    franchiseParam,
    fromDate,
    toDate,
    filterType,
    storeId,
  ])

  useEffect(() => {
    if (optionsData?.getSaleIdsWithTotals?.records) {
      setOptions(optionsData.getSaleIdsWithTotals.records)
    } else {
      setOptions([])
    }
  }, [optionsData])

  const handleSelect = async (option: SaleIdsDataRecord) => {
    const mutationInput = {
      sale_name_ida_base: baseSaleNameIda,
      sale_name_ida_cible: option.sale_name_ida,
      libelle_base: saleName,
      libelle_cible: option.sale_name,
      store_id: storeId,
      company_id: companyId,
      franchise_name: franchiseParam,
    }

    try {
      const { data } = await createRegroupingIdaMutation({
        variables: {
          input: mutationInput,
        },
      })

      if (data?.createRegroupingIda?.records) {
        onMatchSuccess()
        onClose()
      } else {
        toast.error("Échec du matching.")
      }
    } catch (error) {
      console.error("Error creating RegroupingIda:", error)
      toast.error("Une erreur est survenue lors du matching.")
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Lier la Référence</DialogTitle>
          <DialogDescription>
            Sélectionnez une référence cible pour lier avec{" "}
            <strong>{baseSaleNameIda}</strong> - <em>{saleName}</em>.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground">
              <TrendingUp className="h-4 w-4" />
            </span>
            <Input
              placeholder="Rechercher une référence..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
          </div>

          {(optionsLoading || isLoading) && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          <div className="h-60">
            {options.length === 0 && !optionsLoading && !isLoading ? (
              <div className="text-gray-500">Aucune référence trouvée.</div>
            ) : (
              <Virtuoso
                className="h-60"
                data={options}
                itemContent={(_, option) => (
                  <Button
                    key={option.sale_id}
                    variant="outline"
                    onClick={() => handleSelect(option)}
                    className="w-full text-left mb-2"
                  >
                    <div className="flex items-start justify-between">
                      <div className="space-y-1">
                        <div className="flex items-center gap-2">
                          <span className="font-medium">
                            {option.sale_name}
                          </span>
                          <Badge variant="outline" className="text-xs">
                            {option.sale_name_ida}
                          </Badge>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-4 text-sm text-muted-foreground">
                      <div className="flex items-center gap-1">
                        <TrendingUp className="w-4 h-4" />
                        <span>Ventes: {option.total_sales.toFixed(2)}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Package className="w-4 h-4" />
                        <span>Commandes: {option.total_orders.toFixed(2)}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Truck className="w-4 h-4" />
                        <span>
                          Livraisons: {option.total_deliveries.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Button>
                )}
              />
            )}
          </div>

          {mutationLoading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Annuler
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MatchSaleIdDialog
