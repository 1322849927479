import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

export function CompaniesPage(): JSX.Element {
  const navigate = useNavigate()
  const { companyId, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  useEffect(() => {
    navigate(
      `/admin/companies/${companyId}${
        storeFranchise !== null ? `?franchise=${storeFranchise}` : ""
      }`,
    )
  }, [companyId, navigate, storeFranchise])

  return <></>
}
