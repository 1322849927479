import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { CheckIcon, ChevronDownIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useState } from "react"
import { upperFirst } from "lodash"

const BreadcrumbCategory = ({
  activeLabel,
  items,
  className,
  isSearchable,
  onSelect,
  placeholder,
}: {
  placeholder: string
  onSelect: (value?: string) => void
  isSearchable?: boolean
  className?: string
  activeLabel?: string
  items: string[]
}) => {
  const [isOpened, setOpen] = useState(false)

  return (
    <Popover open={isOpened} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <BreadcrumbItem
          role="combobox"
          aria-expanded={isOpened}
          className={cn(
            "justify-between text-sm md:text-base font-medium select-none rounded px-2 py-2 cursor-pointer leading-tight",
            className,
          )}
        >
          {activeLabel ? upperFirst(activeLabel) : placeholder}
          <ChevronDownIcon className="h-4 w-4 shrink-0 opacity-50" />
        </BreadcrumbItem>
      </PopoverTrigger>
      <PopoverContent className="w-min p-0" align="start">
        <Command>
          {isSearchable && (
            <CommandInput className="!outline-none ![box-shadow:none] !border-none" />
          )}
          <CommandList>
            <CommandGroup>
              <CommandItem
                className={!activeLabel ? "font-medium" : ""}
                value={undefined}
                onSelect={() => {
                  onSelect(undefined)
                  setOpen(false)
                }}
              >
                <CheckIcon
                  className={cn(
                    "mr-2 h-4 w-4",
                    !activeLabel ? "opacity-100" : "opacity-0",
                  )}
                />
                {placeholder}
              </CommandItem>
              {items.map((item) => (
                <CommandItem
                  key={item}
                  value={item}
                  className={
                    activeLabel === item
                      ? "font-bold"
                      : "font-medium text-muted-foreground"
                  }
                  onSelect={(value) => {
                    onSelect(value)
                    setOpen(false)
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 h-4 w-4",
                      item === activeLabel ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {upperFirst(item)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const CategoryBreadcrumb = ({
  subCategories,
  categories,
  activeCategory,
  activeSubcategory,
  onSelectCategory,
  onSelectSubcategory,
}: {
  subCategories: string[]
  categories: string[]
  activeCategory?: string
  activeSubcategory?: string
  onSelectCategory: (category?: string) => void
  onSelectSubcategory: (category?: string) => void
}) => {
  const showSubcategories = subCategories.length > 1
  const showCategories = categories.length > 1

  if (!showCategories && !showSubcategories) return null

  return (
    <Breadcrumb>
      <BreadcrumbList className="flex-nowrap whitespace-nowrap">
        {showCategories && (
          <BreadcrumbCategory
            className={showSubcategories ? "" : "text-primary"}
            onSelect={onSelectCategory}
            items={categories}
            activeLabel={activeCategory}
            placeholder="Tous les produits"
          />
        )}
        {showCategories && showSubcategories && (
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
        )}
        {showSubcategories && (
          <BreadcrumbCategory
            placeholder={
              showCategories
                ? `Tous les ${activeCategory}`
                : "Tous les produits"
            }
            onSelect={onSelectSubcategory}
            isSearchable
            className="text-primary"
            items={subCategories}
            activeLabel={activeSubcategory}
          />
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
