import { StateType } from "@/types"
import {
  Promotions,
  useGetEngagementPromoQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { EngagementPromoHeader } from "./components/EngagementPromoHeader"
import { EngagementPromoDateSelector } from "./components/EngagagementPromoDateSelector"
import { EngagementPromoContent } from "./components/EngagementPromoContent"
import { Spinner } from "@/ui/Spinner"
import Header from "@/components/header"

export interface GroupedPromotion {
  operation_name: string
  start_date: string
  end_date: string
  start_date_opc: string
  end_date_opc: string
  promotions: Promotions[]
}

export function EngagementPromoPage() {
  const { storeId } = useSelector((state: StateType) => state.storeReducer)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const { data, loading } = useGetEngagementPromoQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })

  const groupedPromotions = useMemo(() => {
    return (
      data?.getEngagementPromo.promotions?.reduce<GroupedPromotion[]>(
        (groupedPromotions, promotion) => {
          const foundPromotionIndex = groupedPromotions.findIndex(
            (groupedPromotion) =>
              groupedPromotion.operation_name === promotion.operation_name &&
              groupedPromotion.start_date === promotion.start_date &&
              groupedPromotion.end_date === promotion.end_date,
          )
          if (foundPromotionIndex !== -1) {
            groupedPromotions[foundPromotionIndex].promotions.push(promotion)
            return groupedPromotions
          }

          groupedPromotions.push({
            operation_name: promotion.operation_name,
            start_date: promotion.start_date,
            end_date: promotion.end_date,
            start_date_opc: promotion.start_date_opc,
            end_date_opc: promotion.end_date_opc,
            promotions: [promotion],
          })
          return groupedPromotions
        },
        [],
      ) ?? []
    )
  }, [data])

  return (
    <>
      <Header />
      <div className="flex flex-col gap-2 px-6">
        <EngagementPromoHeader
          isTestMode={isTestMode}
          storeId={storeId!}
          isSendDisabled={loading || groupedPromotions.length === 0}
        />
        {/* Engagements promo is loading */}
        {loading && (
          <div className="w-full flex-1 flex justify-center items-center">
            <Spinner />
          </div>
        )}
        {/* No data found */}
        {!loading && groupedPromotions.length === 0 && (
          <p className="text-center">
            Aucune promotion n&apos;est disponible pour le moment
          </p>
        )}
        <div className="mb-20">
          {/* Engagements promo content */}
          {!loading && groupedPromotions.length > 0 && (
            <div>
              <EngagementPromoDateSelector
                groupedPromotions={groupedPromotions}
              />
              <div className="mt-4">
                <EngagementPromoContent groupedPromotions={groupedPromotions} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
