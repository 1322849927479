import { TableHead, TableHeader, TableRow } from "@/components/ui/table"

export function PerformancesTableHeader() {
  return (
    <TableHeader className="sticky top-0 bg-gray-100 z-10">
      <TableRow>
        <TableHead>Rang (distance pred - ventes)</TableHead>
        <TableHead>Magasin</TableHead>
        <TableHead>Commandes</TableHead>
        <TableHead>Préds (avant recalage)</TableHead>
        <TableHead>Préds (post recalage)</TableHead>
        <TableHead>Préds de CA</TableHead>
        <TableHead>Ventes</TableHead>
        <TableHead>Démarque</TableHead>
        <TableHead>Modifications</TableHead>
      </TableRow>
    </TableHeader>
  )
}
