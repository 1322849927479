import ConfirmRecapModal from "../../assets/modals/ConfirmRecapModal"
import Table from "./components/index"
import { Button } from "../../ui/Button"
import {
  GetOrderItem,
  OrderItem,
  useGetOrderLazyQuery,
  useInactiveItemsLazyQuery,
  useSendOrderMutation,
} from "../../utils/__generated__/graphql"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "../../types"
import {
  AllMercurialInfo,
  defaultMercurialReducerState,
} from "../../reducers/mercurialReducer"
import Success from "../../assets/Success"
import { HomeIcon } from "../../ui/icons/HomeIcon"
import { Img } from "react-image"
import { captureException } from "@sentry/react"
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom"
import { filteredMercurialeReducerSelector } from "../../selectors/mercurialeSelectors"
import { InventoryContext } from "../../components/inventory/InventoryRoot"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { OrderCorrectionModal } from "../../assets/modals/OrderCorrectionModal"
import { getOrderQuantity } from "../../utils/getOrderQuantity"
import { useWindowSize } from "@/hooks/useWindowSize"
import { toast } from "sonner"
import { AlertTriangleIcon } from "@/assets/AlertTriangle"
import { getDisplayedUnit } from "@/utils/getUnit"

interface MissingItem {
  name: string
  quantity: number
  photoId?: string
  order_id?: string
  error_message?: string
}

const Recap = () => {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [inactiveReferencesIds, setInactiveReferencesIds] = useState<string[]>(
    [],
  )

  const [{ synchronisationLoading }] = useOutletContext<InventoryContext>()

  const { storeId, companyId, companyName, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { updatedReferences, mercurialAndStoreInventories } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const user = useSelector((state: StateType) => state.userReducer)

  const dimMercurialeId =
    mercurialAndStoreInventories[0]?.dim_mercuriale_id ?? null

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) => dimMercuriale.dimMercurialeId === dimMercurialeId,
  )?.dimOrderRequestId
  const { dataSynchronizationStatus, online } = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const enable = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [getInactiveItems] = useInactiveItemsLazyQuery({
    variables: {
      input: {
        dim_mercuriale_id: dimMercurialeId ?? "",
        store_id: storeId ?? "",
      },
    },
    fetchPolicy: "network-only",
  })

  const getInactiveItemsHandler = async () => {
    const result = await getInactiveItems()
    setInactiveReferencesIds(result.data?.getInactiveItems.inactive_items ?? [])
    dispatch({
      type: "setInactiveReferences",
      payload: result.data?.getInactiveItems.inactive_items ?? [],
    })
  }

  useEffect(() => {
    void getInactiveItemsHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const savedUpdatedReferences = useRef(
    Object.values(updatedReferences).filter((updatedReference) => {
      return (updatedReference.orderInventoryQuantity ?? 0) > 0
    }),
  )
  const savedForgottenReferences = useRef(
    Object.values(updatedReferences).filter((updatedReference) => {
      const mercurialeInfo = mercurialAndStoreInventories.find(
        (mercurialeInfo) =>
          mercurialeInfo.mercuriale_id === updatedReference.mercurialeId,
      )
      return (
        (mercurialeInfo?.quantity_predicted_array?.[0] ?? 0) > 0 &&
        (updatedReference.orderInventoryQuantity ?? 0) === 0
      )
    }),
  )
  const orderId = searchParams.get("orderId")

  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false)
  const [orderErrorMessage, setOrderErrorMessage] = useState<string>()
  const [missingReferences, setMissingReferences] = useState<MissingItem[]>([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [isWaitingSend, setIsWaitingSend] = useState(false)
  const [isOrderCorrectionModalOpen, setIsOrderCorrectionModalOpen] =
    useState(false)

  const [sendOrderMutation, { loading: sendOrderLoading }] =
    useSendOrderMutation()
  const [getOrder, { data: orderData, loading: isOrderLoading }] =
    useGetOrderLazyQuery()

  useEffect(() => {
    if (orderId === null || typeof storeId !== "string") return
    void getOrder({
      variables: {
        input: { store_id: storeId, dim_order_request_id: orderId },
      },
    })
  }, [getOrder, orderId, storeId])

  const references = useMemo<(AllMercurialInfo | GetOrderItem)[]>(() => {
    if (isOrderLoading) return []
    if (
      orderData?.getOrder.order_items !== undefined &&
      orderData?.getOrder.order_items !== null
    ) {
      return orderData.getOrder.order_items
    }
    if (typeof orderId === "string" && orderId !== "") return []

    const mercurialeInfos = mercurialAndStoreInventories.reduce<
      Record<string, AllMercurialInfo>
    >((acc, curr) => {
      if (typeof curr.mercuriale_id !== "string") return acc
      acc[curr.mercuriale_id] = curr
      return acc
    }, {})
    return savedUpdatedReferences.current
      ?.filter(
        (_updatedReference) =>
          mercurialeInfos[_updatedReference.mercurialeId] !== undefined,
      )
      ?.map((_updatedReference) => {
        const updatedReference =
          updatedReferences[_updatedReference.mercurialeId]
        const mercuriale = mercurialeInfos[updatedReference.mercurialeId]
        const quantity =
          updatedReference.orderInventoryQuantity ??
          mercuriale.quantity_actual ??
          0
        return {
          ...mercuriale,
          quantity_actual: quantity,
          is_sold_out: inactiveReferencesIds.includes(
            mercuriale.mercuriale_id ?? "",
          ),
          back_inventory_qty:
            updatedReference.backInventoryQuantity ??
            mercuriale.back_inventory_qty ??
            0,
          floor_inventory_qty:
            updatedReference.floorInventoryQuantity ??
            mercuriale.floor_inventory_qty ??
            0,
          shelf_floor_size:
            updatedReference.shelfFloorSize ?? mercuriale.shelf_floor_size ?? 0,
        }
      })
  }, [
    isOrderLoading,
    mercurialAndStoreInventories,
    orderData?.getOrder.order_items,
    orderId,
    updatedReferences,
    inactiveReferencesIds,
  ])

  const forgottenReferences = useMemo<AllMercurialInfo[]>(() => {
    const mercurialeInfos = mercurialAndStoreInventories.reduce<
      Record<string, AllMercurialInfo>
    >((acc, curr) => {
      if (typeof curr.mercuriale_id !== "string") return acc
      acc[curr.mercuriale_id] = curr
      return acc
    }, {})
    return savedForgottenReferences.current
      ?.filter(
        (_updatedReference) =>
          mercurialeInfos[_updatedReference.mercurialeId] !== undefined,
      )
      ?.map((_updatedReference) => {
        const updatedReference =
          updatedReferences[_updatedReference.mercurialeId]
        const mercuriale = mercurialeInfos[updatedReference.mercurialeId]
        const quantity =
          updatedReference.orderInventoryQuantity ??
          mercuriale.quantity_actual ??
          0
        return {
          ...mercuriale,
          quantity_actual: quantity,
          back_inventory_qty:
            updatedReference.backInventoryQuantity ??
            mercuriale.back_inventory_qty ??
            0,
          floor_inventory_qty:
            updatedReference.floorInventoryQuantity ??
            mercuriale.floor_inventory_qty ??
            0,
          shelf_floor_size:
            updatedReference.shelfFloorSize ?? mercuriale.shelf_floor_size ?? 0,
        }
      })
  }, [mercurialAndStoreInventories, updatedReferences])

  const checkoutReferences = useMemo(() => {
    return [...forgottenReferences, ...references]
  }, [forgottenReferences, references])

  const excessiveProducts = useMemo(() => {
    return references.filter(
      (product) =>
        ("quantity_predicted_array" in product &&
          Array.isArray(product.quantity_predicted_array) &&
          product.quantity_predicted_array[0] !== undefined &&
          (product.quantity_actual ?? 0) >
            product.quantity_predicted_array[0] * 3) ||
        (product.quantity_actual ?? 0) > 99,
    )
  }, [references])

  const tooLowProducts = useMemo(() => {
    return checkoutReferences.filter((product) => {
      return (
        "quantity_predicted_array" in product &&
        Array.isArray(product.quantity_predicted_array) &&
        product.quantity_predicted_array[0] !== undefined &&
        (product.quantity_actual ?? 0) <
          getOrderQuantity({
            backQuantity: product.back_inventory_qty ?? 0,
            floorQuantity: product.floor_inventory_qty ?? 0,
            predictedQuantityArray: product.quantity_predicted_array ?? [],
          }) *
            0.3
      )
    })
  }, [checkoutReferences])

  const notAvailableProducts = useMemo(() => {
    return checkoutReferences.filter(
      (product) => "active" in product && product.is_sold_out,
    )
  }, [checkoutReferences])

  const boxProducts = useMemo(() => {
    return references.filter(
      (product) =>
        (product.quantity_actual ?? 0) > 0 &&
        product.mercuriale_name?.toLowerCase().includes("box") === true,
    )
  }, [references])

  const moveHandler = useCallback(
    async (isWaitingSend: boolean) => {
      if (isWaitingSend) {
        setIsWaitingSend(true)
        return
      }
      try {
        const orderItems: OrderItem[] = mercurialAndStoreInventories
          .filter((mercuriale) => {
            const updatedReference =
              updatedReferences[mercuriale.mercuriale_id ?? ""]
            return (
              ((updatedReference?.orderInventoryQuantity ??
                mercuriale?.quantity_actual ??
                (mercuriale?.quantity_predicted_array ?? [])[0] ??
                0) > 0 ||
                updatedReference?.isOrderInventoryQuantityUpdated === true) &&
              (mercuriale.active ?? true) === true
            )
          })
          .map((mercuriale) => {
            const updatedReference =
              updatedReferences[mercuriale.mercuriale_id ?? ""]
            return {
              supplier_id: mercuriale.supplier_id ?? "",
              supplier_internal_code: mercuriale.supplier_internal_code,
              order_name: mercuriale.mercuriale_name,
              order_id: mercuriale.order_id,
              quantity_actual:
                updatedReference?.orderInventoryQuantity ??
                mercuriale.quantity_actual ??
                (mercuriale.quantity_predicted_array ?? [])[0] ??
                null,
              price: mercuriale.pa,
              pv: mercuriale.pv,
              unit: mercuriale.unit,
              local_flag: mercuriale.local_flag,
              sale_name_ida: mercuriale.sale_name_ida,
              colisage: mercuriale.colisage,
              item_key: mercuriale.item_key,
              shipping_code: mercuriale.shipping_code,
              shipping_canal: mercuriale.shipping_canal,
              order_code: mercuriale.order_code,
              dim_mercuriale_id: mercuriale.dim_mercuriale_id!,
              mercuriale_id: mercuriale.mercuriale_id!,
              order_pickup_time: mercuriale.order_pickup_time,
              expected_reception_date:
                mercuriale?.order_expected_reception_date ??
                mercuriale.mercuriale_reception_date,
              sub_family_key: mercuriale.sub_family_key,
              final_quantity_predicted: getOrderQuantity({
                backQuantity: mercuriale.back_inventory_qty ?? 0,
                floorQuantity: mercuriale.floor_inventory_qty ?? 0,
                predictedQuantityArray:
                  mercuriale.quantity_predicted_array ?? [],
              }),
            }
          })

        if (orderItems.length === 0) return

        const sendOrderResult = await sendOrderMutation({
          variables: {
            input: {
              store_id: storeId ?? "",
              test_mode: enable,
              dim_order_request_id: dimOrderRequestId,
              order_items: orderItems,
            },
          },
        })

        if (sendOrderResult.data?.sendOrder.error !== null) {
          const errorCode =
            sendOrderResult.data?.sendOrder.error !== undefined &&
            "ida_error_code" in sendOrderResult.data.sendOrder.error
              ? sendOrderResult.data?.sendOrder.error.ida_error_code
              : undefined

          if (
            errorCode === "DIM_ORDER_REQUEST_COMPLETED" ||
            errorCode === "DIM_ORDER_REQUEST_CANCELLED" ||
            errorCode === "DIM_ORDER_REQUEST_NOT_FOUND"
          ) {
            setOrderErrorMessage(sendOrderResult.data?.sendOrder.error?.message)
            setConfirmModal(false)
            dispatch({
              type: "setMercurial",
              payload: defaultMercurialReducerState,
            })
          }
          throw new Error(sendOrderResult.data?.sendOrder.error?.message)
        }

        const missingItems: MissingItem[] =
          sendOrderResult.data?.sendOrder?.missing_items?.map((missingItem) => {
            const photoId =
              mercurialAndStoreInventories.find(
                (mercuriale) => mercuriale.order_id === missingItem.order_id,
              )?.photo_id ?? undefined
            return {
              photoId,
              name: missingItem.order_name,
              quantity: missingItem.quantity_actual,
              order_id: missingItem.order_id,
              error_message: missingItem.error_message ?? undefined,
            }
          }) ?? []

        const inactiveMercurialeItems: MissingItem[] =
          mercurialAndStoreInventories
            .filter((mercuriale) => {
              const updatedReference =
                updatedReferences[mercuriale.mercuriale_id ?? ""]
              return (
                mercuriale.active === false &&
                (updatedReference?.orderInventoryQuantity ??
                  mercuriale.quantity_actual ??
                  0 > 0)
              )
            })
            .map((mercuriale) => {
              const updatedReference =
                updatedReferences[mercuriale.mercuriale_id ?? ""]
              return {
                photoId: mercuriale.photo_id ?? undefined,
                name: mercuriale.mercuriale_name ?? "",
                quantity:
                  updatedReference?.orderInventoryQuantity ??
                  mercuriale.quantity_actual ??
                  0,
                error_message: "Référence non disponible en mercuriale",
              }
            })

        setMissingReferences([...inactiveMercurialeItems, ...missingItems])

        dispatch({
          type: "setMercurial",
          payload: defaultMercurialReducerState,
        })

        setIsOrderConfirmed(true)
        setConfirmModal(false)
      } catch (error) {
        console.error(error)
        captureException(error)
        toast.error("Commande non envoyée")
      } finally {
        setIsWaitingSend(false)
      }
    },
    [
      dimOrderRequestId,
      dispatch,
      enable,
      mercurialAndStoreInventories,
      sendOrderMutation,
      storeId,
      updatedReferences,
    ],
  )

  useEffect(() => {
    if (
      dataSynchronizationStatus === DataSynchronizationStatus.SYNCHRONIZED &&
      online &&
      isWaitingSend
    ) {
      moveHandler(false)
    }
  }, [dataSynchronizationStatus, isWaitingSend, moveHandler, online])

  const handleValidateOrderPreview = () => {
    if (
      excessiveProducts.length > 0 ||
      tooLowProducts.length > 0 ||
      notAvailableProducts.length > 0
    ) {
      setIsOrderCorrectionModalOpen(true)
    } else {
      setConfirmModal(true)
    }
  }

  const { isMD } = useWindowSize()

  return (
    <div
      className={`h-screen ${isMD ? "p-2" : ""} flex flex-col gap-2 ${user.storeId !== storeId ? "pb-12 md:pb-0" : ""}`}
    >
      <div className="flex-1">
        {isOrderConfirmed || orderErrorMessage !== undefined ? (
          <div className="w-full h-full flex items-center flex-col justify-center border border-zinc-200 rounded p-2 gap-4">
            {orderErrorMessage !== undefined ? (
              <>
                <AlertTriangleIcon />
                <p className="text-zinc-400 text-xl">{orderErrorMessage}</p>
              </>
            ) : (
              <>
                <Success />
                <p className="text-zinc-400 text-xl">Commande envoyée</p>
              </>
            )}
            {missingReferences.length > 0 && (
              <>
                <h2 className="self-start font-bold text-lg">
                  Références indisponibles à la commande
                </h2>
                <div className="w-full overflow-y-auto gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                  {missingReferences.map((missingReference, i) => (
                    <div
                      key={i}
                      className="font-bold text-sm py-4 px-4 rounded bg-white shadow flex flex-col gap-2 items-center justify-center"
                    >
                      {missingReference.photoId && (
                        <Img
                          src={`https://storage.googleapis.com/references_images/${companyId}/${missingReference.photoId}.jpeg`}
                          className="w-12 h-12 rounded-full"
                        />
                      )}
                      <p className="font-bold text-sm text-center">
                        {missingReference.name}
                      </p>
                      <p className="text-zinc-500">
                        {missingReference.quantity}{" "}
                        {getDisplayedUnit("cs", companyName, storeFranchise)}
                      </p>
                      <p className="text-zinc-500">
                        <span className="font-bold">Raison :</span>{" "}
                        <span className="font-normal italic">
                          {missingReference.error_message}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <Table
            references={references}
            excessiveProducts={excessiveProducts}
            tooLowProducts={tooLowProducts}
            notAvailableProducts={notAvailableProducts}
            handleValidateOrderPreview={handleValidateOrderPreview}
            isOrderConfirmed={isOrderConfirmed}
            orderId={orderId}
            enable={enable}
          />
        )}
      </div>
      <div className="flex gap-2 justify-between">
        {(isOrderConfirmed || orderErrorMessage !== undefined) && (
          <Button
            size="lg"
            className="uppercase font-bold h-10 md:h-auto"
            leftIcon={<HomeIcon className="w-5 h-5" />}
            onClick={() => navigate("/home")}
          >
            Accueil
          </Button>
        )}
      </div>
      <OrderCorrectionModal
        open={isOrderCorrectionModalOpen}
        setOpen={setIsOrderCorrectionModalOpen}
        handleConfirm={() => {
          setIsOrderCorrectionModalOpen(false)
          setConfirmModal(true)
        }}
        excessiveProducts={excessiveProducts}
        tooLowProducts={tooLowProducts}
        boxProducts={boxProducts}
        notAvailableProducts={notAvailableProducts}
        allItems={checkoutReferences}
        companyName={companyName}
        franchise={storeFranchise}
      />
      <ConfirmRecapModal
        open={confirmModal}
        setOpen={setConfirmModal}
        isLoading={sendOrderLoading || synchronisationLoading}
        handleConfirm={moveHandler}
        isWaitingSend={isWaitingSend}
        setIsWaitingSend={setIsWaitingSend}
      />
    </div>
  )
}

export default Recap
