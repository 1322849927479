import { Label } from "./Label"
import { ReceiptPercentIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import BreakageLabel from "../components/inventory/BreakageLabel"
import { ShoppingCart, Tag, TrophyIcon } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { upperFirst } from "lodash"

interface BadgesProps {
  expanded?: boolean
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  isBestSeller?: boolean
  hideBreakageLabel?: boolean
  hide?: boolean
  isFirstRow?: boolean
  storeCurrency: string | null
  hidePromotion?: boolean
}

export function Badges({
  expanded = false,
  data,
  isBestSeller = false,
  hideBreakageLabel = false,
  hide = false,
  isFirstRow = false,
  storeCurrency,
  hidePromotion = false,
}: BadgesProps) {
  const breakagePercent =
    typeof data?.breakage_percentage === "number"
      ? Math.round(data.breakage_percentage)
      : 0

  const breakageAmount = Math.round(data?.breakage_amount ?? 0)

  const hasSignificantBreakageAmount = breakageAmount >= 1
  const isPromotion =
    hidePromotion === false &&
    (data?.promotion === true || typeof data.message_promotion === "string")
  const isPromotionWithOffer =
    isPromotion && data.message_promotion?.toLowerCase() !== "sans offre"
  const isExpanded = expanded
  const isNewReference = data.new_reference === true
  const isBestSellerInFirstRow = isBestSeller && isFirstRow
  const isEngagementPromo = data.engagement_promo_flag === true

  const shouldShowLabels =
    hasSignificantBreakageAmount ||
    isPromotion ||
    isExpanded ||
    isNewReference ||
    isBestSellerInFirstRow ||
    isEngagementPromo

  if (!shouldShowLabels || hide) {
    return <></>
  }

  return (
    <div className="flex flex-wrap gap-2 max-w-72 md:max-w-full">
      {isEngagementPromo === true && (
        <Badge className="bg-gradient-to-r from-orange-500 to-red-500 transition-colors text-xs font-medium text-white border-none">
          <Tag className="w-3.5 h-3.5 mr-1.5" />
          ENGAGEMENT PROMO
        </Badge>
      )}
      {data.new_reference === true && !isEngagementPromo && (
        <Label
          color="yellow"
          variant="filled"
          leftIcon={<SparklesIcon className="w-4 h-4" />}
        >
          Nouveau
        </Label>
      )}
      {isBestSeller && isFirstRow && (
        <Badge
          variant="secondary"
          className="flex items-center font-semibold rounded-full  border-none bg-gradient-to-r from-amber-100 to-amber-200 text-amber-800"
        >
          <TrophyIcon className="w-3.5 h-3.5 mr-1.5" />
          Top vente
        </Badge>
      )}
      {!hideBreakageLabel && breakageAmount >= 1 && (
        <BreakageLabel
          shrinkage={breakagePercent}
          amount={breakageAmount}
          expanded={expanded}
          storeCurrency={storeCurrency}
        />
      )}
      {/* If 'promotion' is true and 'message_promotion' is null, display 'PROMOTION' */}
      {data?.promotion === true &&
        (typeof data?.message_promotion !== "string" ||
          data.message_promotion.toLowerCase() === "sans offre") && (
          <Label
            color="blue"
            variant="filled"
            leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
          >
            PROMO
            <span className={`${expanded ? "inline" : "hidden"} md:inline`}>
              TION
            </span>
          </Label>
        )}

      {/* If 'message_promotion' is not null, display the message along with the end date */}
      {isPromotion && isPromotionWithOffer && (
        <div className="flex items-center">
          <Label
            color="blue"
            variant="filled"
            leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
            className="text-nowrap"
          >
            {upperFirst(data.message_promotion?.toLowerCase())}
            {typeof data?.promotion_end_date === "string" && (
              <span>
                {" - "}Fin le{" "}
                {new Intl.DateTimeFormat("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                }).format(new Date(data?.promotion_end_date))}
              </span>
            )}
          </Label>
        </div>
      )}
      {!data.typology_flag && (
        <Badge
          variant="secondary"
          className="text-xs font-medium bg-amber-100 text-amber-800 border border-amber-200  whitespace-nowrap hover:bg-amber-100"
        >
          <ShoppingCart className="w-4 h-4 mr-2" />
          GAMME COMPLEMENTAIRE
        </Badge>
      )}
    </div>
  )
}
