export interface GetTotalStocksParams {
  backInventoryQty: number | undefined | null
  floorInventoryQty: number | undefined | null
  isToVerify: boolean | undefined | null
}

/**
 * Returns the total stock of a reference including/excluding back inventory quantity if the value is to verify.
 * @example
 * const total = getTotalStocks({ backInventoryQty: 10, floorInventoryQty: 20, isToVerify: false })
 * // total === 30
 * const total = getTotalStocks({ backInventoryQty: 10, floorInventoryQty: 20, isToVerify: true })
 * // total === 20
 */
export function getTotalStocks({
  backInventoryQty,
  floorInventoryQty,
  isToVerify,
}: GetTotalStocksParams) {
  const totalInventoryQuantity =
    (isToVerify !== true && typeof backInventoryQty === "number"
      ? backInventoryQty
      : 0) + (floorInventoryQty ?? 0)

  return totalInventoryQuantity
}
