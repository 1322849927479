import { ReactNode } from "react"

export interface SelectorData<T> {
  value: T
  label: ReactNode
  selectedLabel?: ReactNode
}

export type RingColor =
  | "red"
  | "lightRed"
  | "orange"
  | "lightOrange"
  | "yellow"
  | "blue"
  | "softOrange"

export const ringColors: Record<RingColor, string> = {
  red: "border-red-700",
  lightRed: "border-red-600",
  orange: "border-custom-orange",
  lightOrange: "border-custom-lightOrange",
  yellow: "border-yellow-500",
  blue: "border-cyan-700",
  softOrange: "border-orange-100",
}
