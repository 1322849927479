import { useState, useEffect } from "react"

import { Input } from "@/components/ui/input"
import SupplierSelection from "@/components/storeScheduler/SupplierSelection"
import BulkUpsertPlanner from "@/components/storeScheduler/bulkUpsertPlanner"

import { dayNameToEnumValue, enumValueToDayName } from "@/utils/dayUtils"

import {
  useGetStoresQuery,
  useGetPlanningDaysQuery,
  useUpsertMultiplePlanningDaysMutation,
  useUpsertPlanningDayMutation,
  useDeletePlanningDayMutation,
  OrderDay,
} from "@/utils/__generated__/graphql"
import { useParams, useSearchParams } from "react-router-dom"

import StoresTable from "@/components/storeScheduler/storeTable"

import { Schedule, ScheduleEntry } from "@/types"
import { toast } from "sonner"
const days = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
]

export function StoreScheduler() {
  const params = useParams<{ companyId: string }>()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise")
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [schedule, setSchedule] = useState<Schedule>([])
  const [openPopovers, setOpenPopovers] = useState<Record<string, boolean>>({})

  const [selectedOrderNumber, setSelectedOrderNumber] = useState<
    Record<string, number>
  >({})

  const getPopoverKey = (storeId: string, day: string, orderNumber: number) => {
    return `${storeId}-${day}-${selectedSupplier}-order-${orderNumber}`
  }

  const { data } = useGetStoresQuery({
    variables: {
      input: {
        company_id: params.companyId!,
        franchise: franchiseParam,
      },
    },
  })

  const { data: planningData } = useGetPlanningDaysQuery({
    variables: {
      input: {
        company_id: params.companyId,
        franchise_name: franchiseParam ?? "",
      },
    },
  })

  const [upsertPlanningDayMutation] = useUpsertPlanningDayMutation()
  const [deletePlanningDayMutation] = useDeletePlanningDayMutation()
  const [upsertMultiplePlanningDaysMutation] =
    useUpsertMultiplePlanningDaysMutation()

  useEffect(() => {
    if (planningData?.GetPlanningDays?.planning_days) {
      const initialSchedule: Schedule =
        planningData.GetPlanningDays.planning_days.map((planningDay) => ({
          storeId: planningDay.store_id,
          supplierId: planningDay.supplier_id,
          supplierName: planningDay.supplier_name,
          receptionDate: enumValueToDayName(planningDay?.order_day ?? ""),
          arrivalDay: planningDay.delivery_day
            ? enumValueToDayName(planningDay.delivery_day)
            : undefined,
          selected: true,
          numberOfOrders: planningDay.number_of_order ?? 1,
        }))
      setSchedule(initialSchedule)
    }
  }, [planningData])

  const filteredStores =
    data?.getStores?.stores?.filter((store) =>
      store.store_name.toLowerCase().includes(searchTerm.toLowerCase()),
    ) ?? []

  const suppliers =
    data?.getStores?.suppliers?.map((supplier) => ({
      supplierName: supplier.supplier_name,
      supplierId: supplier.id,
    })) ?? []

  const handleCheckboxChange = async (
    supplierId: string,
    storeId: string,
    day: string,
    numberOfOrders: number,
  ) => {
    const existingEntryIndex = schedule.findIndex(
      (entry) =>
        entry.supplierId === supplierId &&
        entry.storeId === storeId &&
        entry.receptionDate === day &&
        entry.numberOfOrders === numberOfOrders,
    )

    if (existingEntryIndex !== -1) {
      // Entry exists, so user is unchecking the checkbox
      const entryToRemove = schedule[existingEntryIndex]

      // Optimistically update the state
      setSchedule((prev) => prev.filter((_, idx) => idx !== existingEntryIndex))

      try {
        // Send delete request to backend
        const result = await deletePlanningDayMutation({
          variables: {
            input: {
              store_id: storeId,
              supplier_id: supplierId,
              order_day: dayNameToEnumValue(day) as OrderDay,
              number_of_order: numberOfOrders,
            },
          },
        })

        if (result.data?.deletePlanningDay.success === false) {
          throw new Error(
            "Une erreur s'est produite lors de la suppression du jour.",
          )
        }

        // Show success toast
        toast("Jour supprimé", {
          description: `Le jour ${day} a été supprimé pour le fournisseur ${entryToRemove.supplierName}, commande n°${numberOfOrders}.`,
        })
      } catch (error) {
        // Revert state if there's an error
        setSchedule((prev) => [...prev, entryToRemove])

        // Show error toast
        toast.error("Erreur", {
          description:
            "Une erreur s'est produite lors de la suppression du jour.",
        })
      }
    }
    // Do nothing if the entry doesn't exist (checkbox should be disabled)
  }

  const handleSetArrivalDay = async (
    supplierId: string,
    storeId: string,
    orderDay: string,
    arrivalDay: string,
    numberOfOrders: number,
  ) => {
    if (!supplierId) {
      toast.error("Attention", {
        description: "Oups ! Vous devez d'abord sélectionner un fournisseur.",
      })
      return
    }

    const existingEntryIndex = schedule.findIndex(
      (entry) =>
        entry.supplierId === supplierId &&
        entry.storeId === storeId &&
        entry.receptionDate === orderDay &&
        entry.numberOfOrders === numberOfOrders,
    )

    let updatedSchedule
    if (existingEntryIndex !== -1) {
      // Entry exists, update the arrival day
      const updatedEntry = {
        ...schedule[existingEntryIndex],
        arrivalDay,
      }
      updatedSchedule = [...schedule]
      updatedSchedule[existingEntryIndex] = updatedEntry
    } else {
      // Entry doesn't exist, create a new one
      const supplierName =
        suppliers.find((s) => s.supplierId === supplierId)?.supplierName ?? ""

      const newEntry: ScheduleEntry = {
        supplierId,
        storeId,
        receptionDate: orderDay,
        selected: true,
        numberOfOrders,
        supplierName,
        arrivalDay,
      }
      updatedSchedule = [...schedule, newEntry]
    }

    // Optimistically update the state
    setSchedule(updatedSchedule)

    try {
      // Upsert in backend
      const result = await upsertPlanningDayMutation({
        variables: {
          input: {
            store_id: storeId,
            supplier_id: supplierId,
            order_day: dayNameToEnumValue(orderDay) as OrderDay,
            delivery_day: dayNameToEnumValue(arrivalDay) as OrderDay,
            franco: 0,
            number_of_order: numberOfOrders,
          },
        },
      })

      if (result.data?.upsertPlanningDay.success === false) {
        throw new Error("Une erreur s'est produite lors de l'ajout du jour.")
      }

      // Show success toast
      toast("Jour sélectionné", {
        description: `Le jour ${arrivalDay} a été choisi comme jour d'arrivée pour la commande n°${numberOfOrders}.`,
      })

      // Close the Popover
      setOpenPopovers((prev) => ({
        ...prev,
        [getPopoverKey(storeId, orderDay, numberOfOrders)]: false,
      }))
    } catch (error) {
      // Revert state if there's an error
      setSchedule((prev) =>
        prev.filter(
          (entry) =>
            !(
              entry.supplierId === supplierId &&
              entry.storeId === storeId &&
              entry.receptionDate === orderDay &&
              entry.numberOfOrders === numberOfOrders
            ),
        ),
      )

      // Show error toast
      toast.error("Erreur", {
        description: "Une erreur s'est produite lors de l'ajout du jour.",
      })
    }
  }

  const handleBulkUpsert = async (
    orders: { orderDay: string; deliveryDay: string; numberOfOrder: number }[],
  ) => {
    if (!selectedSupplier) {
      toast.error("Attention", {
        description: "Oups ! Vous devez d'abord sélectionner un fournisseur.",
      })
      return
    }

    // Validate that no two orders have the same orderDay and numberOfOrder
    const uniqueChecks = new Set<string>()
    for (const [index, order] of orders.entries()) {
      if (order.orderDay === order.deliveryDay) {
        toast.error("Attention", {
          description: `Le jour de commande et le jour de livraison ne peuvent pas être identiques pour la commande n°${index + 1}.`,
        })
        return
      }

      const key = `${order.orderDay}-${order.numberOfOrder}`
      if (uniqueChecks.has(key)) {
        toast.error("Attention", {
          description: `Les numéros de commande doivent être uniques par jour de commande. Conflit trouvé pour la commande n°${index + 1}.`,
        })
        return
      }
      uniqueChecks.add(key)
    }

    // Collect all store IDs
    const allStoreIds = filteredStores.map((store) => store.id) || []

    if (allStoreIds.length === 0) {
      toast.error("Aucun magasin trouvé", {
        description: "Il n'y a aucun magasin disponible pour cette opération.",
      })
      return
    }

    try {
      // Iterate over each order configuration
      for (const order of orders) {
        const { orderDay, deliveryDay, numberOfOrder } = order

        const result = await upsertMultiplePlanningDaysMutation({
          variables: {
            input: {
              supplier_id: selectedSupplier,
              order_day: dayNameToEnumValue(orderDay) as OrderDay,
              delivery_day: dayNameToEnumValue(deliveryDay) as OrderDay,
              store_ids: allStoreIds,
              number_of_order: numberOfOrder,
            },
          },
        })

        if (
          result.data?.upsertMultiplePlanningDays.results.some(
            (result) => result.success === false,
          )
        ) {
          throw new Error("Une erreur s'est produite lors de l'ajout du jour.")
        }

        const updatedEntries = allStoreIds.map((storeId) => ({
          storeId,
          supplierId: selectedSupplier,
          supplierName:
            suppliers.find((s) => s.supplierId === selectedSupplier)
              ?.supplierName ?? "",
          receptionDate: orderDay,
          arrivalDay: deliveryDay,
          selected: true,
          numberOfOrders: numberOfOrder,
        }))

        setSchedule((prevSchedule) => {
          const filteredSchedule = prevSchedule.filter(
            (entry) =>
              !(
                entry.receptionDate === orderDay &&
                entry.numberOfOrders === numberOfOrder &&
                entry.supplierId === selectedSupplier
              ),
          )
          // Add the updated entries
          return [...filteredSchedule, ...updatedEntries]
        })
      }

      // Show success toast
      toast("Bravo", {
        description: "Le planning a été mis à jour sur tous les magasins.",
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        toast.error("Erreur", {
          description:
            error.message ||
            "Une erreur s'est produite lors de l'ajout du jour.",
        })
      } else {
        toast.error("Erreur", {
          description: "Une erreur inattendue s'est produite.",
        })
      }
    }
  }

  const handleAddOrderNumber = (storeId: string, day: string) => {
    const existingOrders = schedule.filter(
      (entry) =>
        entry.storeId === storeId &&
        entry.receptionDate === day &&
        entry.supplierId === selectedSupplier,
    ).length
    const nextOrderNumber = existingOrders + 1
    setSelectedOrderNumber((prev) => ({
      ...prev,
      [`${storeId}-${day}`]: nextOrderNumber,
    }))
    setOpenPopovers((prev) => ({
      ...prev,
      [`${storeId}-${day}-add-order`]: true,
    }))
  }

  return (
    <div className="flex flex-col h-screen w-full overflow-hidden p-3">
      {/* Supplier Selection Buttons */}
      <SupplierSelection
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        onSelect={(supplierId) => {
          setSelectedSupplier((prev) => (prev === supplierId ? "" : supplierId))
        }}
      />

      {/* Bulk Upsert Planning Days Dialog */}
      <BulkUpsertPlanner
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        days={days}
        onApply={handleBulkUpsert}
      />

      {/* Search Input */}
      <div className="mb-4">
        <Input
          placeholder="Rechercher"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Stores Table */}
      <StoresTable
        stores={filteredStores}
        days={days}
        schedule={schedule}
        selectedSupplier={selectedSupplier}
        suppliers={suppliers}
        onCheckboxChange={handleCheckboxChange}
        onSetArrivalDay={handleSetArrivalDay}
        onAddOrder={handleAddOrderNumber}
        openPopovers={openPopovers}
        setOpenPopovers={setOpenPopovers}
        getPopoverKey={getPopoverKey}
        selectedOrderNumber={selectedOrderNumber}
        setSelectedOrderNumber={setSelectedOrderNumber}
      />
    </div>
  )
}
