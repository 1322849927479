import Header from "@/components/header"
import { Outlet } from "react-router-dom"

export function Admin(): JSX.Element {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex-1 w-full overflow-auto pt-2 pb-8">
        <Outlet />
      </div>
    </div>
  )
}
