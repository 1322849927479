import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ProductCard } from "./ProductCard"
import { ProductAlertSection } from "./ProductAlertSection"

interface ExcessiveProductsProps {
  excessiveProductIds: string[] | undefined
  excessiveProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
  companyName: string | null
  franchise: string | null
}

export const ExcessiveProducts = ({
  excessiveProductIds,
  excessiveProducts,
  allItems,
  companyName,
  franchise,
}: ExcessiveProductsProps) => {
  return (
    <ProductAlertSection
      title={`Modifications à la hausse : ${excessiveProducts.length}`}
      message={
        excessiveProducts.length > 0
          ? "Attention ces quantités risquent d'augmenter la démarque"
          : "Toutes les quantités sont correctes"
      }
      isAllFixed={excessiveProducts.length === 0}
    >
      {(excessiveProductIds ?? []).map((productId, index) => {
        const product = allItems.find(
          (item) => item.mercuriale_id === productId,
        )
        if (!product) return null

        const isFix = excessiveProducts.includes(product)

        return (
          <ProductCard
            key={index}
            product={product}
            isFix={isFix}
            companyName={companyName}
            franchise={franchise}
          />
        )
      })}
    </ProductAlertSection>
  )
}
