import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { Table, TableCell, TableRow } from "../ui/table"

export const NotAvailableProductsBlock = ({
  notAvailableProducts,
}: {
  notAvailableProducts: AllMercurialInfo[]
}) => {
  return (
    <div>
      <div className="px-4">
        <div className="flex items-center gap-1">
          <p className="text-md font-bold">Ruptures fournisseur</p>
          <p className="text-s text-neutral-500">
            ({notAvailableProducts.length})
          </p>
        </div>
        <div className="overflow-y-auto max-h-[150px]">
          <Table>
            {notAvailableProducts.map((product, index) => (
              <TableRow
                className="flex flex-col px-2 py-1"
                key={product.mercuriale_id}
              >
                <TableCell className="p-0">
                  <p>{product.mercuriale_name}</p>
                </TableCell>
                <TableCell
                  className={`p-0 ${
                    index === notAvailableProducts.length - 1 && "border-b-none"
                  }`}
                >
                  <p>{product.supplier_name}</p>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </div>
    </div>
  )
}
