"use client"
import {
  ColumnDef,
  flexRender,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getExpandedRowModel,
  useReactTable,
  getPaginationRowModel,
  VisibilityState,
} from "@tanstack/react-table"
import { Checkbox } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useRef, useState, useEffect, useMemo } from "react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import DataTableSkeleton from "./DataTableSkeleton"
import {
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Filter,
  Search,
} from "lucide-react"
import { ReferenceWithRegroupingIdaRecords } from "@/utils/__generated__/graphql"

interface MatchedDataTableProps {
  columns: ColumnDef<ReferenceWithRegroupingIdaRecords, unknown>[]
  data: ReferenceWithRegroupingIdaRecords[]
  loading: boolean
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
}

export function MatchedDataTable({
  columns,
  data,
  loading,
  rowSelection,
  setRowSelection,
}: MatchedDataTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [showOnlyMatched, setShowOnlyMatched] = useState(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    store_id: false,
    sale_id: false,
  })
  const [grouping, setGrouping] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 250,
  })

  const filteredData = useMemo(() => {
    if (showOnlyMatched) {
      return data.filter((record) => record.is_matched)
    }
    return data
  }, [data, showOnlyMatched])

  const table = useReactTable({
    data: filteredData,
    columns,
    getRowId: (row: ReferenceWithRegroupingIdaRecords) =>
      row.sale_name_ida_base,
    onGroupingChange: setGrouping,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    rowCount: data.length,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      grouping,
      pagination,
    },
  })

  const scrollableDivRef = useRef<HTMLDivElement>(null)
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const scrollTop = scrollableDivRef.current.scrollTop
        setShowScrollToTop(scrollTop > 300)
      }
    }

    const scrollableDiv = scrollableDivRef.current
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const totalPages = table.getPageCount()
  const currentPage = table.getState().pagination.pageIndex + 1

  if (loading) {
    return <DataTableSkeleton rowCount={5} />
  }

  return (
    <div className="relative flex flex-col h-full overflow-hidden">
      {/* Top bar for searching and filtering */}
      <div className="flex items-center gap-2 px-2 py-3 border-b border-gray-200 bg-gray-50">
        <div className="relative">
          <Search className="h-4 w-4 text-muted-foreground absolute left-3 top-1/2 -translate-y-1/2" />
          <Input
            placeholder="Libellé Vente..."
            value={
              (table.getColumn("libelle_base")?.getFilterValue() as string) ??
              ""
            }
            onChange={(event) =>
              table
                .getColumn("libelle_base")
                ?.setFilterValue(event.target.value)
            }
            className="pl-9 max-w-sm"
          />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center space-x-2"
            >
              <Filter className="h-4 w-4" />
              <span>Filtrer les colonnes</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="show-only-matched"
            checked={showOnlyMatched}
            onCheckedChange={(checked) => setShowOnlyMatched(!!checked)}
          />
          <Label className="text-sm" htmlFor="show-only-matched">
            Afficher uniquement les références matchées
          </Label>
        </div>
      </div>

      {/* Scrollable Table Area */}
      <div className="relative flex-1 overflow-hidden">
        <div
          ref={scrollableDivRef}
          className="relative w-full h-full overflow-y-auto"
        >
          <Table className="min-w-full">
            <TableHeader className="sticky top-0 bg-white z-10 shadow-sm">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  className="[&>th]:whitespace-nowrap"
                >
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id} className="bg-gray-50">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => {
                  const isSelected = row.getIsSelected()
                  return (
                    <TableRow
                      key={row.id}
                      data-state={isSelected && "selected"}
                      className="[&[data-state=selected]]:bg-blue-50 hover:bg-gray-100 transition-colors cursor-pointer"
                      onClick={() => row.toggleSelected(!isSelected)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const isCheckboxCell = cell.column.id === "selecteur"
                        return (
                          <TableCell
                            key={cell.id}
                            className="align-middle"
                            onClick={(e) =>
                              isCheckboxCell && e.stopPropagation()
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center text-gray-500"
                  >
                    Aucun résultat.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {showScrollToTop && (
          <Button
            onClick={scrollToTop}
            className="absolute bottom-4 right-4 p-4 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 focus:outline-none border border-gray-600 z-50"
            aria-label="Retour en haut"
          >
            <ArrowUp className="h-5 w-5" />
          </Button>
        )}
      </div>

      {/* Pagination and info */}
      <div className="text-sm text-muted-foreground mt-2 px-2 pb-2 border-t border-gray-200 bg-gray-50">
        <div className="flex items-center space-x-2">
          <span className="text-sm">
            Page {currentPage} sur {totalPages}
          </span>
          <span className="text-sm">
            | Total : {table.getFilteredRowModel().rows.length} lignes
          </span>
          <div className="ml-auto flex items-center space-x-1">
            <Button
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              variant="ghost"
              size="sm"
              className="px-2"
              aria-label="Première page"
            >
              <ChevronsLeft size={16} />
            </Button>

            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              variant="ghost"
              size="sm"
              className="px-2"
              aria-label="Page précédente"
            >
              <ChevronLeft size={16} />
            </Button>

            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              variant="ghost"
              size="sm"
              className="px-2"
              aria-label="Page suivante"
            >
              <ChevronRight size={16} />
            </Button>

            <Button
              onClick={() => table.setPageIndex(totalPages - 1)}
              disabled={!table.getCanNextPage()}
              variant="ghost"
              size="sm"
              className="px-2"
              aria-label="Dernière page"
            >
              <ChevronsRight size={16} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
