interface FeaturesFlags {
  promotions: boolean
  newReferences: boolean
  toVerify: boolean
  top5HighestBreakage: boolean
  usedToBePromo: boolean
  shelfFloorSizeOverexposure: boolean
  shelfFloorSizeUnderExposure: boolean
  paChanged: boolean
}

export const checkInFeaturesFlags: Record<string, FeaturesFlags> = {
  carrefour: {
    promotions: false,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  provencia: {
    promotions: false,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  intermarche: {
    promotions: false,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: true,
  },
  systemeu: {
    promotions: false,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  naturalia: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  marceletfils: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  auchan: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  schiever: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  biomonde: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  ktg: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
  default: {
    promotions: true,
    newReferences: true,
    toVerify: true,
    top5HighestBreakage: true,
    usedToBePromo: true,
    shelfFloorSizeOverexposure: true,
    shelfFloorSizeUnderExposure: true,
    paChanged: false,
  },
}

export function getFeaturesFlags(
  companyName: string | null | undefined,
  franchise: string | null | undefined,
): FeaturesFlags | undefined {
  const key = franchise ?? companyName
  if (typeof key !== "string") return undefined

  if (checkInFeaturesFlags[key] === undefined) {
    return checkInFeaturesFlags.default
  }

  return checkInFeaturesFlags[key]
}

export function hasFeatureFlag(
  companyName: string | null | undefined,
  franchise: string | null | undefined,
  flag: keyof FeaturesFlags,
): boolean {
  const featuresFlags = getFeaturesFlags(companyName, franchise)
  if (featuresFlags === undefined) return false

  return featuresFlags[flag]
}
