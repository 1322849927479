import { ArrowDown, ArrowUp, Check } from "lucide-react"
import { ReactNode } from "react"
import { twJoin } from "tailwind-merge"

function formatCurrency(
  amount: number | null | undefined,
  currency: string | null | undefined,
) {
  return new Intl.NumberFormat("fr-FR", {
    notation: "compact",
    style: "currency",
    currency: currency ?? "EUR",
    maximumFractionDigits: (amount ?? 0) > 1000 ? 2 : 0,
  }).format(amount ?? 0)
}

interface LastWeeksPerformancesProps {
  title: string
  description: string
  oneWeekAgo: number
  oneWeekAgoPercent?: number
  twoWeeksAgo: number
  twoWeeksAgoPercent?: number
  oneYearAgo: number
  oneYearAgoPercent?: number
  currency: string
  checked?: boolean
  onCheckedChange?: (value: boolean) => void
  invertColors?: boolean
  icon: ReactNode
}

export function LastWeeksPerformances({
  title,
  description,
  oneWeekAgo,
  oneWeekAgoPercent,
  twoWeeksAgo,
  twoWeeksAgoPercent,
  oneYearAgo,
  currency,
  checked,
  onCheckedChange,
  invertColors = false,
  icon,
}: LastWeeksPerformancesProps) {
  const performancesDelta = oneWeekAgo - twoWeeksAgo

  return (
    <div
      className={twJoin(
        "bg-white rounded p-4 flex flex-col gap-4",
        checked && "ring-2 ring-black",
      )}
      onClick={() => onCheckedChange?.(!checked)} // Appelle uniquement si `onCheckedChange` existe
    >
      <div className="flex items-center gap-2">
        {icon}
        <p className="font-bold">{title}</p>
      </div>
      <p className="font-bold">
        {description} est en{" "}
        <span
          className={twJoin(
            invertColors
              ? performancesDelta < 0
                ? "text-red-700"
                : "text-green-700"
              : performancesDelta < 0
                ? "text-green-700"
                : "text-red-700",
          )}
        >
          {performancesDelta < 0 ? "baisse" : "hausse"}
        </span>
        .
      </p>
      <div className="w-full flex items-center">
        {checked !== undefined && onCheckedChange && (
          <div
            className={twJoin(
              "mr-4 size-5 border border-black rounded-sm flex items-center justify-center text-white",
              checked && "bg-black",
            )}
          >
            <Check className="size-4" />
          </div>
        )}
        <div className="flex-1 flex flex-col md:flex-row">
          <div className="flex-1 p-4 rounded-tl rounded-tr md:rounded-tr-none md:rounded-l bg-gray-200">
            <p className="text-2xl font-bold flex justify-center items-center gap-2">
              {formatCurrency(twoWeeksAgo, currency)}
              {twoWeeksAgoPercent && (
                <span className="text-sm text-gray-600">
                  (
                  {new Intl.NumberFormat("fr-FR", {
                    style: "percent",
                  }).format(twoWeeksAgoPercent ?? 0)}
                  )
                </span>
              )}
            </p>
            <p className="text-center text-sm">il y a deux semaines</p>
          </div>
          <div
            className={twJoin(
              "flex-1 p-4 rounded-bl rounded-br md:rounded-bl-none md:rounded-r bg-gradient-to-tr text-white",
              invertColors
                ? performancesDelta < 0
                  ? "from-[#FF4755] to-[#D70818]"
                  : "from-green-300 to-green-900"
                : performancesDelta < 0
                  ? "from-green-300 to-green-900"
                  : "from-[#FF4755] to-[#D70818]",
            )}
          >
            <div className="flex justify-center items-center gap-1">
              <p className="text-2xl font-bold flex justify-center items-center gap-2">
                {formatCurrency(oneWeekAgo, currency)}
                {twoWeeksAgoPercent && (
                  <span className="text-sm text-white">
                    (
                    {new Intl.NumberFormat("fr-FR", {
                      style: "percent",
                    }).format(oneWeekAgoPercent ?? 0)}
                    )
                  </span>
                )}
              </p>
              {performancesDelta < 0 ? (
                <ArrowDown className="size-6" />
              ) : (
                <ArrowUp className="size-6" />
              )}
              <p className="text-sm">
                {performancesDelta >= 0 && "+"}
                {formatCurrency(performancesDelta, currency)}
              </p>
            </div>
            <p className="text-center text-sm">il y a une semaine</p>
          </div>
        </div>
      </div>
      {(oneYearAgo ?? 0) > 0 && (
        <p>
          <span className="font-bold">
            {formatCurrency(oneYearAgo, currency)}
          </span>{" "}
          la même semaine, l&apos;an dernier.
        </p>
      )}
    </div>
  )
}
