// src/components/inventories/TopUnorderedReferencesGlobalChart.tsx

"use client"

import React, { useState, useEffect, useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@/components/ui/table"
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
} from "recharts"
import { toast } from "sonner"

import { Store } from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"

import { useGetTopUnorderedReferencesGlobalQuery } from "@/utils/__generated__/graphql"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { add } from "date-fns"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { Spinner } from "@/ui/Spinner"

interface TopUnorderedReferencesGlobalChartProps {
  dateRange: {
    from: Date
    to: Date
  }
  stores: Store[]
}

export function TopUnorderedReferencesGlobalChart({
  dateRange,
}: TopUnorderedReferencesGlobalChartProps) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const [startRankInput, setStartRankInput] = useState<number>(1)
  const [endRankInput, setEndRankInput] = useState<number>(10)
  const [ruptureThresholdInput, setRuptureThresholdInput] = useState<number>(7)
  const [startRank, setStartRank] = useState<number>(1)
  const [endRank, setEndRank] = useState<number>(10)
  const [ruptureThreshold, setRuptureThreshold] = useState<number>(7)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate
  const totalDays = useMemo(() => {
    const start = new Date(fromDate)
    const end = new Date(toDate)
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1
  }, [fromDate, toDate])

  const { data, loading, error, refetch } =
    useGetTopUnorderedReferencesGlobalQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          rupture_threshold_days: ruptureThreshold,
          start_rank: startRank,
          store_id: storeId!,
          end_rank: endRank,
        },
      },
      skip: !companyId || !fromDate || !toDate,
      fetchPolicy: "cache-and-network",
    })

  // **Effect to Refetch Data When Query Variables Change**
  useEffect(() => {
    if (companyId && franchiseParam && fromDate && toDate) {
      refetch({
        input: {
          company_id: companyId,
          franchise_name: franchiseParam,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          rupture_threshold_days: ruptureThreshold,
          start_rank: startRank,
          end_rank: endRank,
          store_id: storeId!,
        },
      })
    }
  }, [
    startRank,
    endRank,
    ruptureThreshold,
    franchiseParam,
    companyId,
    storeId,
    fromDate,
    toDate,
    refetch,
  ])

  const references = useMemo(() => {
    if (!data?.getTopUnorderedReferencesGlobal.references) return []
    return data.getTopUnorderedReferencesGlobal.references.map((ref) => ({
      saleName: ref.sale_name,
      TotalSales: ref.total_sales,
      OrderDays: ref.order_days,
      NotOrderedDays: totalDays - ref.order_days,
      TotalOrderedQuantity: ref.total_ordered_quantity,
      store_name: ref.store_name,
      last_order_date: Intl.DateTimeFormat("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
      }).format(new Date(ref.last_order_date)),
    }))
  }, [data, totalDays])
  console.log(references, "references")

  const plotData = useMemo(() => {
    if (!data?.getTopUnorderedReferencesGlobal.references) return []

    const storeMap: Record<string, number> = {}

    data.getTopUnorderedReferencesGlobal.references.forEach((ref) => {
      const storeName = ref.store_name
      if (storeMap[storeName]) {
        storeMap[storeName] += 1
      } else {
        storeMap[storeName] = 1
      }
    })

    const sortedStores = Object.entries(storeMap)
      .map(([store_name, reference_count]) => ({
        store_name,
        reference_count,
      }))
      .sort((a, b) => b.reference_count - a.reference_count)

    return sortedStores
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des données.")
    }
  }, [error])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Validation: startRank <= endRank and endRank <= 20
    if (startRankInput > endRankInput) {
      toast.error(
        "Le nombre de références de départ doit être inférieur ou égal au nombre de références de fin.",
      )
      return
    }
    if (ruptureThresholdInput < 1) {
      toast.error("Le seuil de rupture doit être au moins de 1 jour.")
      return
    }

    setStartRank(startRankInput)
    setEndRank(endRankInput)
    setRuptureThreshold(ruptureThresholdInput)
  }

  const handleStartRankChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    setStartRankInput(value)
  }

  const handleEndRankChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    setEndRankInput(value)
  }

  const handleRuptureThresholdChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(e.target.value, 10)
    setRuptureThresholdInput(value)
  }

  return (
    <div className="h-full flex-1 p-1 flex flex-col overflow-y-hidden">
      {/* **Form for Rank, Threshold, and Date Selection** */}
      <Card className="mb-6 py-2 w-fit">
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex items-center space-x-4">
              <div>
                <Label
                  htmlFor="start-rank"
                  className="block text-sm font-medium text-gray-700"
                >
                  Rang de Départ
                </Label>
                <Input
                  id="start-rank"
                  type="number"
                  min={1}
                  max={499}
                  value={startRankInput}
                  onChange={handleStartRankChange}
                  className="mt-1 block w-full"
                  required
                />
              </div>
              <div>
                <Label
                  htmlFor="end-rank"
                  className="block text-sm font-medium text-gray-700"
                >
                  Rang de Fin
                </Label>
                <Input
                  id="end-rank"
                  type="number"
                  min={1}
                  max={500}
                  value={endRankInput}
                  onChange={handleEndRankChange}
                  className="mt-1 block w-full"
                  required
                />
              </div>
              <div>
                <Label
                  htmlFor="rupture-threshold"
                  className="block text-sm font-medium text-gray-700"
                >
                  Seuil de Rupture (jours)
                </Label>
                <Input
                  id="rupture-threshold"
                  type="number"
                  min={1}
                  value={ruptureThresholdInput}
                  onChange={handleRuptureThresholdChange}
                  className="mt-1 block w-full"
                  required
                />
              </div>
              <button
                type="submit"
                className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
                  startRankInput > endRankInput || ruptureThresholdInput < 1
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                }`}
              >
                Appliquer
              </button>
            </div>
          </form>
        </CardContent>
      </Card>
      {loading && (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      )}

      {!loading && references.length === 0 && (
        <div className="space-y-8">
          <Card className="w-full max-w-6xl mx-auto">
            <CardHeader>
              <CardTitle>Références Non Commandées Globales</CardTitle>
            </CardHeader>
            <CardContent>
              Aucune référence en potentiel rupture trouvée.
            </CardContent>
          </Card>
        </div>
      )}

      {!loading && references.length > 0 && (
        <div className="flex overflow-y-auto gap-1">
          <Card className="flex-1 overflow-y-auto">
            <CardHeader>
              <CardTitle>Top Références en Potentielles Ruptures</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader className="sticky top-0 bg-gray-100">
                  <TableRow>
                    <TableHead>Rang</TableHead>
                    <TableHead>Nom de Référence</TableHead>
                    <TableHead>Ventes Totales</TableHead>
                    <TableHead>Nombre de Commandes</TableHead>
                    <TableHead>Quantité Commandée Totale</TableHead>
                    <TableHead>Date de la Dernière Commande</TableHead>
                    <TableHead>Nom du Magasin</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {references.map((ref, index) => (
                    <TableRow key={ref.saleName}>
                      <TableCell>{startRank + index}</TableCell>
                      <TableCell>{ref.saleName}</TableCell>
                      <TableCell>{ref.TotalSales}</TableCell>
                      <TableCell>{ref.OrderDays}</TableCell>
                      <TableCell>{ref.TotalOrderedQuantity}</TableCell>
                      <TableCell>{ref.last_order_date}</TableCell>
                      <TableCell>{ref.store_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className="space-y-4 w-1/3 h-full overflow-y-auto">
            <CardHeader>
              <CardTitle>
                Magasins avec le plus de top ref en potentielles ruptures
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={plotData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
                  aria-label="Magasins avec le plus de top ref en potentielles ruptures"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="store_name"
                    angle={-45}
                    textAnchor="end"
                    interval={0}
                    height={150}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    label={{
                      value: "Nombre de Références Non Commandées",
                      angle: -90,
                      position: "insideLeft",
                      offset: -5,
                      fill: "black",
                    }}
                    allowDecimals={false}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="reference_count"
                    fill="#ff7300"
                    name="Références Non Commandées"
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  )
}
