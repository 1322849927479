import { getQuarterValue } from "@/utils/getQuarterValue"
import { GetTotalStocksParams, getTotalStocks } from "./getTotalStocks"

interface TotalStockProps extends GetTotalStocksParams {
  displayZeroBeforeQuarter?: boolean
}

/**
 * Display the total stock quantity for the feature "Stock total" in the inventory.
 * @example
 * <TotalStock backInventoryQty={10.5} floorInventoryQty={20} isToVerify={false} />
 * // 30 1/2
 * <TotalStock backInventoryQty={10.5} floorInventoryQty={20} isToVerify={true} />
 * // 20
 * <TotalStock backInventoryQty={0.25} floorInventoryQty={0} />
 * // 1/4
 * <TotalStock backInventoryQty={0.25} floorInventoryQty={0} displayZeroBeforeQuarter />
 * // 0 1/4
 */
export function TotalStock({
  backInventoryQty,
  floorInventoryQty,
  isToVerify,
  displayZeroBeforeQuarter = false,
}: TotalStockProps) {
  const totalInventoryQuantity = getTotalStocks({
    backInventoryQty,
    floorInventoryQty,
    isToVerify,
  })
  const totalInventoryQuantityQuarter = getQuarterValue(
    totalInventoryQuantity,
    "size-4",
  )

  const displayZero =
    totalInventoryQuantity === 0 ||
    (totalInventoryQuantityQuarter.value ?? 0) >= 1 ||
    displayZeroBeforeQuarter

  return (
    <>
      {displayZero && totalInventoryQuantityQuarter.value}
      {totalInventoryQuantityQuarter.icon}
    </>
  )
}
