// src/components/DataTable.tsx

"use client"
import {
  ColumnDef,
  flexRender,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getExpandedRowModel,
  useReactTable,
  VisibilityState,
  getPaginationRowModel,
} from "@tanstack/react-table"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useRef, useState, useEffect } from "react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import DataTableSkeleton from "./DataTableSkeleton"
import { ArrowUp } from "lucide-react"

interface MatchedDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  loading: boolean
}

export function UnMatchedDataTable<TData, TValue>({
  columns,
  data,
  loading,
}: MatchedDataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    store_id: false,
    sale_id: false,
  })
  const [grouping, setGrouping] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 250,
  })
  const table = useReactTable({
    data,
    columns,
    onGroupingChange: setGrouping,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      grouping,
      pagination,
    },
  })
  console.log("data", data)

  const scrollableDivRef = useRef<HTMLDivElement>(null)
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const scrollTop = scrollableDivRef.current.scrollTop
        setShowScrollToTop(scrollTop > 300)
      }
    }

    const scrollableDiv = scrollableDivRef.current
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  if (loading) {
    return <DataTableSkeleton rowCount={5} />
  }

  return (
    <div className="flex-1 px-2 flex flex-col h-full overflow-y-auto">
      <div className="flex items-center py-2 gap-2">
        <Input
          placeholder="Rechercher par nom de magasin"
          value={
            (table.getColumn("store_name")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("store_name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <Input
          placeholder="Rechercher par nom de référence"
          value={
            (table.getColumn("sale_name")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("sale_name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Filtrer les colonnes
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div
        ref={scrollableDivRef}
        className="rounded-md border overflow-y-auto h-full flex-1"
      >
        <Table>
          <TableHeader className="sticky top-0 bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Aucune résultats.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <p className="text-sm text-muted-foreground">
        {table.getFilteredRowModel().rows.length} lignes
      </p>
      {showScrollToTop && (
        <Button
          onClick={scrollToTop}
          className="absolute bottom-12 right-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 focus:outline-none"
          aria-label="Retour en haut"
        >
          <ArrowUp />
        </Button>
      )}
    </div>
  )
}
