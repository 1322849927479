import { Fragment, useEffect, useState } from "react"
import { Modal } from "@/ui/Modal"
import { useGetUsersQuery, User } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"
import { UserForm } from "@/components/Form/UserForm"
import { SearchIcon } from "@/ui/icons/SearchIcon"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ExternalLink, UserRoundPlus } from "lucide-react"

export function UsersPage(): JSX.Element {
  const [users, setUserList] = useState<User[]>([])
  const { data, loading } = useGetUsersQuery()
  const [searchTerm, setSearchTerm] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  useEffect(() => {
    setUserList(data?.getUsers?.users ?? [])
  }, [data?.getUsers?.users])

  const handleCreateUser = (user: User) => {
    setIsModalOpen(false)
    setUserList((prev) => [...prev, user])
  }

  const filteredUsers = users.filter((user) =>
    Object.values(user).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )
  const setRowGrey = (index: number) =>
    `${index % 2 !== 0 ? "bg-gray-100" : ""}`

  return (
    <div className="p-2">
      <div className="flex gap-2 mb-4">
        <div className="relative w-full">
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            name="search"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <SearchIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
        <Button onClick={() => setIsModalOpen(true)}>
          <UserRoundPlus className="size-4" />
          Créer un utilisateur
        </Button>
        <Button variant="link" asChild>
          <a
            href="https://accounts.auth0.com/teams/team-np52dt0/"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLink className="size-4" />
            Dashboard Auth0
          </a>
        </Button>
        <Modal
          title="Create New User"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="grid gap-4 py-4">
            <UserForm handleFormSubmit={handleCreateUser} />
          </div>
        </Modal>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : (
        <div className="col-span-4 grid grid-cols-[1fr,1fr,1fr,1fr]">
          <div className="col-span-4 grid grid-cols-[1fr,1fr,1fr,1fr] font-bold bg-gray-200 p-2 rounded-t-md">
            <p>Email</p>
            <p>Store ID</p>
            <p>Company Name</p>
            <p>Store Name</p>
          </div>
          {filteredUsers.map((user, index) => (
            <Fragment key={user.email}>
              <p className={setRowGrey(index)}>{user.email}</p>
              <p className={setRowGrey(index)}>{user.store_id}</p>
              <p className={setRowGrey(index)}>{user.company_name}</p>
              <p className={setRowGrey(index)}>{user.store_name}</p>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
