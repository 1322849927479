import { Modal } from "../../../../ui/Modal"
import { InventoryTable } from "../../InventoryTable"
import { useDispatch, useSelector } from "react-redux"
import { selectedMercurialeInfosSelector } from "../../../../selectors/mercurialeSelectors"
import { useMemo, useRef, useState } from "react"
import { InventoryRow } from "../../InventoryRow"
import { DispatchActionType, StateType } from "../../../../types"
import UpdateInventoryPad from "../../UpdateInventoryPad"
import { VirtuosoHandle } from "react-virtuoso"
import { removeDuplicatesValues } from "../../../../utils/removeDuplicates"
import { getDiffBetweenDates } from "../../../../utils/getDiffBetweenDates"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { UsedToBePromoCard } from "./UsedToBePromoCard"
import { ProgressBar } from "../ProgressBar"
import { OverAndUnderExposureCard } from "./overAndUnderExposure"
import { PaChangedCard } from "./PaChangedCard"
import { getVariation } from "@/utils/getVariation"
import { hasFeatureFlag } from "./checkInFeaturesFlags"

interface CheckInModalProps {
  updateInventory: (value: string) => Promise<void>
  isLoading: boolean
  isValidOrderDate: boolean
}

export function CheckInModal({
  updateInventory,
  isLoading,
  isValidOrderDate,
}: CheckInModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const mercurialeInfos = useSelector(selectedMercurialeInfosSelector)
  const selectedDimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const checkInModalCloseDate = useSelector((state: StateType) =>
    selectedDimMercurialeId !== undefined
      ? state.mercurialReducer.checkInModalCloseDates[selectedDimMercurialeId]
      : undefined,
  )
  const {
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
    storeFranchise,
  } = useSelector((state: StateType) => state.storeReducer)
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.inventoryPage.displayShelfFloorSize,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [scrollPosition, setScrollPosition] = useState(0)
  const [isEndReached, setIsEndReached] = useState(false)

  const referencesToVerify = useRef<string[]>()

  const promotionReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "promotions")) {
      return []
    }
    return mercurialeInfos.filter(
      (mercurialeInfo) =>
        mercurialeInfo.promotion === true &&
        referencesToVerify.current?.includes(
          mercurialeInfo.mercuriale_id ?? "",
        ) !== true,
    )
  }, [companyName, mercurialeInfos, storeFranchise])

  const toVerifyReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "toVerify")) {
      return []
    }
    if (referencesToVerify.current !== undefined) {
      return referencesToVerify.current
        .map((mercurialeId) =>
          mercurialeInfos.find(
            (mercurialeInfo) => mercurialeInfo.mercuriale_id === mercurialeId,
          ),
        )
        .filter((value): value is AllMercurialInfo => value !== undefined)
    }
    const filteredMercurialeInfos = mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.stock_to_verify_flag === true,
    )
    // Store the references to verify in a ref to kept in memory
    referencesToVerify.current = filteredMercurialeInfos
      .map((mercurialeInfo) => mercurialeInfo.mercuriale_id)
      .filter((value): value is string => typeof value === "string")
    return filteredMercurialeInfos
  }, [companyName, mercurialeInfos, storeFranchise])

  const newReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "newReferences")) {
      return []
    }
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.new_reference === true,
    )
  }, [companyName, mercurialeInfos, storeFranchise])

  const shelfFloorSizeOverexposure = useMemo(() => {
    if (
      !hasFeatureFlag(companyName, storeFranchise, "shelfFloorSizeOverexposure")
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "overexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const shelfFloorSizeUnderExposure = useMemo(() => {
    if (
      !hasFeatureFlag(
        companyName,
        storeFranchise,
        "shelfFloorSizeUnderExposure",
      )
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "underexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const top5HighestBreakage = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "top5HighestBreakage")) {
      return []
    }
    return mercurialeInfos
      .filter((mercurialeInfo) => {
        const breakage = mercurialeInfo.breakage_percentage
        return typeof breakage === "number" && breakage > 10 && breakage < 100
      })
      .sort(
        (a, b) => (b.breakage_percentage ?? 0) - (a.breakage_percentage ?? 0),
      )
      .slice(0, 5)
  }, [companyName, mercurialeInfos, storeFranchise])

  const usedToBePromoReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "usedToBePromo")) {
      return []
    }
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.used_to_be_promo === true,
    )
  }, [companyName, mercurialeInfos, storeFranchise])

  const paChangedReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "paChanged")) {
      return []
    }
    const filteredMercurialeInfos = mercurialeInfos.filter((mercurialeInfo) => {
      const pa = mercurialeInfo.pa ?? 0
      const lastPa = mercurialeInfo.last_mercu_pa ?? 0

      if (pa === 0 || lastPa < 0.5) return false

      const variation = getVariation(pa, lastPa)
      const isPaDecreased = variation < 0
      const isEngagementPromo = mercurialeInfo.engagement_promo_flag ?? false

      return isEngagementPromo === false && isPaDecreased
    })

    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
      .sort((a, b) => {
        const aPa = a.pa ?? 0
        const aLastPa = a.last_mercu_pa ?? 0
        const bPa = b.pa ?? 0
        const bLastPa = b.last_mercu_pa ?? 0

        const aVariation = getVariation(aPa, aLastPa)
        const bVariation = getVariation(bPa, bLastPa)

        return aVariation - bVariation
      })
      .splice(0, 4)
  }, [companyName, mercurialeInfos, storeFranchise])

  const filteredMercurialeInfos = useMemo(() => {
    return [
      ...promotionReferences,
      ...newReferences,
      ...toVerifyReferences,
      ...top5HighestBreakage,
    ]
  }, [
    promotionReferences,
    newReferences,
    toVerifyReferences,
    top5HighestBreakage,
  ])

  const deduplicatedFilteredMercurialeInfos = useMemo(() => {
    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
  }, [filteredMercurialeInfos])

  const deduplicatedUsedToBePromoReferences = useMemo(() => {
    return removeDuplicatesValues(usedToBePromoReferences, "sale_id")
  }, [usedToBePromoReferences])

  const deduplicatedShelfFloorSizeOverexposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeOverexposure, "sale_id")
  }, [shelfFloorSizeOverexposure])

  const deduplicatedShelfFloorSizeUnderExposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeUnderExposure, "sale_id")
  }, [shelfFloorSizeUnderExposure])

  const hasAtLeastOneRow =
    deduplicatedUsedToBePromoReferences.length > 0 ||
    deduplicatedShelfFloorSizeOverexposure.length > 0 ||
    deduplicatedShelfFloorSizeUnderExposure.length > 0 ||
    paChangedReferences.length > 0

  const totalReferences = useMemo(
    () => [
      ...deduplicatedFilteredMercurialeInfos,
      ...(hasAtLeastOneRow ? [deduplicatedUsedToBePromoReferences[0]] : []),
    ],
    [
      deduplicatedFilteredMercurialeInfos,
      deduplicatedUsedToBePromoReferences,
      hasAtLeastOneRow,
    ],
  )

  const isModalOpen = useMemo(() => {
    if (
      isLoading ||
      !isValidOrderDate ||
      storeSettings?.show_checkin_modal !== true ||
      deduplicatedFilteredMercurialeInfos.length === 0
    ) {
      return false
    }
    if (checkInModalCloseDate === undefined) return true

    if (getDiffBetweenDates(new Date(), new Date(checkInModalCloseDate)) < 0)
      return true

    return false
  }, [
    checkInModalCloseDate,
    deduplicatedFilteredMercurialeInfos.length,
    isLoading,
    isValidOrderDate,
    storeSettings?.show_checkin_modal,
  ])

  return (
    <Modal
      open={isModalOpen}
      border="gradient"
      fullScreen
      className="max-w-full overflow-y-auto bg-gray-300"
    >
      <div className="bg-white border rounded p-4 text-left">
        <h1 className="md:text-2xl font-bold">⚡ Liste des références clés</h1>
        <ProgressBar
          scrollPosition={scrollPosition}
          virtuosoRef={virtuosoRef}
          promotionReferences={promotionReferences}
          newReferences={newReferences}
          toVerifyReferences={toVerifyReferences}
          top5HighestBreakage={top5HighestBreakage}
          hasAtLeastOneRow={hasAtLeastOneRow}
        />
      </div>
      <div className="h-full flex flex-col md:flex-row gap-2">
        <InventoryTable
          ref={virtuosoRef}
          onScroll={(e) => {
            const target = e.target as HTMLElement
            const maxScrollHeight = target.scrollHeight - target.clientHeight
            const position = (target.scrollTop / maxScrollHeight) * 100
            if (position < scrollPosition) return

            setScrollPosition(position)
          }}
          endReached={() => {
            setIsEndReached(true)
            const interval = setInterval(() => {
              setScrollPosition((_scrollPosition) => {
                if (_scrollPosition >= 100) {
                  clearInterval(interval)
                  return 100
                }
                return _scrollPosition + 5
              })
            }, 10)
          }}
          totalCount={totalReferences.length}
          itemContent={(index) => {
            if (index === totalReferences.length - 1 && hasAtLeastOneRow) {
              return (
                <div className="flex flex-col gap-2">
                  {paChangedReferences.length > 0 && (
                    <PaChangedCard
                      references={paChangedReferences}
                      companyId={companyId}
                      storeCurrency={storeCurrency}
                      isTestMode={isTestMode}
                      storeId={storeId!}
                    />
                  )}
                  {deduplicatedUsedToBePromoReferences.length > 0 && (
                    <UsedToBePromoCard
                      references={deduplicatedUsedToBePromoReferences}
                      companyId={companyId}
                      updateInventory={updateInventory}
                      companyName={companyName}
                      franchise={storeFranchise}
                    />
                  )}
                  {deduplicatedShelfFloorSizeOverexposure.length > 0 && (
                    <OverAndUnderExposureCard
                      flag="overexposure"
                      references={deduplicatedShelfFloorSizeOverexposure}
                      companyId={companyId}
                      updateInventory={updateInventory}
                      companyName={companyName}
                      franchise={storeFranchise}
                    />
                  )}
                  {deduplicatedShelfFloorSizeUnderExposure.length > 0 && (
                    <OverAndUnderExposureCard
                      flag="underexposure"
                      references={deduplicatedShelfFloorSizeUnderExposure}
                      companyId={companyId}
                      updateInventory={updateInventory}
                      companyName={companyName}
                      franchise={storeFranchise}
                    />
                  )}
                </div>
              )
            }

            const row = totalReferences[index]
            const references = filteredMercurialeInfos.filter(
              (mercurialeInfo) => mercurialeInfo.sale_id === row.sale_id,
            )

            return (
              <InventoryRow
                key={row.mercuriale_id!}
                index={index}
                storeId={storeId}
                bestSellers={[]}
                selectedInventory={selectedInventory}
                isOnline={isOnline}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={references}
                companyName={companyName}
                franchise={storeFranchise}
              />
            )
          }}
        />
        <div className="w-full md:w-72 flex flex-col gap-2">
          <div className="hidden md:block w-full">
            <UpdateInventoryPad
              isLoading={false}
              updateInventory={updateInventory}
            />
          </div>
          <button
            className="w-full rounded bg-green-900 text-white font-black py-4 disabled:bg-gray-300 transition-colors"
            disabled={!isEndReached}
            onClick={() => {
              dispatch({
                type: "closeCheckInModal",
                payload: {
                  dimMercurialeId: selectedDimMercurialeId!,
                  date: new Date(),
                },
              })
            }}
          >
            SUIVANT
          </button>
        </div>
      </div>
    </Modal>
  )
}
