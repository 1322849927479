import { getDisplayedUnit, getNormalizedUnit } from "@/utils/getUnit"
import { toFixedNumber } from "@/utils/toFixedNumber"
import { twMerge } from "tailwind-merge"

interface EquivalentInventoryProps {
  value: number
  colisage: number
  unit: string
  useKgPce: boolean
  className?: string
  toVerify?: boolean
  companyName: string | null
  franchise: string | null
}

export function EquivalentInventory({
  value,
  colisage,
  unit,
  useKgPce,
  className,
  toVerify = false,
  companyName,
  franchise,
}: EquivalentInventoryProps) {
  const mergedClassName = twMerge("font-bold text-sm", className)
  const computedValue = toFixedNumber(value ?? 0)

  if (value < 0 || toVerify) {
    return <p className={mergedClassName}>-</p>
  }

  const displayValue = useKgPce
    ? toFixedNumber(computedValue / colisage)
    : getNormalizedUnit(unit) === "pce"
      ? Math.round(computedValue * colisage)
      : toFixedNumber(computedValue * colisage)

  const unitLabel = getDisplayedUnit(
    useKgPce ? "cs" : unit,
    companyName,
    franchise,
  )

  return (
    <p className={mergedClassName}>
      {displayValue} {unitLabel}
    </p>
  )
}
