// src/components/GlobalReferencePredictionsPage.tsx
"use client"
import { useParams, useSearchParams } from "react-router-dom"
import { add } from "date-fns"
import { useState, useMemo } from "react"
import {
  useGetWorstReferencesAcrossStoresQuery,
  useGetWorstReferencesGlobalQuery,
} from "@/utils/__generated__/graphql"

import { Store } from "@/utils/__generated__/graphql"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { WorstReferencesList } from "./WorstReferencesList"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { GlobalReferencePredictionsChart } from "./GlobalReferencePredictionsChart"

interface GlobalReferencePredictionsPageProps {
  dateRange: {
    from: Date | undefined
    to: Date | undefined
  }
  stores: Store[]
}

export default function GlobalReferencePredictionsPage({
  dateRange,
}: GlobalReferencePredictionsPageProps) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const [endRank, setEndRank] = useState<number>(10)
  const [startRank, setStartRank] = useState<number>(1)

  const { data: allStoresData, loading: allStoresLoading } =
    useGetWorstReferencesAcrossStoresQuery({
      variables: {
        input: {
          company_id: companyId!,
          store_id: storeId!,
          franchise_name: franchiseParam,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          start_rank: startRank,
          end_rank: endRank,
        },
      },
      skip: !companyId || !fromDate || !toDate,
    })

  const allStoresChartData = useMemo(() => {
    if (!allStoresData?.getWorstReferencesAcrossStores.performances) return []
    return allStoresData.getWorstReferencesAcrossStores.performances.map(
      (perf) => ({
        store_name: perf.store_name,
        sale_name: perf.sale_name,
        taux_de_modif: perf.updated,
        predicted_amount: perf.predicted_amount,
        ordered_amount: perf.ordered_amount,
        sales_amount: perf.sales_amount,
        distance: perf.amount_difference,
      }),
    )
  }, [allStoresData])

  const { data, loading } = useGetWorstReferencesGlobalQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        store_id: storeId!,
        franchise_name: franchiseParam,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        start_rank: startRank,
        end_rank: endRank,
      },
    },
    skip: !companyId || !fromDate || !toDate,
  })

  const chartData = useMemo(() => {
    if (!data?.getWorstReferencesGlobal.global_performances) return []
    return data.getWorstReferencesGlobal.global_performances.map((perf) => ({
      reference: perf.sale_name,
      tauxDeModif: perf.updated,
      predictedAmount: Math.round(perf.predicted_amount * 100) / 100,
      orderedAmount: Math.round(perf.ordered_amount * 100) / 100,
      salesAmount: Math.round(perf.sales_amount * 100) / 100,
      distance: perf.amount_difference,
    }))
  }, [data])

  return (
    <div className="h-full flex-1 p-4 flex flex-col">
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex items-center gap-2">
          <Label htmlFor="start-rank"> Nombres de références : </Label>
          <Input
            id="start-rank"
            type="number"
            min={1}
            value={startRank}
            onChange={(e) => setStartRank(parseInt(e.target.value))}
            className="w-20"
          />
          <Label htmlFor="end-rank">à</Label>
          <Input
            id="end-rank"
            type="number"
            min={1}
            value={endRank}
            onChange={(e) => setEndRank(parseInt(e.target.value))}
            className="w-20"
          />
        </div>
      </div>
      <div className="flex-1 flex gap-2">
        {loading ? (
          <Card className="w-full max-w-4xl mx-auto">
            <CardHeader>
              <CardTitle>Références les moins bien prédites (Global)</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="animate-pulse">
                <div className="h-64 bg-gray-200 rounded"></div>
              </div>
            </CardContent>
          </Card>
        ) : chartData.length > 0 ? (
          <GlobalReferencePredictionsChart data={chartData} />
        ) : (
          <div className="mt-4 text-gray-500">
            Aucune donnée disponible pour les références sélectionnées.
          </div>
        )}

        {/* List Section */}
        {allStoresLoading ? (
          // Skeleton loader for the list
          <Card className="w-1/3">
            <CardHeader>
              <CardTitle>
                Liste des références les moins bien prédites
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4 w-full animate-pulse">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="flex items-center">
                    <div className="ml-4 space-y-1 flex-1">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                    </div>
                    <div className="ml-auto text-right space-y-1">
                      <div className="h-4 bg-gray-200 rounded w-20"></div>
                      <div className="h-3 bg-gray-200 rounded w-24"></div>
                      <div className="h-3 bg-gray-200 rounded w-24"></div>
                      <div className="h-3 bg-gray-200 rounded w-24"></div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        ) : allStoresChartData.length > 0 ? (
          <WorstReferencesList data={allStoresChartData} />
        ) : (
          <div className="mt-4 text-gray-500">
            Aucune donnée disponible pour les références sélectionnées.
          </div>
        )}
      </div>
    </div>
  )
}
