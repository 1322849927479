import React from "react"
import { Skeleton } from "@/components/ui/skeleton"

interface DataTableSkeletonProps {
  rowCount?: number
}

const DataTableSkeleton: React.FC<DataTableSkeletonProps> = ({
  rowCount = 5, // Adjust the number of skeleton rows as needed
}) => {
  const columnWidths = [
    "w-[150px]",
    "w-[200px]",
    "w-[250px]",
    "w-[100px]",
    "w-[100px]",
  ]

  return (
    <div className="animate-pulse px-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columnWidths.map((width, index) => (
              <th
                key={index}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <Skeleton className={`h-4 ${width}`} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: rowCount }).map((_, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-50 cursor-pointer">
              {columnWidths.map((width, cellIndex) => (
                <td key={cellIndex} className="px-6 py-4 whitespace-nowrap">
                  <Skeleton className={`h-4 ${width} bg-gray-300 rounded`} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DataTableSkeleton
