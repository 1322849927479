import { useWindowSize } from "@/hooks/useWindowSize"
import { Timer } from "lucide-react"
import { TotalStock } from "../TotalStock"

const renderBadge = (
  isToVerify = false,
  isTooHigh = false,
  isTooLow = false,
  stockAecouler = false,
) => {
  if (isToVerify) {
    return (
      <p className="text-red-700 md:text-white md:bg-red-700 md:px-1 md:py-1 md:rounded-sm">
        À VÉRIFIER
      </p>
    )
  }
  if (stockAecouler) {
    return (
      <p className="flex items-center gap-1 md:text-black md:bg-orange-100 text-orange-700 md:px-1 md:py-1 md:rounded-sm">
        <Timer className="w-3.5 h-3.5" />
        <span className="font-semibold tracking-wide">À ÉCOULER</span>
      </p>
    )
  }
  if (isTooHigh) {
    return (
      <p className="text-custom-orange md:text-white md:bg-custom-orange md:px-1 md:py-1 md:rounded-sm">
        STOCK ÉLEVÉ
      </p>
    )
  }
  if (isTooLow) {
    return (
      <p className="text-custom-lightOrange md:text-white md:bg-custom-lightOrange md:px-1 md:py-1 md:rounded-sm">
        STOCK FAIBLE
      </p>
    )
  }
  return <></>
}

interface BackInventoryExtraElementProps {
  value: number
  floorInventoryQuantity?: number
  isToVerify?: boolean
  isTooHigh?: boolean
  isTooLow?: boolean
  stockAecouler?: boolean
  unit?: string
}

export function BackInventoryExtraElement({
  value,
  floorInventoryQuantity = 0,
  isToVerify,
  isTooHigh,
  isTooLow,
  stockAecouler,
  unit,
}: BackInventoryExtraElementProps) {
  const { isMD } = useWindowSize()

  return (
    <div className="w-full flex items-center flex-col gap-2">
      <div className="h-[10px] md:h-[20px] text-xxs md:text-xxxs font-bold text-center">
        {renderBadge(isToVerify, isTooHigh, isTooLow, stockAecouler)}
      </div>
      {!isMD && (
        <p className="w-fit bg-gray-100 border px-2 py-1 rounded text-xxs text-gray-400 text-center flex flex-col gap-1">
          Stock total
          <span className="ml-1 text-xs text-gray-900 font-bold flex justify-center items-center">
            <TotalStock
              backInventoryQty={value}
              floorInventoryQty={floorInventoryQuantity}
              isToVerify={isToVerify}
            />
            {unit}
          </span>
        </p>
      )}
    </div>
  )
}
