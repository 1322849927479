// src/components/predictions/StoreReferencePredictionsPage.tsx

"use client"

import { useParams, useSearchParams } from "react-router-dom"
import { add } from "date-fns"
import { useState, useEffect, useMemo } from "react"
import { useGetReferencesPerformancesQuery } from "@/utils/__generated__/graphql"

import { ReferenceModificationChart } from "./ReferenceModificationChart"
import { Store } from "@/utils/__generated__/graphql"

import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
interface StoreReferencePredictionsPageProps {
  dateRange: {
    from: Date | undefined
    to: Date | undefined
  }
  stores: Store[]
  selectedStoreId: string | null
}

export default function StoreReferencePredictionsPage({
  dateRange,
  selectedStoreId,
  stores,
}: StoreReferencePredictionsPageProps) {
  const params = useParams()
  const StoreName = stores.find(
    (store) => store.id === selectedStoreId,
  )?.store_name
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const [endRank, setEndRank] = useState<number>(10)
  const [startRank, setStartRank] = useState<number>(1)

  const { data, loading, error, refetch } = useGetReferencesPerformancesQuery({
    variables:
      selectedStoreId && companyId
        ? {
            input: {
              store_id: selectedStoreId,
              company_id: companyId ?? "",
              start_date: formatDateToYYYYMMDD(fromDate),
              end_date: formatDateToYYYYMMDD(toDate),
              start_rank: startRank ?? 1,
              end_rank: endRank ?? 10,
              franchise_name: franchiseParam,
            },
          }
        : undefined,
    skip: !selectedStoreId || !companyId || !fromDate || !toDate,
    fetchPolicy: "cache-and-network",
  })
  useEffect(() => {
    if (selectedStoreId) {
      refetch({
        input: {
          store_id: selectedStoreId,
          company_id: companyId ?? "",
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
          start_rank: startRank ?? 1,
          end_rank: endRank ?? 10,
          franchise_name: franchiseParam,
        },
      })
    }
  }, [
    selectedStoreId,
    fromDate,
    toDate,
    startRank,
    endRank,
    companyId,
    franchiseParam,
    refetch,
  ])

  // Sort performances by sales_amount in descending order
  const top10PercentReferences = useMemo(() => {
    if (!data?.getReferencesPerformances.performances) return []
    const sorted = [...data.getReferencesPerformances.performances].sort(
      (a, b) => b.sales_amount - a.sales_amount,
    )
    return sorted
  }, [data])

  // Transform data for the chart
  const chartData = useMemo(() => {
    return top10PercentReferences.map((perf) => ({
      reference: perf.sale_name,
      tauxDeModif: perf.updated,
      predictedAmount: Math.round(perf.predicted_amount * 100) / 100,
      orderedAmount: Math.round(perf.ordered_amount * 100) / 100,
      salesAmount: Math.round(perf.sales_amount * 100) / 100,
      distance: perf.amount_difference,
    }))
  }, [top10PercentReferences])

  return (
    <div className="h-full flex-1 p-4 flex flex-col overflow-y-hidden">
      <div className="flex items-center gap-2 mb-4">
        <Label htmlFor="start-rank">Nombres de références :</Label>
        <Input
          id="start-rank"
          type="number"
          min={1}
          value={startRank}
          onChange={(e) => setStartRank(parseInt(e.target.value, 10))}
          className="w-20"
        />
        <Label htmlFor="end-rank">à</Label>
        <Input
          id="end-rank"
          type="number"
          min={1}
          value={endRank}
          onChange={(e) => setEndRank(parseInt(e.target.value, 10))}
          className="w-20"
        />
      </div>

      {error && (
        <div className="text-red-500 my-4">
          Erreur lors de la récupération des performances : {error.message}
        </div>
      )}

      {selectedStoreId ? (
        <ReferenceModificationChart
          data={chartData}
          loading={loading}
          storeName={StoreName ?? ""}
        />
      ) : (
        <div className="text-gray-500">
          Veuillez sélectionner un magasin pour afficher les performances des
          références.
        </div>
      )}
    </div>
  )
}
