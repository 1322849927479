import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { GetMessagesForAdminQueryResult } from "@/utils/__generated__/graphql"
import { ScanEye } from "lucide-react"
import Markdown from "marked-react"

interface ViewMessageModalProps {
  message: Exclude<
    GetMessagesForAdminQueryResult["data"],
    undefined
  >["messagesForAdmin"][number]
}

export function ViewMessageModal({ message }: ViewMessageModalProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <ScanEye className="size-4" />
          Voir
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>{message.title}</DialogTitle>
          <DialogDescription className="flex gap-1">
            Début :
            <span className="font-medium text-black">
              {new Intl.DateTimeFormat("fr-FR", {
                weekday: "long",
                day: "numeric",
                month: "long",
              }).format(new Date(message.start_date))}
            </span>
            Fin :
            <span className="font-medium text-black">
              {message.end_date
                ? new Intl.DateTimeFormat("fr-FR", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                  }).format(new Date(message.end_date))
                : "N/A"}
            </span>
          </DialogDescription>
        </DialogHeader>
        <Markdown>{message.content}</Markdown>
      </DialogContent>
    </Dialog>
  )
}
