import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { removeDuplicatesValues } from "@/utils/removeDuplicates"
import { useMemo } from "react"
import { VirtuosoHandle } from "react-virtuoso"
import { twJoin } from "tailwind-merge"

interface Category {
  name: string
  count: number
}

function createCategory(
  name: string,
  references: AllMercurialInfo[],
): Category | undefined {
  const deduplicatedReferences = removeDuplicatesValues(references, "sale_id")
  if (deduplicatedReferences.length === 0) return undefined
  return {
    name,
    count: deduplicatedReferences.length,
  }
}

export function ProgressBar({
  scrollPosition,
  virtuosoRef,
  promotionReferences,
  newReferences,
  toVerifyReferences,
  top5HighestBreakage,
  hasAtLeastOneRow,
}: {
  scrollPosition: number
  virtuosoRef: React.RefObject<VirtuosoHandle>
  promotionReferences: AllMercurialInfo[]
  newReferences: AllMercurialInfo[]
  toVerifyReferences: AllMercurialInfo[]
  top5HighestBreakage: AllMercurialInfo[]
  hasAtLeastOneRow: boolean
}) {
  const categories = useMemo<Category[]>(() => {
    return [
      createCategory("Promotions", promotionReferences),
      createCategory("Nouveautés", newReferences),
      createCategory("À vérifier", toVerifyReferences),
      createCategory("Démarque importante", top5HighestBreakage),
      hasAtLeastOneRow ? { name: "Modifications", count: 1 } : undefined,
    ].filter((category): category is Category => category !== undefined)
  }, [
    promotionReferences,
    newReferences,
    toVerifyReferences,
    top5HighestBreakage,
    hasAtLeastOneRow,
  ])

  const totalLength = useMemo(() => {
    return categories.reduce((acc, category) => acc + category.count, 0)
  }, [categories])

  const categoriesPositions = useMemo(() => {
    return categories.map((category, categoryIndex) => {
      const index = categories.reduce((acc, category, index) => {
        return index < categoryIndex ? acc + category.count : acc
      }, 0)
      const buttonPosition = (index / totalLength) * 100
      const buttonRange = (category.count / totalLength) * 100
      return { start: buttonPosition, end: buttonPosition + buttonRange }
    })
  }, [categories, totalLength])

  const currentCategoryName = useMemo(() => {
    if (scrollPosition >= 100) return "🏁"
    const categoryIndex = categoriesPositions.findIndex(
      (position) =>
        scrollPosition >= position.start && scrollPosition <= position.end,
    )
    if (categoryIndex === -1) return undefined

    return categories[categoryIndex].name
  }, [categories, categoriesPositions, scrollPosition])

  const handleVirtuosoScroll = (index: number) => {
    virtuosoRef.current?.scrollToIndex({
      index,
    })
  }

  return (
    <>
      <div className="relative mt-4 text-xs h-6 w-full">
        <div
          className="h-full flex justify-end min-w-fit"
          style={{ width: `${scrollPosition}%` }}
        >
          <button
            type="button"
            className={twJoin(
              "rounded-sm transition-color text-white font-black",
              scrollPosition < 100 ? "bg-green-900 px-2 py-1" : "text-lg",
            )}
          >
            {currentCategoryName}
          </button>
        </div>
      </div>
      <div className="relative bg-gray-100 w-full rounded-full h-3 mt-1 flex">
        <div
          className={twJoin(
            "h-full rounded-full bg-gradient-to-r from-green-500 to-green-900",
          )}
          style={{ width: `${scrollPosition}%` }}
        />
        {categories.map((category, categoryIndex) => {
          const index = categories.reduce((acc, category, index) => {
            return index < categoryIndex ? acc + category.count : acc
          }, 0)
          const buttonPosition = (index / totalLength) * 100
          return (
            <button
              type="button"
              key={category.name}
              className="size-4 rounded-full bg-green-950 absolute -top-[2px]"
              style={{ left: `${buttonPosition}%` }}
              onClick={() => handleVirtuosoScroll(index)}
            />
          )
        })}
      </div>
    </>
  )
}
