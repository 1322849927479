// src/components/GlobalReferencePredictionsChart.tsx

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

interface GlobalReferencePredictionsChartProps {
  data: {
    reference: string
    tauxDeModif: number
    predictedAmount: number
    orderedAmount: number
    salesAmount: number
  }[]
}

export function GlobalReferencePredictionsChart({
  data,
}: GlobalReferencePredictionsChartProps) {
  return (
    <Card className="flex-1 h-fit sticky top-0">
      <CardHeader>
        <CardTitle>References les moins bien prédites (Global)</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-center mb-4">
          <div className="flex space-x-6">
            <LegendItem color="red" label="Ventes" />
            <LegendItem color="black" label="Préd. avant recalage" />
            <LegendItem color="gray" label="Commandes" />
            <LegendItem color="#2563eb" label="Taux de modification" isLine />
          </div>
        </div>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="reference"
              angle={-45}
              textAnchor="end"
              interval={0}
              height={100}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              yAxisId="left"
              label={{
                value: "Montant en euro",
                angle: -90,
                position: "insideLeft",
                offset: -10,
                dy: 40,
                fill: "black",
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{
                value: "Taux de modification",
                angle: -90,
                position: "insideRight",
                dy: -80,
                dx: -10,
                fill: "black",
              }}
            />
            <Tooltip />
            <Bar
              yAxisId="left"
              dataKey="salesAmount"
              fill="red"
              name="Ventes"
            />
            <Bar
              yAxisId="left"
              dataKey="predictedAmount"
              fill="black"
              name="Préd. avant recalage"
            />
            <Bar
              yAxisId="left"
              dataKey="orderedAmount"
              fill="gray"
              name="Commandes"
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="tauxDeModif"
              stroke="#2563eb"
              strokeWidth={2}
              name="Taux de modification"
              dot={{ r: 4 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}

function LegendItem({
  color,
  label,
  isLine = false,
}: {
  color: string
  label: string
  isLine?: boolean
}) {
  return (
    <div className="flex items-center">
      {isLine ? (
        <svg width="24" height="10" className="mr-2">
          <line x1="0" y1="5" x2="24" y2="5" stroke={color} strokeWidth="2" />
        </svg>
      ) : (
        <div className="w-4 h-4 mr-2" style={{ backgroundColor: color }} />
      )}
      <span className="text-sm">{label}</span>
    </div>
  )
}
