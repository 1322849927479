import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { DateRange, SelectRangeEventHandler } from "react-day-picker"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"

interface DatePickerProps {
  value?: DateRange
  onChange?: SelectRangeEventHandler
}

export function DateRangePicker({ value, onChange }: DatePickerProps) {
  const from = value?.from
  const to = value?.to

  const isEqual =
    from !== undefined &&
    to !== undefined &&
    getDiffBetweenDates(from, to) === 0

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !value && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {to !== undefined && !isEqual ? "Du " : "Le "}
          {Intl.DateTimeFormat("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }).format(from)}
          {to !== undefined && !isEqual ? (
            <>
              {" au "}
              {Intl.DateTimeFormat("fr-FR", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }).format(to)}
            </>
          ) : (
            <span className="text-gray-400">- Un seul jour</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="range"
          selected={value}
          onSelect={onChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
