import { ComponentType, UIEvent, forwardRef } from "react"
import { ItemContent, Virtuoso, VirtuosoHandle } from "react-virtuoso"
import styled from "styled-components"
import { noop } from "lodash"

interface InventoryTableProps {
  totalCount: number
  itemContent: ItemContent<unknown, unknown>
  onScroll?: (e: UIEvent<HTMLDivElement>) => void
  header?:
    | ComponentType<{
        context?: unknown
      }>
    | undefined
  footer?:
    | ComponentType<{
        context?: unknown
      }>
    | undefined
  endReached?: () => void
  components?: Record<string, ComponentType<unknown>>
  onRangeChange?: (index: number) => void
}

export const InventoryTable = forwardRef<VirtuosoHandle, InventoryTableProps>(
  function MyInput(
    {
      onRangeChange = noop,
      onScroll,
      totalCount,
      header,
      footer,
      itemContent,
      endReached,
    },
    ref,
  ) {
    return (
      <Virtuoso
        ref={ref}
        endReached={endReached}
        onScroll={onScroll}
        rangeChanged={(range) => onRangeChange(range.startIndex)}
        className="w-full overflow-x-hidden"
        totalCount={totalCount}
        components={{
          List: ListContainer,
          Header: header,
          Footer: footer,
          EmptyPlaceholder: () => (
            <div className="w-full flex justify-center">
              <p className="font-medium">Aucune donnée</p>
            </div>
          ),
        }}
        itemContent={itemContent}
      />
    )
  },
)

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; // gap-2
  padding: 0 8px; // px-2
  // lg
  @media (min-width: 1024px) {
    gap: 12px; // gap-3
  }
  // xl
  @media (min-width: 1280px) {
    padding: 0 12px; // px-4
  }
`
