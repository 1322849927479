import React from "react"

interface SwitcherProps {
  options: string[]
  onToggle: (index: number) => void
  selected: number
}

export const Switcher: React.FC<SwitcherProps> = ({
  options,
  onToggle,
  selected,
}) => {
  const handleClick = (index: number) => {
    onToggle(index)
  }

  return (
    <div className="flex bg-white gap-2 rounded-md p-2">
      {options.map((option, index) => (
        <button
          className={`p-2 rounded-md ${selected === index && "bg-gray-100"}`}
          key={index}
          onClick={() => handleClick(index)}
        >
          {option}
        </button>
      ))}
    </div>
  )
}
