import Logo from "@/assets/Logo"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { HelpCircle } from "lucide-react"
import { useNavigate } from "react-router-dom"

export function NoStoreLinkedPage() {
  const navigate = useNavigate()

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-4">
      <Logo />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Votre compte n&apos;est lié à aucun magasin.</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-2">
            <p>
              Veuillez contacter l&apos;administrateur de votre enseigne ou le
              support d&apos;IDA.
            </p>
          </CardContent>
          <CardFooter className="flex justify-between">
            <p>
              Email :{" "}
              <a href="mailto:contact@ida.eu" className="underline">
                contact@ida.eu
              </a>
            </p>
            <Button className="w-fit" asChild>
              <a
                href="https://whimsical-scabiosa-bac.notion.site/Centre-d-aide-Ida-151ca59f6f9a80438508dd82bacb4d6e"
                target="_blank"
                rel="noreferrer"
              >
                <HelpCircle className="size-4" />
                Centre d&apos;aide
              </a>
            </Button>
          </CardFooter>
        </Card>
        <div className="flex justify-center gap-4">
          <Button
            variant="outline"
            onClick={() => {
              navigate("/", { replace: true })
            }}
          >
            Réessayer
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              navigate("/logout", { replace: true })
            }}
          >
            Se déconnecter
          </Button>
        </div>
      </div>
    </div>
  )
}
