import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts"
import { ChartConfig, ChartContainer } from "@/components/ui/chart"
import { HomeCard } from "../components/homeCard"
import { useGetWeeksEvolutionQuery } from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu"
import { Loader2 } from "lucide-react"
import { useWindowSize } from "@/hooks/useWindowSize"

const getMonthFromWeek = (weekNumber: number) => {
  const now = new Date()
  const startOfYear = new Date(now.getFullYear(), 0, 1)
  const weekStartDate = new Date(
    startOfYear.setDate(startOfYear.getDate() + (weekNumber - 1) * 7),
  )
  return {
    month: weekStartDate.toLocaleString("fr-FR", { month: "long" }),
    weekStartDate,
  }
}

const getCurrentWeek = () => {
  const now = new Date()
  const start = new Date(now.getFullYear(), 0, 1)
  const diff = (now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  return Math.ceil((diff + start.getDay() + 1) / 7)
}

const chartConfig = {
  orderPrice: {
    label: "Commandes (en prix de vente)",
    color: "#CC1422",
  },
  revenue: {
    label: "chiffre d&a;affaire (€)",
    color: "#6F6F6F",
  },
} satisfies ChartConfig

export const EvolutionOfTheWeek = () => {
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const currentWeek = getCurrentWeek()
  const weeksOptions = Array.from(
    { length: currentWeek },
    (_, i) => i + 1,
  ).reverse()

  const defaultSelectedWeeks = weeksOptions.slice(0, 4)
  const [selectedWeeks, setSelectedWeeks] =
    useState<number[]>(defaultSelectedWeeks)

  const toggleWeekSelection = (week: number) => {
    setSelectedWeeks((prev) =>
      prev.includes(week) ? prev.filter((w) => w !== week) : [...prev, week],
    )
  }

  const selectAllWeeks = () => {
    if (selectedWeeks.length === weeksOptions.length) {
      setSelectedWeeks(weeksOptions.slice(0, 4))
    } else {
      setSelectedWeeks(weeksOptions)
    }
  }

  const { data, loading } = useGetWeeksEvolutionQuery({
    variables: {
      input: {
        store_id: storeId!,
        week_selected: selectedWeeks.length > 0 ? selectedWeeks : weeksOptions,
      },
    },
  })

  const chartData =
    data?.weeksEvolution.weeks_evolution
      .map((item) => {
        const { month } = getMonthFromWeek(item.week_number)
        const currentMonth = new Date().toLocaleString("fr-FR", {
          month: "long",
        })
        return {
          week:
            month === currentMonth
              ? `S${item.week_number}`
              : `S${item.week_number} : ${month}`,
          "Commandes (PV TTC)": item.total_order_price
            ? Math.round(item.total_order_price)
            : 0,
          "Chiffre d'affaires (PV TTC)": item.revenue
            ? Math.round(item.revenue)
            : 0,
          week_number: item.week_number,
        }
      })
      .sort((a, b) => a.week_number - b.week_number) ?? []

  const groupWeeksByMonth = (weeks: number[]) => {
    const months: { month: string; weeks: number[] }[] = Array.from(
      { length: 12 },
      (_, i) => {
        const monthName = new Date(
          new Date().getFullYear(),
          i,
          1,
        ).toLocaleString("fr-FR", { month: "long" })
        return { month: monthName, weeks: [] }
      },
    )

    weeks.forEach((week: number) => {
      const { month } = getMonthFromWeek(week)
      const monthIndex = months.findIndex((m) => m.month === month)
      if (monthIndex !== -1) {
        months[monthIndex].weeks.push(week)
      }
    })

    return months.filter((month) => month.weeks.length > 0).reverse()
  }

  const monthsWithWeeks = groupWeeksByMonth(weeksOptions)

  const { isMD } = useWindowSize()
  return (
    <HomeCard
      title={isMD ? "Réceptions / ventes par semaine" : "Semaines"}
      subtitle="Comparez vos réceptions et votre chiffre d'affaires par semaine"
      className="flex-1 h-full"
    >
      <div className=" p-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              {selectedWeeks.length === weeksOptions.length ? (
                <>
                  <span className="text-gray-500">
                    Sélectionner les semaines
                  </span>{" "}
                  <span className="text-black">Toutes les semaines</span>
                </>
              ) : selectedWeeks.length > (isMD ? 10 : 4) ? (
                <>
                  <span className="text-gray-500">Semaines sélectionnées</span>{" "}
                  <span className="text-black">
                    {selectedWeeks.slice(0, isMD ? 10 : 4).join(", ")}...
                  </span>
                </>
              ) : selectedWeeks.length > 0 ? (
                <>
                  <span className="text-gray-500">Semaines sélectionnées</span>{" "}
                  <span className="text-black">{selectedWeeks.join(", ")}</span>
                </>
              ) : (
                <span className="text-gray-500">Sélectionner les semaines</span>
              )}
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="max-h-64 overflow-y-auto">
            <Button
              className="w-full text-left px-4 py-2"
              variant="ghost"
              onClick={selectAllWeeks}
            >
              {selectedWeeks.length === weeksOptions.length
                ? "Sélectionner les 4 dernières semaines"
                : "Sélectionner toutes les semaines"}
            </Button>
            {monthsWithWeeks.map((month) => (
              <div key={month.month} className="mb-2">
                <h3 className="font-bold text-sm px-4 py-2">{month.month}</h3>
                {month.weeks.map((week) => (
                  <DropdownMenuCheckboxItem
                    key={week}
                    checked={selectedWeeks.includes(week)}
                    onCheckedChange={() => toggleWeekSelection(week)}
                  >
                    Semaine {week}
                  </DropdownMenuCheckboxItem>
                ))}
              </div>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {!loading ? (
        <div className="pr-4 pb-6 max-w-[450px]">
          <ChartContainer config={chartConfig}>
            <BarChart
              data={chartData}
              margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis
                label={{
                  angle: -90,
                  position: "insideLeft",
                  style: {
                    textAnchor: "middle",
                    fontSize: "12px",
                    fill: "#555",
                  },
                }}
                tickFormatter={(value) =>
                  new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: storeCurrency ?? "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(value)
                }
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Commandes (PV TTC)"
                fill={chartConfig.orderPrice.color}
                radius={4}
                className="border-black border-2 border-solid"
              >
                <LabelList
                  dataKey="Commandes (PV TTC)"
                  position="top"
                  formatter={(value: number) =>
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: storeCurrency ?? "EUR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(value)
                  }
                />
              </Bar>
              <Bar
                dataKey="Chiffre d'affaires (PV TTC)"
                fill={chartConfig.revenue.color}
                radius={4}
                className="border-black border-2 border-solid"
              >
                <LabelList
                  dataKey="Chiffre d'affaires (PV TTC)"
                  position="top"
                  formatter={(value: number) =>
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: storeCurrency ?? "EUR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(value)
                  }
                />
              </Bar>
            </BarChart>
          </ChartContainer>
        </div>
      ) : (
        <div className="flex justify-center items-center mt-20  p-4">
          <Loader2 className="w-10 h-10 animate-spin" />
        </div>
      )}
    </HomeCard>
  )
}
