import { ExclamationTriangleIcon, WifiIcon } from "@heroicons/react/24/outline"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import OrderButton from "../button/OrderButton"
import { useSelector } from "react-redux"
import { StateType } from "../../types"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { Dispatch, SetStateAction } from "react"

interface MobileInventoryRecapProps {
  mercurialeDate: string | undefined
  isLoading: boolean
  setSaveDataModalState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      backToHome: boolean
    }>
  >
}

export function MobileInventoryRecap({
  mercurialeDate,
  isLoading,
  setSaveDataModalState,
}: MobileInventoryRecapProps) {
  const storeCurrency = useSelector(
    (state: StateType) => state.storeReducer.storeCurrency,
  )
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const { online, dataSynchronizationStatus } = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const user = useSelector((state: StateType) => state.userReducer)

  const { totalOrders, potentialCommandRevenue } = usePotentialRevenue()

  return (
    <div
      className={`pointer-events-auto flex flex-col gap-3 w-full bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.11)] px-6 pt-2 ${user.storeId !== storeId ? "pb-12" : "pb-3"}`}
    >
      <div className="flex items-center w-full">
        <div className="flex-1 flex items-center gap-2">
          <WifiIcon
            className={`w-5 h-5 ${online ? "text-green-200" : "text-red-200"}`}
          />
          {dataSynchronizationStatus === DataSynchronizationStatus.FAILURE && (
            <button
              className="flex items-center"
              onClick={() =>
                setSaveDataModalState({ isOpen: true, backToHome: false })
              }
            >
              <ExclamationTriangleIcon
                className={`w-5 h-5 lg:w-6 lg:h-6 text-orange-400`}
              />
            </button>
          )}
        </div>
        <div className="flex-2">
          <OrderButton
            isLoading={isLoading}
            className="text-sm font-black py-2 px-6 w-fit uppercase"
            totalOrders={totalOrders}
          />
        </div>
        <div className="flex-1 flex justify-end">
          {mercurialeDate !== undefined && (
            <p className="text-xs text-zinc-400">
              {new Intl.NumberFormat("fr-FR", {
                notation: "compact",
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(potentialCommandRevenue ?? 0)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
