import { useWindowSize } from "@/hooks/useWindowSize"
import OutstandingOrders from "../../OustandingOrders"

const renderBadge = (isPrecommande = false) => {
  if (isPrecommande) {
    return (
      <p className="text-cyan-700 md:text-white md:bg-cyan-700 md:px-1 md:py-1 md:rounded-sm">
        PRECO
      </p>
    )
  }
  return <></>
}

interface OrderExtraElementProps {
  isPrecommande: boolean
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  unit?: string
}

export function OrderExtraElement({
  isPrecommande,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  unit,
}: OrderExtraElementProps) {
  const { isMD } = useWindowSize()
  if (
    (waitedQuantityOrdered ?? 0) === 0 &&
    (promoWaitedQuantityOrdered ?? 0) === 0
  ) {
    return <></>
  }

  return (
    <div className="w-full flex items-center flex-col gap-2">
      <div className="h-[10px] md:h-[20px] text-xxs font-bold text-center">
        {renderBadge(isPrecommande)}
      </div>
      {!isMD && (
        <div className="w-fit bg-gray-100 border px-2 py-1 rounded flex justify-around">
          {(waitedQuantityOrdered ?? 0) > 0 && (
            <OutstandingOrders
              isPromo={false}
              quantity={waitedQuantityOrdered}
              displayedUnit={unit}
            />
          )}
          {(promoWaitedQuantityOrdered ?? 0) > 0 && (
            <OutstandingOrders
              isPromo={true}
              quantity={promoWaitedQuantityOrdered}
              displayedUnit={unit}
            />
          )}
        </div>
      )}
    </div>
  )
}
