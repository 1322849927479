import { TrashIcon } from "@heroicons/react/24/solid"
import { Label } from "../../ui/Label"

const breakages: Record<number, "green" | "orange" | "red"> = {
  1: "green",
  2: "orange",
  3: "red",
}

const getShrinkageResult = (shrinkage: number): 1 | 2 | 3 => {
  if (shrinkage < 5) {
    return 1
  } else if (shrinkage >= 5 && shrinkage < 10) {
    return 2
  } else {
    return 3
  }
}

const BreakageLabel = ({
  shrinkage,
  amount,
  storeCurrency,
}: {
  shrinkage: number | null
  amount?: number | null
  expanded?: boolean
  storeCurrency: string | null
}): JSX.Element | null => {
  if (shrinkage === null) return null

  const shrinkableResult = getShrinkageResult(shrinkage)
  const color = breakages[shrinkableResult]

  return (
    <Label color={color} leftIcon={<TrashIcon className="w-4 h-4" />}>
      {new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: storeCurrency ?? "EUR",
      }).format(amount ?? 0)}{" "}
      ({shrinkage}%) sur 7J
    </Label>
  )
}

export default BreakageLabel
