export const links = [
  {
    title: "Infos pratiques",
    url: "https://marceletfils.sharepoint.com/sites/it/SitePages/Proposition-de-Commandes-FL-(IDA.aspx",
  },
  {
    title: "Actus FL",
    url: "http://192.168.103.50/index.php?uc=Accueil&action=Afficher",
  },
  {
    title: "Avoirs",
    url: "http://192.168.103.50/index.php?uc=Avoir&action=Afficher",
  },
  {
    title: "Docs",
    url: "http://192.168.103.50/index.php?uc=Documents&action=Afficher",
  },
]
