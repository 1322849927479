import "./init"
import ReactDOM from "react-dom/client"
import "./styles/index.css"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import ReactGA from "react-ga4"
import * as Sentry from "@sentry/react"
import { App } from "./app"
import { auth0Config } from "./config"
import { persistor, store } from "./store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { Auth0Provider } from "@auth0/auth0-react"

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://4419263a9f6bbb7541b5e3ab90728db8@o4506863426732032.ingest.us.sentry.io/4506863430074368",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/arolya\.fr\/graphql/,
      /^https:\/\/graphql-api-staging-n62hrcp23q-ew\.a\.run\.app\/graphql/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  LogRocket.init("93cun0/ida")
  setupLogRocketReact(LogRocket)
  ReactGA.initialize("GTM-54BKHHV4")
}

const root = ReactDOM.createRoot(document.getElementById("root")!)
root.render(
  <Auth0Provider {...auth0Config}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Auth0Provider>,
)
