import { StateType } from "@/types"
import { useGetMessagesForAdminQuery } from "@/utils/__generated__/graphql"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { MessagesTable } from "./components/MessagesTable"
import { DeleteMessageModal } from "./components/DeleteMessageModal"
import { Button } from "@/components/ui/button"
import { MessageSquarePlus } from "lucide-react"
import { MessageFormModal } from "./components/MessageFormModal"

export function MessagesPage() {
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const { data, loading, refetch } = useGetMessagesForAdminQuery({
    variables: {
      input: {
        store_id: storeId!,
        company_id: params.companyId!,
        company_franchise: franchiseParam,
      },
    },
  })

  const [deleteMessageId, setDeleteMessageId] = useState<string>()
  const [isMessageFormModalOpen, setIsMessageFormModalOpen] = useState(false)
  const [updateMessageId, setUpdateMessageId] = useState<string>()

  const messages = data?.messagesForAdmin ?? []

  return (
    <div className="p-4">
      <DeleteMessageModal
        storeId={storeId!}
        deleteMessageId={deleteMessageId}
        setDeleteMessageId={setDeleteMessageId}
        refetchMessages={refetch}
      />
      <MessageFormModal
        companyId={params.companyId!}
        franchise={franchiseParam}
        storeId={storeId!}
        refetchMessages={refetch}
        isOpen={isMessageFormModalOpen}
        setIsOpen={setIsMessageFormModalOpen}
        setUpdateMessageId={setUpdateMessageId}
        message={messages.find((message) => message.id === updateMessageId)}
      />
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold mb-4">Messages</h1>
        <Button onClick={() => setIsMessageFormModalOpen(true)}>
          <MessageSquarePlus className="size-4" />
          Écrire un message
        </Button>
      </div>
      <MessagesTable
        messages={messages}
        isLoading={loading}
        onDelete={(id) => setDeleteMessageId(id)}
        onUpdate={(id) => {
          setUpdateMessageId(id)
          setIsMessageFormModalOpen(true)
        }}
      />
    </div>
  )
}
