import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { defaultMercurialReducerState } from "../../reducers/mercurialReducer"
import { defaultUserInterfaceReducerState } from "../../reducers/userInterfaceReducer"
import { useGetStoreLazyQuery } from "../../utils/__generated__/graphql"
import { DispatchActionType, StateType } from "../../types"
import { Spinner } from "../../ui/Spinner"
import { defaultUpdateReducerState } from "../../reducers/updateReducer"
import { usePermissions } from "../../hooks/usePermissions"
import { toast } from "sonner"
import { defaultModalsReducerState } from "@/reducers/modalsReducer"

export function RedirectToStorePage(): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const params = useParams()
  const [getStore] = useGetStoreLazyQuery()
  const { isReadOnly, isCompanyAdmin, isIdaAdmin } = usePermissions()
  const user = useSelector((state: StateType) => state.userReducer)

  const needTestModeEnabled = useMemo(() => {
    const storeId = params.storeId
    if (typeof storeId !== "string") return false

    // Don't activate test mode by default for companies admins
    if (isCompanyAdmin && !isIdaAdmin) return false

    // If redirected store is different from the user default, enable test mode
    if (user.storeId !== storeId) {
      return true
    }

    // If user is read only, enable test mode
    return isReadOnly
  }, [isCompanyAdmin, isIdaAdmin, isReadOnly, params.storeId, user])

  useEffect(() => {
    async function redirectToStore(id: string): Promise<void> {
      try {
        const result = await getStore({
          variables: {
            input: {
              store_id: id,
            },
          },
        })
        if (result.data?.getStore?.error === null) {
          dispatch({
            type: "setStore",
            payload: {
              storeId: result.data.getStore?.store?.id,
              storeName: result.data.getStore?.store?.store_name,
              storeCity: result.data.getStore?.store?.city,
              companyId: result.data.getStore?.store?.company_id,
              storeCountry: result.data.getStore?.store?.country,
              storeSettings: result.data.getStore?.store?.settings,
              storeSuppliers: result.data.getStore?.suppliers,
              storeStoreSuppliers: result.data.getStore.storeSuppliers,
              storeCurrency: result.data.getStore.store?.currency,
              storeCode: result.data.getStore.store?.store_code,
              companyName: result.data.getStore.store?.company_name,
              selectedStoreId: user.storeId !== id ? id : undefined,
              storeFranchise: result.data.getStore.store?.franchise,
            },
          })
          dispatch({
            type: "setTrainingMode",
            payload: needTestModeEnabled,
          })
          dispatch({
            type: "setMercurial",
            payload: defaultMercurialReducerState,
          })
          dispatch({
            type: "setUserInterface",
            payload: defaultUserInterfaceReducerState,
          })
          dispatch({
            type: "setUpdate",
            payload: defaultUpdateReducerState,
          })
          dispatch({
            type: "setModalsReducerState",
            payload: defaultModalsReducerState,
          })
          navigate("/inventory")
        } else {
          throw new Error("Une erreur est survenue")
        }
      } catch (error) {
        console.error(error)
        const errorMesssage =
          error instanceof Error
            ? error.message
            : "Vous ne pouvez pas voir ce magasin"
        toast.error(errorMesssage)
      }
    }

    if (typeof params.storeId === "string") {
      void redirectToStore(params.storeId)
    }
  }, [dispatch, getStore, navigate, needTestModeEnabled, params, user])

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner invertColors className="w-10 h-10" />
    </div>
  )
}
