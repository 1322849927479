import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { useDeleteMessageMutation } from "@/utils/__generated__/graphql"
import { captureException } from "@sentry/react"
import { toast } from "sonner"

interface DeleteMessageModalProps {
  storeId: string
  deleteMessageId: string | undefined
  setDeleteMessageId: (id: string | undefined) => void
  refetchMessages: () => void
}

export function DeleteMessageModal({
  storeId,
  deleteMessageId,
  setDeleteMessageId,
  refetchMessages,
}: DeleteMessageModalProps) {
  const [deleteMessage, { loading: isDeleteMessageLoading }] =
    useDeleteMessageMutation()

  async function handleDelete(id: string | undefined) {
    if (id === undefined) return

    try {
      await deleteMessage({
        variables: {
          input: {
            store_id: storeId,
            id,
          },
        },
      })
      setDeleteMessageId(undefined)
      refetchMessages()
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Le message n'a pas pu être supprimé.")
    }
  }

  return (
    <AlertDialog open={deleteMessageId !== undefined}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Êtes-vous sûr de vouloir supprimer ce message ?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Le message ne sera plus afficher dans l&apos;interface des magasins.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setDeleteMessageId(undefined)}>
            Annuler
          </AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={() => handleDelete(deleteMessageId)}
            disabled={isDeleteMessageLoading}
          >
            Supprimer
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
