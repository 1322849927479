import Markdown from "marked-react"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card"
import {
  GetMessagesForStoreQueryResult,
  MessageType,
} from "@/utils/__generated__/graphql"
import { twJoin } from "tailwind-merge"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"

interface MessageCardProps {
  message: Exclude<
    GetMessagesForStoreQueryResult["data"],
    undefined
  >["messagesForStore"][number]
}

export function MessageCard({ message }: MessageCardProps) {
  const startDate = new Date(message.start_date)
  const endDate = message.end_date ? new Date(message.end_date) : undefined
  const isSameDate = endDate && getDiffBetweenDates(startDate, endDate) === 0

  return (
    <Card
      key={message.id}
      className={twJoin(
        message.type === MessageType.Announcement && "border-red-100",
      )}
    >
      <CardHeader>
        <CardTitle>{message.title}</CardTitle>
        <CardDescription>
          {endDate === undefined && "À partir du "}
          {isSameDate && "Le "}
          {endDate !== undefined && !isSameDate && "Du "}
          {new Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "numeric",
            month: "long",
          }).format(startDate)}{" "}
          {endDate !== undefined &&
            !isSameDate &&
            `au ${new Intl.DateTimeFormat("fr-FR", {
              weekday: "long",
              day: "numeric",
              month: "long",
            }).format(endDate)}`}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Markdown>{message.content}</Markdown>
      </CardContent>
    </Card>
  )
}
