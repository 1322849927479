import { CalendarIcon, ChevronDownIcon } from "lucide-react"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { StateType } from "@/types"
import { useSelector } from "react-redux"

const MercurialeDate = ({ date }: { date?: string | null }) => {
  const isValidDate = !isNaN(new Date(date ?? "").getTime())
  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  return (
    <div className="whitespace-nowrap font-bold flex items-center gap-1">
      <CalendarIcon className="w-4 h-4 text-muted-foreground mr-0.5" />
      <span className="text-muted-foreground font-medium">
        {companyName === "auchan" ? "Commande" : "Mercuriale"} du{" "}
      </span>
      {isValidDate ? (
        <span>
          {new Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "2-digit",
          }).format(new Date(date ?? ""))}{" "}
        </span>
      ) : (
        <div className="my-1 w-14 h-2 bg-gray-300 rounded-full animate-pulse" />
      )}
    </div>
  )
}

export const MercurialeDateSelection = ({
  dimMercuriales,
  selectedDimMercurialeId,
  mercurialeDate,
  onSelect,
}: {
  onSelect: (id: string) => void
  mercurialeDate?: string | null
  selectedDimMercurialeId?: string
  dimMercuriales: MercurialReducerState["dimMercuriales"]
}) => {
  const mercuriales = dimMercuriales?.filter(
    (mercuriale) => !!mercuriale.orderPickupTime,
  )
  const isDisabled = !mercuriales || mercuriales.length <= 1

  if (isDisabled) {
    return <MercurialeDate date={mercurialeDate} />
  }

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <MercurialeDate date={mercurialeDate} />
          <ChevronDownIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="min-w-48 max-h-96 overflow-auto"
        align="center"
      >
        <DropdownMenuLabel>{"Choisir la mercuriale"}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {mercuriales.map(({ orderPickupTime, dimMercurialeId }) => (
          <DropdownMenuCheckboxItem
            key={dimMercurialeId}
            checked={selectedDimMercurialeId === dimMercurialeId}
            onSelect={() => onSelect(dimMercurialeId)}
            className="text-sm font-medium"
          >
            {orderPickupTime.slice(0, -3).replace(":", "h")}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
