import { useEffect, useMemo } from "react"
import { Switch } from "@headlessui/react"
import { useForm, type SubmitHandler, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, type StateType } from "../types"
import {
  Category,
  PdfFilter,
  useUpdateStoreSettingsMutation,
} from "../utils/__generated__/graphql"
import { useNavigate } from "react-router-dom"
import { Button } from "../ui/Button"
import { defaultMercurialReducerState } from "../reducers/mercurialReducer"
import { defaultUserInterfaceReducerState } from "../reducers/userInterfaceReducer"
import { Select, type SelectOptions } from "../ui/Select"
import {
  ArrowDownTrayIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"
import { captureException } from "@sentry/react"
import { usePermissions } from "../hooks/usePermissions"
import { toast } from "sonner"
import Header from "@/components/header"

const pdfFilterOptions: SelectOptions<PdfFilter> = [
  {
    label: "Défaut",
    value: PdfFilter.Default,
  },
  {
    label: "Alphabétique",
    value: PdfFilter.Alphabetical,
  },
  {
    label: "Mercuriale",
    value: PdfFilter.Mercuriale,
  },
]

const useKGPCEOptions: SelectOptions<boolean> = [
  {
    label: "Caisses (cs)",
    value: false,
  },
  {
    label: "Kilos et pièces (kg et pce)",
    value: true,
  },
]

interface StoreSettingsForm {
  show_suppliers: boolean
  show_categories: boolean
  use_kg_pce: boolean
  pdf_filter: PdfFilter
  categories_orders: Category[]
  view_sales_first: boolean
  view_pa: boolean
  view_pv: boolean
  edit_pv: boolean
  display_purchase_code: boolean
  view_local: boolean
  show_checkin_modal: boolean
  must_see_sorting: boolean
  show_breakage_confirm_order: boolean
  show_low_sales_confirm_order: boolean
  display_sale_name_ida: boolean
  limit_order_for_high_breakage: boolean
  show_last_pv: boolean
}

function SettingsPage(): JSX.Element {
  const storeReducer = useSelector((state: StateType) => state.storeReducer)
  const connectionReducer = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const mercurialReducer = useSelector(
    (state: StateType) => state.mercurialReducer,
  )
  const trainingModeReducer = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const userInterfaceReducer = useSelector(
    (state: StateType) => state.userInterfaceReducer,
  )
  const userReducer = useSelector((state: StateType) => state.userReducer)

  const [updateStoreSettings, { loading }] = useUpdateStoreSettingsMutation()
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const { isIdaAdmin, isReadOnly } = usePermissions()

  const { handleSubmit, control, setValue, watch } = useForm<StoreSettingsForm>(
    {
      defaultValues: {
        show_suppliers: storeReducer.storeSettings?.show_suppliers ?? false,
        show_categories: storeReducer.storeSettings?.show_categories ?? false,
        use_kg_pce: storeReducer.storeSettings?.use_kg_pce ?? false,
        pdf_filter: storeReducer.storeSettings?.pdf_filter ?? PdfFilter.Default,
        categories_orders:
          storeReducer.storeSettings?.categories_orders?.map((category) => ({
            name: category.name,
            children: category.children,
          })) ?? [],
        view_sales_first: storeReducer.storeSettings?.view_sales_first ?? true,
        view_pa: storeReducer.storeSettings?.view_pa ?? true,
        view_local: storeReducer.storeSettings?.view_local ?? false,
        view_pv: storeReducer.storeSettings?.view_pv ?? true,
        edit_pv: storeReducer.storeSettings?.edit_pv ?? false,
        display_purchase_code:
          storeReducer.storeSettings?.display_purchase_code ?? false,
        display_sale_name_ida:
          storeReducer.storeSettings?.display_sale_name_ida ?? false,
        show_checkin_modal:
          storeReducer.storeSettings?.show_checkin_modal ?? false,
        must_see_sorting: storeReducer.storeSettings?.must_see_sorting ?? true,
        show_breakage_confirm_order:
          storeReducer.storeSettings?.show_breakage_confirm_order ?? true,
        show_low_sales_confirm_order:
          storeReducer.storeSettings?.show_low_sales_confirm_order ?? false,
        limit_order_for_high_breakage:
          storeReducer.storeSettings?.limit_order_for_high_breakage ?? false,
        show_last_pv: storeReducer.storeSettings?.show_last_pv ?? false,
      },
    },
  )
  const onSubmit: SubmitHandler<StoreSettingsForm> = async (data) => {
    try {
      const result = await updateStoreSettings({
        variables: {
          input: {
            store_id: storeReducer.storeId ?? "",
            settings: data,
          },
        },
      })

      if (result.data?.updateStoreSettings.error !== null) {
        throw new Error(result.data?.updateStoreSettings.error?.message)
      }

      if (
        (storeReducer.storeSettings?.use_kg_pce ?? false) !== data.use_kg_pce
      ) {
        dispatch({
          type: "setMercurial",
          payload: defaultMercurialReducerState,
        })
      }
      dispatch({
        type: "setUserInterface",
        payload: defaultUserInterfaceReducerState,
      })
      dispatch({
        type: "setStore",
        payload: {
          storeSettings: result.data?.updateStoreSettings.store?.settings,
        },
      })
      navigate(-1)
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Paramètres non sauvegardés")
    }
  }

  const encodedState = useMemo(
    () =>
      encodeURIComponent(
        JSON.stringify({
          storeReducer,
          connectionReducer,
          mercurialReducer,
          trainingModeReducer,
          userInterfaceReducer,
          userReducer,
        }),
      ),
    [
      connectionReducer,
      mercurialReducer,
      storeReducer,
      trainingModeReducer,
      userInterfaceReducer,
      userReducer,
    ],
  )

  const displayPurchaseCode = watch("display_purchase_code")
  const displaySaleNameId = watch("display_sale_name_ida")

  useEffect(() => {
    if (displayPurchaseCode) {
      setValue("display_sale_name_ida", false)
    }
  }, [displayPurchaseCode, setValue])

  useEffect(() => {
    if (displaySaleNameId) {
      setValue("display_purchase_code", false)
    }
  }, [displaySaleNameId, setValue])

  return (
    <>
      <Header />
      <div className=" flex px-6 flex-col gap-8">
        <h1 className="font-black text-zinc-800 text-xl">Paramètres</h1>
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Affichages</h2>
          <form
            onSubmit={(event) => {
              void handleSubmit(onSubmit)(event)
            }}
            className="text-gray-600 flex flex-col gap-4 md:flex-row md:items-end md:justify-between md:gap-0"
          >
            <div className="flex flex-col items-start gap-4 max-w-[370px] w-full">
              <div className="flex items-center justify-between w-full">
                <label>Afficher les fournisseurs</label>
                <Controller
                  control={control}
                  name="show_suppliers"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Switch
                      ref={ref}
                      checked={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                    >
                      <span className="sr-only">Afficher les fournisseurs</span>
                      <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                    </Switch>
                  )}
                />
              </div>
              <div className="flex items-center justify-between w-full">
                <label>Afficher les catégories</label>
                <Controller
                  control={control}
                  name="show_categories"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Switch
                      ref={ref}
                      checked={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                    >
                      <span className="sr-only">Afficher les catégories</span>
                      <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                    </Switch>
                  )}
                />
              </div>
              <div>
                <label>Choix de l&apos;unité de commande</label>
                <Controller
                  control={control}
                  name="use_kg_pce"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={useKGPCEOptions}
                      onChange={onChange}
                      value={value}
                      className="w-full"
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label>
                  Ordre des références dans l&apos;envoi de commande
                </label>
                <Controller
                  control={control}
                  name="pdf_filter"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={pdfFilterOptions}
                      onChange={onChange}
                      value={value}
                      className="w-full"
                    />
                  )}
                />
              </div>
              {isIdaAdmin && (
                <>
                  <hr className="w-full" />
                  <h2 className="font-black text-red-700">Ida Admin</h2>
                  <div className="flex items-center justify-between w-full">
                    <label>Voir les ventes en premier</label>
                    <Controller
                      control={control}
                      name="view_sales_first"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only"></span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher les prix d&#39;achat</label>
                    <Controller
                      control={control}
                      name="view_pa"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher les prix d&#39;achat
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher les prix de ventes</label>
                    <Controller
                      control={control}
                      name="view_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher les prix de ventes
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Modifier les prix de ventes</label>
                    <Controller
                      control={control}
                      name="edit_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Modifier les prix de ventes
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher le prix de vente précédent</label>
                    <Controller
                      control={control}
                      name="show_last_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher le prix de vente précédent
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher le toggle de local</label>
                    <Controller
                      control={control}
                      name="view_local"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher le toggle de local
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher le code d&apos;achat</label>
                    <Controller
                      control={control}
                      name="display_purchase_code"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher le code d&apos;achat
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Afficher le code de vente</label>
                    <Controller
                      control={control}
                      name="display_sale_name_ida"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Afficher le code de vente
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Montrer la modal de checkin</label>
                    <Controller
                      control={control}
                      name="show_checkin_modal"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Montrer la modal de checkin
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Effectuer le tri dans la liste des références</label>
                    <Controller
                      control={control}
                      name="must_see_sorting"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Effectuer le tri dans la liste des références
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Confirmation de commande avec démarque</label>
                    <Controller
                      control={control}
                      name="show_breakage_confirm_order"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Confirmation de commande avec démarque
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Confirmation de commande avec peu de ventes</label>
                    <Controller
                      control={control}
                      name="show_low_sales_confirm_order"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Confirmation de commande avec peu de ventes
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <label>Bloquer la commande des hautes démarques</label>
                    <Controller
                      control={control}
                      name="limit_order_for_high_breakage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                        >
                          <span className="sr-only">
                            Bloquer la commande des hautes démarques
                          </span>
                          <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <Button
              disabled={loading || trainingModeReducer.enable}
              type="submit"
              className="font-black h-14 px-5"
            >
              SAUVEGARDER
            </Button>
          </form>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Système</h2>
          <div className="flex flex-col gap-2">
            <p className="text-zinc-800 text-xs">Mettre à jour le système</p>
            <a
              href={`/account/settings?date=${new Date().getTime()}`}
              className="w-full h-14 px-10 py-4 bg-cyan-300 rounded shadow justify-center items-center gap-4 inline-flex text-white font-black md:w-fit"
            >
              METTRE À JOUR
            </a>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Mode</h2>
          {isReadOnly && (
            <p className="w-fit flex items-center gap-1 px-2 py-1 rounded bg-yellow-200 text-sm text-yellow-900">
              <ExclamationTriangleIcon className="w-4 h-4" />
              Vous utilisez l&apos;application en mode visualisation, vous ne
              pouvez pas désactiver le{" "}
              <span className="font-bold">mode test</span>
            </p>
          )}
          <div className="w-fit flex items-center gap-2">
            <Switch
              checked={trainingModeReducer.enable}
              onChange={(checked) => {
                dispatch({ type: "setTrainingMode", payload: checked })
                if (!checked) {
                  dispatch({
                    type: "setMercurial",
                    payload: defaultMercurialReducerState,
                  })
                  dispatch({
                    type: "setUserInterface",
                    payload: defaultUserInterfaceReducerState,
                  })
                }
              }}
              disabled={isReadOnly && !isIdaAdmin}
              className="enabled:ui-checked:bg-green-500 ui-not-checked:bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full disabled:bg-gray-200"
            >
              <span className="sr-only">Mode test</span>
              <span className="ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition" />
            </Switch>
            <label>Mode test</label>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">
            État de l&apos;application
          </h2>
          <div className="flex flex-col gap-2">
            <p className="text-zinc-800 text-xs">Récupérer le fichier</p>
            <div className="flex gap-4 flex-col md:flex-row">
              <a
                href={`data:text/plain;charset=utf-8,${encodedState}`}
                download={`state ida ${new Date().toString()}`}
                className="w-full md:w-fit h-14 px-5 py-4 bg-green-500 rounded shadow justify-center items-center gap-4 inline-flex text-white font-black"
              >
                <ArrowDownTrayIcon className="w-6 h-6" />
                TÉLÉCHARGER
              </a>
              <a
                href={`mailto:contact@ai-ida.com?cc=mathieu@ai-ida.com&subject=state&body=${encodedState}`}
                className="w-full md:w-fit h-14 px-5 py-4 border-green-500 rounded border-2 shadow justify-center items-center gap-4 inline-flex text-green-500 font-black"
              >
                <EnvelopeIcon className="w-6 h-6" />
                ENVOYER PAR EMAIL
              </a>
            </div>
          </div>
        </div>
        <hr />
        <Link
          to="/logout"
          className="flex gap-1.5 items-center text-red-700 text-xs underline mb-20"
        >
          Se déconnecter
        </Link>
      </div>
    </>
  )
}

export default SettingsPage
