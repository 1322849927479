// src/components/ModificationsChartByStore.tsx

"use client"

import { add, parseISO } from "date-fns"
import { useEffect, useMemo } from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import {
  Store,
  useGetStoreInventoryModificationsEvolutionQuery,
} from "@/utils/__generated__/graphql"
import { CardContent, CardTitle } from "@/components/ui/card"
import { format } from "date-fns"

interface ModificationsChartByStoreProps {
  dateRange: {
    from: Date | undefined
    to: Date | undefined
  }
  selectedStoreId: string
  stores: Store[]
}

export default function ModificationsChartByStore({
  dateRange,
  selectedStoreId,
  stores,
}: ModificationsChartByStoreProps) {
  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()
  const StoreName = useMemo(() => {
    const store = stores.find((store) => store.id === selectedStoreId)
    return store?.store_name ?? "Inconnu"
  }, [selectedStoreId, stores])

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const { data, loading, error, refetch } =
    useGetStoreInventoryModificationsEvolutionQuery({
      variables: {
        input: {
          store_id: selectedStoreId,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
        },
      },
      skip: !selectedStoreId,
      fetchPolicy: "cache-and-network", // Ensures data is fetched from the network
    })

  // Optionally refetch when selectedStoreId or dateRange changes
  useEffect(() => {
    if (selectedStoreId) {
      refetch({
        input: {
          store_id: selectedStoreId,
          start_date: formatDateToYYYYMMDD(fromDate),
          end_date: formatDateToYYYYMMDD(toDate),
        },
      })
    }
  }, [selectedStoreId, fromDate, toDate, refetch])

  const chartData = useMemo(() => {
    return (
      data?.getStoreInventoryModificationsEvolution?.modifications_evolution
        ?.map((item) => {
          try {
            return {
              date: format(parseISO(item.modification_date), "yyyy-MM-dd"),
              modification_count: item.modification_count,
              increase_count: item.increase_count,
              decrease_count: item.decrease_count,
              total_increase: Math.round(item.total_increase * 100) / 100,
              total_decrease: Math.round(item.total_decrease * 100) / 100,
            }
          } catch (error) {
            console.error("Invalid date format:", item.modification_date)
            return null // Exclude invalid date entries
          }
        })
        .filter(Boolean) ?? []
    )
  }, [data])

  return (
    <div className="h-full flex-1 p-4 flex flex-col gap-4 overflow-y-auto">
      <CardTitle>
        Nombres de modifications de stock pour le magasin {StoreName}
      </CardTitle>
      <CardContent>
        {selectedStoreId ? (
          loading ? (
            // Skeleton loader when loading
            <div className="animate-pulse">
              <div className="h-64 bg-gray-200 rounded"></div>
            </div>
          ) : error ? (
            <div className="text-red-500">
              Erreur lors du chargement des données.
            </div>
          ) : chartData.length === 0 ? (
            <div>Aucune donnée disponible pour le magasin sélectionné.</div>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                key={selectedStoreId} // Force re-render on store change
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="modification_count"
                  stroke="#8884d8"
                  name="Nombre de modifications"
                />
                <Line
                  type="monotone"
                  dataKey="increase_count"
                  stroke="#82ca9d"
                  name="Nombre d'augmentations"
                />
                <Line
                  type="monotone"
                  dataKey="decrease_count"
                  stroke="#FF0000" // Different color for clarity
                  name="Nombre de diminutions"
                />
              </LineChart>
            </ResponsiveContainer>
          )
        ) : (
          <div className="text-gray-500">
            Veuillez sélectionner un magasin pour afficher les performances des
            modifications de stock.
          </div>
        )}
      </CardContent>
    </div>
  )
}
